import React from "react";
import { UserInfoProps } from "./types";
import {
  Heading,
  Paragraph,
  UserCard,
  Tooltip,
} from "@trace-one/design-system";

const UserInfo: React.FC<UserInfoProps> = ({
  user,
  companyDetails,
  jobTitles,
  isMasquerade,
}) => {
  const jobTitle = jobTitles?.find(
    jobTitle =>
      jobTitle.value === user.userJobTitleId?.toString() && jobTitle
  );

  const companyName = companyDetails?.companyDisplayName;
  const MAX_LENGTH = 24;

  return (
    <div className="user-info" data-test-id="user-menu-user-info">
      <div
        className="user-info-avatar"
        data-test-id="user-menu-user-info-avatar"
      >
        <div>
          <UserCard.Information
            size="xl"
            name={`${user?.userFirstName} ${user?.userLastName}`}
            photoUrl={user?.userPhotoUrl}
            icon={isMasquerade}
          />
        </div>
      </div>
      <div
        className="user-info-details"
        data-test-id="user-menu-user-info-details"
      >
        <Heading
          size="xxs"
          color="white"
          data-test-id="user-menu-user-info-name"
        >
          {`${user.userFirstName} ${user.userLastName}`}
        </Heading>
        {jobTitle && (
          <Paragraph
            size="s"
            color="white"
            data-test-id="user-menu-user-info-job-title"
          >
            {jobTitle.name}
          </Paragraph>
        )}
        <Tooltip placement="bottom" text={companyName}>
          <Paragraph
            size="s"
            color="white"
            data-test-id="user-menu-user-info-company"
          >
            {companyName?.length > MAX_LENGTH
              ? `${companyName?.substring(0, MAX_LENGTH)}...`
              : companyName}
          </Paragraph>
        </Tooltip>
      </div>
    </div>
  );
};

export default UserInfo;
