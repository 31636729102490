import { AxiosInstancesManager } from "src/configs/AxiosInstancesManager";
import { REFERENCE_LIST_ID, REFERENCE_LIST_NAME } from "src/utils/constants";
import { ReferenceListItem, ReferenceListItemData } from "../../models";
import { RLMD_PREFIX } from "../constants";
export const getLanguages = async ({
  languageCode,
}: {
  languageCode: string;
}) => {
  const rlmdInstance = AxiosInstancesManager.getRlmd();

  return await rlmdInstance.get<ReferenceListItem[]>(
    `/api/referencelist/${REFERENCE_LIST_ID.LANGUAGES}/items`,
    { params: { languageCode } }
  );
};

export const getCountries = async ({
  languageCode,
  referenceListName,
}: {
  languageCode: string;
  referenceListName: string;
}) => {
  const rlmdInstance = AxiosInstancesManager.getRlmd();

  return await rlmdInstance.get<ReferenceListItem[]>(
    `/api/referencelist/name/${REFERENCE_LIST_NAME.COUNTRIES}/items`,
    { params: { languageCode, referenceListName } }
  );
};

export const getJobTitles = async ({
  languageCode,
  referenceListName,
}: {
  languageCode: string;
  referenceListName: string;
}) => {
  const rlmdInstance = AxiosInstancesManager.getRlmd();

  return await rlmdInstance.get<ReferenceListItem[]>(
    `/api/referencelist/name/${REFERENCE_LIST_NAME.JOB_TITLES}/items`,
    { params: { languageCode, referenceListName } }
  );
};

export const fetchAppsList = async ({
  languageCode,
}: {
  languageCode: string;
}) => {
  const rlmdInstance = AxiosInstancesManager.getRlmd();

  return await rlmdInstance.post<ReferenceListItem[]>(
    `/api/referencelist/${REFERENCE_LIST_ID.APPS_REFERENCE_LIST_ID}/items/v2`,
    {
      parentItemIds: [REFERENCE_LIST_ID.REQUESTABLE_APPS_LIST_ID],
    },
    {
      params: {
        id: REFERENCE_LIST_ID.APPS_REFERENCE_LIST_ID,
        languageCode: languageCode,
      },
    }
  );
};
interface RefListData {
  name: string;
  unit: string;
}

export const fetchRefList = async ({
  languageCode,
  id,
  companyId,
}: {
  languageCode: string;
  id: string;
  companyId: string;
}): Promise<RefListData[]> => {
  try {
    const rlmdInstance = AxiosInstancesManager.getRlmd();

    const { data } = await rlmdInstance.get(`${RLMD_PREFIX}/${id}/items`, {
      params: { languageCode, companyId },
    });

    return data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const fetchRefListCollection = async ({
  languageCode,
  id,
  parentItemIds,
}: {
  languageCode: string;
  id: string;
  parentItemIds: string[];
}): Promise<RefListData[]> => {
  try {
    const rlmdInstance = AxiosInstancesManager.getRlmd();
    const { data } = await rlmdInstance.post(
      `${RLMD_PREFIX}/${id}/items/v2`,
      { parentItemIds },
      {
        params: { languageCode },
      }
    );

    return data.referenceListItems;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const fetchReferenceListItems = ({
  languageCode,
  id,
  take,
  skip,
  searchText,
  parentItemIds,
  ...params
}: {
  languageCode: string;
  id: string;
  take?: number;
  skip?: number;
  searchText?: string;
  parentItemIds?: string[];
}) => {
  const rlmdInstance = AxiosInstancesManager.getRlmd();
  return rlmdInstance.post<{
    referenceListItems: ReferenceListItemData[];
    skipAndTakeQueryStats: {
      currentCount: number;
      totalCount: number;
    };
  }>(
    `/api/referencelist/${id}/items/v2`,

    { parentItemIds },
    {
      params: {
        languageCode,
        portalTypeId: "2280fc83-0b5e-4d05-93b5-27a3aa3a3fcb",
        take,
        skip,
        searchText,
        sortByItemOrder: true,
        ...params,
      },
    }
  );
};

export const fetchNetContent = async ({
  languageCode,
}: {
  languageCode: string;
}) => {
  const rlmdInstance = AxiosInstancesManager.getRlmd();

  return await rlmdInstance.get<ReferenceListItem[]>(
    `/api/referencelist/name/${REFERENCE_LIST_NAME.NET_CONTENT}/items`,
    { params: { languageCode } }
  );
};
