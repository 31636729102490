import { defineMessages } from "react-intl";

export const notificationSettingsMessages = defineMessages({
  title: {
    id: "businessComponents.notificationsSettings.content.title",
    defaultMessage: "Notifications settings",
  },
  typeTitle: {
    id: "businessComponents.notificationsSettings.table.type.title",
    defaultMessage: "Type",
  },
  inAppTitle: {
    id: "businessComponents.notificationsSettings.table.inAppNotif.title",
    defaultMessage: "Show In-App",
  },
  emailNotifTitle: {
    id: "businessComponents.notificationsSettings.table.emailNotif.title",
    defaultMessage: "Single email",
  },
  emailDigestTitle: {
    id: "businessComponents.notificationsSettings.table.emailDigest.title",
    defaultMessage: "Email digest",
  },
  noData: {
    id: "businessComponents.notificationsSettings.table.noData",
    defaultMessage:
      "No notification customization is available for this application",
  },
  emailDigestNotAllowed: {
    id: "businessComponents.notificationsSettings.tooltip.EmailDigestNotAllowed",
    defaultMessage: "Email digest is not allowed for this notification type",
  },
  inAppIsMandatory: {
    id: "businessComponents.notificationsSettings.tooltip.inAppIsMandatory",
    defaultMessage: "If digest is activated, then in app is mandatory",
  },
  noSingleEmail: {
    id: "businessComponents.notificationsSettings.tooltip.NoSingleEmail",
    defaultMessage: "If digest is activated, then no single email will be send",
  },
  optionNotAllowed: {
    id: "businessComponents.notificationsSettings.tooltip.OptionNotAllowed",
    defaultMessage: "This option is not allowed for this notification type",
  },
});
