import React from "react";
import { useHistory } from "react-router-dom";
import { NotificationsPanel } from "IndexComponents";

const adjustIntercomButton = (isNotificationPanelOpen: boolean) => {
  [
    ".fake-intercom-popover-container",
    ".fake-intercom-icon-wrapper",
    ".fake-intercom",
    ".intercom-lightweight-app",
    ".intercom-app",
  ]
    .map(selector => document.querySelector(selector))
    .filter(Boolean)
    // eslint-disable-next-line no-undef
    .forEach((elem: HTMLElement) => {
      if (isNotificationPanelOpen) {
        elem.style.display = "none";
      } else {
        elem.style.display = null;
      }
    });
};

const Notifications: React.FC = () => {
  // @ts-ignore
  const settingsURL = `${window.env.TON_URL}/user-profile/notification-settings`;

  const history = useHistory();
  const handleNotificationPanelToggle = (isOpen: boolean) => {
    adjustIntercomButton(isOpen);
  };

  return (
    <li key="notificationPanel">
      <NotificationsPanel
        pathNotificationsSettings={settingsURL}
        history={history}
        onNotificationPanelToggle={handleNotificationPanelToggle}
      />
    </li>
  );
};

Notifications.displayName = "Notifications";

export default Notifications;
