import React from "react";
import { Typography, Button, Pagination } from "@trace-one/design-system";
import ProductListItem from "../ProductListItem";
import { ProductListProps } from "../../types";
import { useIntl } from "react-intl";
import messages from "../../translations/messages";

const ProductList: React.FC<ProductListProps> = ({
  total,
  products,
  handleAddSelectedProduct,
  handleRemoveSelectedProduct,
  isProductSelected,
  netContentMeasures,
  pagination,
}) => {
  const { formatMessage } = useIntl();

  return (
    <div className="ds-d-flex ds-flex-column ds-gap-3">
      <div className="ds-d-flex ds-flex-column ds-gap-3">
        <div className="ds-d-flex ds-justify-content-between">
          <Typography
            variant="body-regular"
            data-test-id="bs-modal-product-list-result-count"
          >
            {total} {formatMessage(messages.listResults)}
          </Typography>
          <div className="ds-d-flex">
            {/* Hiding this as we don;t have this in api yet */}
            {false && (
              <>
                <Typography variant="body-regular">
                  {formatMessage(messages.listSortBy)}
                </Typography>
                <Button
                  // onClick={handleSort}
                  color="secondary"
                  type="tertiary"
                  size="small"
                  iconName="arrow-down"
                />
              </>
            )}
          </div>
        </div>
        <div className="ds-d-flex ds-flex-column ds-gap-2">
          {products.map(product => {
            return (
              <ProductListItem
                key={product.id}
                onSelect={handleAddSelectedProduct}
                onDeSelect={handleRemoveSelectedProduct}
                selected={isProductSelected(product)}
                product={product}
                netContentMeasures={netContentMeasures}
              ></ProductListItem>
            );
          })}
        </div>
      </div>
      <div>
        <Pagination
          current={pagination.pageAndSize.page}
          onChange={pagination.onPageChange}
          defaultPageSize={10}
          total={total}
        ></Pagination>
      </div>
    </div>
  );
};

export default ProductList;
