import React, { useMemo, memo } from "react";
import { Card, Heading, Paragraph } from "@trace-one/design-system";
import { ProductListItemProps } from "../../types";
import { getPrefixClassName } from "src/utils/general";
import classNames from "classnames";

const prefixCls = getPrefixClassName("modal-product-select");
const dataTestIdPrefix = "bs-modal-product-select";

const ProductListItem: React.FC<ProductListItemProps> = ({
  selected,
  onSelect,
  onDeSelect,
  product,
  netContentMeasures,
}) => {
  const netContents = useMemo(() => {
    return product.netContents
      .map(({ unitOfMeasure, value }) => {
        const text = netContentMeasures[unitOfMeasure];
        return [value, text].filter(x => x).join(" ");
      })
      .join(" ; ");
  }, [product.netContents, netContentMeasures]);

  const headerAction = {
    key: 1,
    type: "button",
    title: selected ? "Remove" : "Add",
    args: {
      "data-test-id": `${
        selected
          ? "bs-moda-product-select-remove-btn"
          : "bs-moda-product-select-add-btn"
      }`,
      type: "tertiary",
      color: selected ? "red" : "",
      onClick: () => {
        if (selected) {
          onDeSelect(product.id);
        } else {
          onSelect(product);
        }
      },
    },
  };

  const buildTitle = product => {
    return ["itemName", "brandName", "gtin"]
      .map(k => product[k] || "NA")
      .join(" - ");
  };

  return (
    <Card
      title={buildTitle(product)}
      size="xxsmall"
      type="expandable"
      headerActions={[headerAction]}
      data-test-id={`${dataTestIdPrefix}-item-${product.id}`}
    >
      <div
        className={classNames(
          "ds-d-grid",
          "ds-gap-2",
          `${prefixCls}--product-card-content`
        )}
      >
        <div>
          <Heading size="xxs">Product name</Heading>
          <Paragraph data-test-id={`${dataTestIdPrefix}-productName`}>
            {product.itemName}
          </Paragraph>
        </div>
        <div className="ds-d-flex ds-flex-column gap-1">
          <Heading size="xxs">Product brand</Heading>
          <Paragraph data-test-id={`${dataTestIdPrefix}-brandName`}>
            {product.brandName}
          </Paragraph>
        </div>
        <div className="ds-d-flex ds-flex-column gap-1">
          <Heading size="xxs">Net content</Heading>
          <Paragraph data-test-id={`${dataTestIdPrefix}-netContents`}>
            {netContents || "--"}
          </Paragraph>
        </div>
        <div className="ds-d-flex ds-flex-column gap-1">
          <Heading size="xxs">Category</Heading>
          <Paragraph data-test-id={`${dataTestIdPrefix}-category`}>
            {product.category?.categoryId || "--"}
          </Paragraph>
        </div>
        <div className="ds-d-flex ds-flex-column gap-1">
          <Heading size="xxs">GTIN</Heading>
          <Paragraph data-test-id={`${dataTestIdPrefix}-gtin`}>
            {product.gtin || "--"}
          </Paragraph>
        </div>
        <div className="ds-d-flex ds-flex-column gap-1">
          <Heading size="xxs">Supplier</Heading>
          <Paragraph data-test-id={`${dataTestIdPrefix}-supplier`}>
            --
          </Paragraph>
        </div>
      </div>
    </Card>
  );
};

export default memo(ProductListItem);
