import { useEffect, useState } from "react";
import { getUnreadNotificationsCount } from "src/apis/notif";
import { CountsBySenderZone } from "src/apis/notif/types";

const REFRESH_INTERVAL_TIME_SECONDS = 60;

const useUnreadCounts = () => {
  const [unreadNotifications, setUnreadNotifications] = useState<number>(0);
  const [countsBySenderZones, setCountsBySenderZones] = useState<
    CountsBySenderZone[]
  >([]);

  const refreshCount = async () => {
    try {
      const {
        data: { totalUnreadCount, countsBySenderZones },
      } = await getUnreadNotificationsCount();
      setUnreadNotifications(totalUnreadCount);
      setCountsBySenderZones(countsBySenderZones);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const timeout = window.setInterval(() => {
      refreshCount();
    }, 1000 * REFRESH_INTERVAL_TIME_SECONDS);
    refreshCount();
    return () => {
      clearInterval(timeout);
    };
  }, []);

  return {
    unreadNotifications,
    countsBySenderZones,
    setUnreadNotifications,
    refreshCount,
  };
};

export default useUnreadCounts;
