import React from "react";
import { Heading } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import { notifSettingsContentMessages } from "src/components/UserProfilePage/translations/messages";
import { NotifSettingsProps } from "./types";
import Email from "./components/Email";
import Settings from "./components/Settings";

const NotifSettings: React.FC<NotifSettingsProps> = ({
  user,
  applicationTypeIds,
  isCompanyAdmin,
}) => {
  const { formatMessage } = useIntl();

  return (
    <div data-test-id="user-profile-content-notif-settings">
      <Heading size="m">
        {formatMessage(notifSettingsContentMessages.title)}
      </Heading>
      <div className="content-notif-settings">
        <Email user={user} />
        <Settings
          isUserAdmin={user.isAccountAdministrator}
          isCompanyAdmin={isCompanyAdmin}
          applicationTypeIds={applicationTypeIds}
        />
      </div>
    </div>
  );
};

export default NotifSettings;
