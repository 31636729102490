import { useState } from "react";
import { getOnOutsideMenuClick, getOnMenuToggle } from "../../utils";
import { UseMenuVisibilityProps } from "./types";

const useMenuVisibility = ({
  menuRef,
  burgerIconRef,
}: UseMenuVisibilityProps) => {
  const [isMenuVisible, setMenuVisible] = useState(false);

  const handleOutsideMenuClick = getOnOutsideMenuClick({
    menuRef,
    burgerIconRef,
    setMenuVisible,
  });

  const handleMenuToggle = getOnMenuToggle({
    setMenuVisible,
  });

  return { isMenuVisible, handleOutsideMenuClick, handleMenuToggle };
};

export default useMenuVisibility;
