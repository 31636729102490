import React from "react";
import { Button } from "@trace-one/design-system";

import "../../styles.less";
import { FiltersFooterProps } from "../../types";
import { useIntl } from "react-intl";
import { notificationsFilterMessage } from "../../translations/defineMessage";

const FiltersFooter: React.FC<FiltersFooterProps> = ({
  toggleFilters,
  onApplyFilters,
}) => {
  const { formatMessage } = useIntl();

  return (
    <footer
      className="notifications-filter-footer"
      data-test-id="notification-panel-filters-footer"
    >
      <div>
        <Button
          data-test-id="notifications-panel-footer-back-btn"
          type="secondary"
          onClick={toggleFilters}
        >
          {formatMessage(notificationsFilterMessage.footerButtonsBack)}
        </Button>

        <Button
          data-test-id="notifications-panel-footer-apply-btn"
          className="apply-btn"
          type="primary"
          onClick={onApplyFilters}
        >
          {formatMessage(notificationsFilterMessage.footerButtonsApply)}
        </Button>
      </div>
    </footer>
  );
};

export default FiltersFooter;
