export interface NotificationSettings {
  notificationPreferences?: NotificationPreference[];
  digestFrequency?: string;
}

export interface NotificationPreference {
  level?: string;
  senderZone?: string;
  notificationTypeId?: string;
  notificationTypeTitle?: string;
  communicationMethods?: string[];
  isDigestActive?: boolean;
  isUserAllowedToSubscribeInDigest?: boolean;
  notificationTypeDefaultCommunicationMethods?: string[];
}

export enum NotificationStatus {
  READ = "Read",
  UNREAD = "Unread",
}

export interface NotificationListItem {
  occurrenceUserInstanceId: string;
  notificationSubject: string;
  notificationContent: string;
  notificationUrl: string;
  notificationTypeTitle: string;
  senderZone: string;
  notificationEventUtcDateTime: string;
  status: NotificationStatus;
  notificationTypeId: string;
  isUserAllowedToSetPreferences: boolean;
}
