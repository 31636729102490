import React from "react";
import { Input, Button } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import { notifSettingsContentMessages } from "src/components/UserProfilePage/translations/messages";
import { EmailProps } from "./types";
import { Form } from "antd";
import useNotificationEmail from "./hooks/useNotificationEmail";

const Email: React.FC<EmailProps> = ({ user }) => {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();

  const {
    email,
    updateEmail,
    confirmationEmail,
    updateConfirmationEmail,
    updateNotifEmail,
  } = useNotificationEmail({
    userId: user.userId,
    userNotifEmail: user.userNotificationEmail,
  });

  return (
    <Form form={form} layout="vertical">
      <div
        className="content-notif-settings-email"
        data-test-id="user-profile-content-notif-settings-email"
      >
        <Form.Item
          name="email"
          label={formatMessage(notifSettingsContentMessages.email)}
          initialValue={email}
          rules={[
            {
              validator: _ => {
                if (email) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(
                    formatMessage(
                      notifSettingsContentMessages.emailIsRequiredErrorMessage
                    )
                  )
                );
              },
            },
          ]}
          data-test-id="user-profile-content-notif-settings-email-item"
        >
          <Input
            placeholder={formatMessage(
              notifSettingsContentMessages.emailPlaceholder
            )}
            value={email}
            onChange={e => updateEmail(e.target.value)}
            data-test-id="user-profile-content-notif-settings-select"
          />
        </Form.Item>
        <Form.Item
          name="emailVerification"
          label={formatMessage(notifSettingsContentMessages.emailVerification)}
          initialValue={confirmationEmail}
          required={false}
          rules={[
            {
              required: true,
              message: formatMessage(
                notifSettingsContentMessages.emailVerificationErrorMessage
              ),
            },
            ({ getFieldValue }) => ({
              validator (_, value) {
                if (!value || getFieldValue("email") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(
                    formatMessage(
                      notifSettingsContentMessages.emailVerificationErrorMessage
                    )
                  )
                );
              },
            }),
          ]}
          data-test-id="user-profile-content-notif-settings-emailVerification-item"
        >
          <Input
            placeholder={formatMessage(
              notifSettingsContentMessages.emailPlaceholder
            )}
            value={confirmationEmail}
            onChange={e => {
              updateConfirmationEmail(e.target.value);
            }}
            disabled={email === user.userNotificationEmail}
            data-test-id="user-profile-content-notif-settings-emailVerification-select"
          />
        </Form.Item>
        <Button
          htmlType="submit"
          onClick={updateNotifEmail}
          disabled={email === user.userNotificationEmail}
          data-test-id="user-profile-content-notif-settings-save-btn"
        >
          {formatMessage(notifSettingsContentMessages.btnSave)}
        </Button>
      </div>
    </Form>
  );
};

export default Email;
