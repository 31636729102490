import { useMemo, useState } from "react";
import { LibraryObjectData } from "../types";

const prepareSelectedLibraryObject = ({
  libraryObject,
  selectedLibraryObjects,
}: {
  libraryObject: LibraryObjectData;
  selectedLibraryObjects: LibraryObjectData[];
}) => {
  if (
    selectedLibraryObjects.findIndex(item => item.id === libraryObject.id) > -1
  ) {
    return selectedLibraryObjects.filter(item => item.id !== libraryObject.id);
  }
  return [...selectedLibraryObjects, libraryObject];
};

const useSelectedLibraryObject = ({
  existingLibraryObjectIds,
  multiple,
}: {
  existingLibraryObjectIds?: string[];
  multiple: boolean;
}) => {
  const [selectedLibraryObjects, setSelectedLibraryObjects] = useState<
    LibraryObjectData[]
  >([]);

  const onSelectLibraryObject = (libraryObject: LibraryObjectData) => {
    if (existingLibraryObjectIds?.includes(libraryObject.id)) return;
    if (multiple) {
      setSelectedLibraryObjects(
        prepareSelectedLibraryObject({ libraryObject, selectedLibraryObjects })
      );
    } else {
      setSelectedLibraryObjects([libraryObject]);
    }
  };

  const selectedLibraryObjectsIds = useMemo(
    () => selectedLibraryObjects?.map(libraryObject => libraryObject?.id),
    [selectedLibraryObjects]
  );

  return {
    selectedLibraryObjects,
    setSelectedLibraryObjects,
    onSelectLibraryObject,
    selectedLibraryObjectsIds,
  };
};

export default useSelectedLibraryObject;
