import React from "react";
import { useIntl } from "react-intl";
import { Heading, Select, Spinner } from "@trace-one/design-system";
import { useLanguages } from "./hooks";
import { settingsContentMessages } from "src/components/UserProfilePage/translations/messages";
import { LanguageProps } from "./types";

const Language: React.FC<LanguageProps> = ({ user, onLanguageChange }) => {
  const { formatMessage } = useIntl();
  const { languages, onUserLanguageChange, isLoading } = useLanguages({
    userId: user.userId,
    languageCode: user.userLanguagePreference,
    onLanguageChange,
  });

  if (isLoading) return <Spinner size="large" />;

  return (
    <>
      <Heading size="xxs">
        {formatMessage(settingsContentMessages.languageDescription)}
      </Heading>
      <div
        className="content-my-settings-language-select"
        data-test-id="user-profile-content-my-settings-language-select"
      >
        <Select
          options={languages}
          defaultValue={user.userLanguagePreference}
          onChange={onUserLanguageChange}
          allowClear={false}
        />
      </div>
    </>
  );
};

export default Language;
