/* istanbul ignore file */
import { useEffect, useState } from "react";
import { node } from "prop-types";
import { initializeInstancesManager } from "../../configs/AxiosInstancesManager";

const AxiosInstancesWrapper = ({
  children,
  axiosInstanceCUMD,
  axiosInstancePMD,
  axiosInstanceRLMD,
  axiosInstanceSMD,
  axiosInstanceTAGS,
  axiosInstancePKG,
  axiosInstanceCLB,
  axiosInstanceTON,
  axiosInstanceDOCUMENT,
  axiosInstanceNOTIF,
  axiosInstanceDISCUSS,
  axiosInstanceCATALOGUE,
}) => {
  const [
    isInstancesInitializationFinished,
    setIsInstancesInitializationFinished,
  ] = useState(false);

  useEffect(() => {
    initializeInstancesManager({
      axiosInstanceCUMD,
      axiosInstancePMD,
      axiosInstanceRLMD,
      axiosInstanceSMD,
      axiosInstanceTAGS,
      axiosInstancePKG,
      axiosInstanceCLB,
      axiosInstanceTON,
      axiosInstanceDOCUMENT,
      axiosInstanceNOTIF,
      axiosInstanceDISCUSS,
      axiosInstanceCATALOGUE,
    });

    setIsInstancesInitializationFinished(true);
  }, []);

  return isInstancesInitializationFinished ? children : null;
};

AxiosInstancesWrapper.propTypes = {
  children: node.isRequired,
};

export default AxiosInstancesWrapper;
