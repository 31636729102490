import { useEffect, useState } from "react";
import { CustomDropdownProps } from "src/components/UploadFiles/components/types";

const useDropdown = ({
  defaultValue,
  onChangeCallback,
  selectedValue,
  options,
}: {
  defaultValue: string;
  onChangeCallback: Function;
  selectedValue?: string;
  options?: CustomDropdownProps["options"];
}) => {
  const [value, setValue] = useState<string>(null);

  useEffect(() => {
    const isValidSelectedValue =
      selectedValue && options?.some(option => option?.value === selectedValue);

    const isValidDefaultValue =
      defaultValue && options?.some(option => option?.value === defaultValue);

    if (isValidSelectedValue || isValidDefaultValue) {
      setValue(isValidSelectedValue ? selectedValue : defaultValue);
    }
  }, [selectedValue, defaultValue]);

  const onChange = (fileId: string) => (value: string) => {
    setValue(value);
    onChangeCallback?.({ fileId, selectedValue: value });
  };

  return { onChange, value };
};

export default useDropdown;
