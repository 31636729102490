import React from "react";
import { Picker } from "@trace-one/design-system";
import { ProductSearchProps } from "../../types";
import { Filter } from "IndexComponents";
import useBrands from "../../hooks/useBrands";
import AsyncPickerDropdown from "../AsyncPickerDropdown";
import useGetCompanies from "../../hooks/useGetCompanies";
import { useIntl } from "react-intl";
import messages from "../../translations/messages";

const ProductSearch: React.FC<ProductSearchProps> = ({
  isRetailer,
  ownerCompanyId,
  selectedFilters,
  mergeFilterValues,
  clearFilterValues,
  clearAllFilters,
  handleSearch,
}) => {
  const { formatMessage } = useIntl();

  const { brandOptions, searchBrandByName } = useBrands({
    ownerCompanyId,
  });
  const { suppliersOptions, searchCompanies, makeCompanyOptions } =
    useGetCompanies({
      companyId: ownerCompanyId,
    });

  const filters = [
    {
      title: formatMessage(messages.filterSupplierTitle),
      controlType: "single_selection",
      clearSelection: () => {
        clearFilterValues("supplier");
      },
      elementToRender: null,
      quickFilter: (
        <AsyncPickerDropdown
          selectionType="single"
          data-test-id="picker-filter-supplier"
          searchPlaceholderText={"Search"}
          clearBtnText={"Clear"}
          closeBtnText={"Close"}
          title={formatMessage(messages.filterSupplierTitle)}
          showBadgeCount
          defaultOptions={suppliersOptions}
          value={
            selectedFilters.find(x => x.filterId === "supplier")?.values ?? []
          }
          onSearch={undefined}
          showRadioButtons
          minLengthToSearch={3}
          onAsyncSearch={searchValue => {
            return searchCompanies(searchValue).then(makeCompanyOptions);
          }}
          searchOnChange
          showSearchInput
          onChange={({ selectedItem, value }) => {
            console.log(selectedItem, value);
            mergeFilterValues("supplier", value);
          }}
          clearSelection={() => {
            clearFilterValues("supplier");
          }}
        ></AsyncPickerDropdown>
      ),
      filterId: "supplier",
    },
    {
      title: formatMessage(messages.filterBrandTitle),
      controlType: "single_selection",
      clearSelection: () => {
        clearFilterValues("brand");
      },
      elementToRender: null,
      quickFilter: (
        <AsyncPickerDropdown
          selectionType="single"
          data-test-id="picker-filter-brand"
          searchPlaceholderText={"Search"}
          clearBtnText={"clear"}
          closeBtnText={"close"}
          title={formatMessage(messages.filterBrandTitle)}
          showBadgeCount
          showRadioButtons
          showSearchInput
          defaultOptions={brandOptions}
          value={
            selectedFilters.find(x => x.filterId === "brand")?.values ?? []
          }
          searchOnChange
          onAsyncSearch={undefined}
          minLengthToSearch={1}
          onSearch={searchBrandByName}
          onChange={({ _, value }) => {
            mergeFilterValues("brand", value);
          }}
          clearSelection={() => {
            clearFilterValues("brand");
          }}
        ></AsyncPickerDropdown>
      ),
      filterId: "brand",
    },
    {
      title: formatMessage(messages.filterWithSuppliersTitle),
      controlType: "single_selection",
      clearSelection: () => {},
      elementToRender: null,
      quickFilter: (
        <Picker.Filter
          title={formatMessage(messages.filterWithSuppliersTitle)}
          value={
            selectedFilters.find(x => x.filterId === "with_suppliers")
              ?.values || []
          }
          selectionType="single"
          showRadioButtons
          items={[
            { label: "Yes", value: "yes" },
            { label: "No", value: "no" },
          ]}
          onChange={event => {
            mergeFilterValues("with_suppliers", event.value);
          }}
          clearSelection={() => clearFilterValues("with_suppliers")}
        ></Picker.Filter>
      ),
      filterId: "with_suppliers",
    },
  ];

  return (
    <Filter
      data-test-id="bs-modal-product-select-product-filter"
      filters={filters}
      selectedFilters={selectedFilters}
      clearAllFilters={clearAllFilters}
      hideAllFilters
      search={{
        // defaultValue: initialSearchValue,
        placeholder: formatMessage(messages.filterSearchPlaceholder),
        onSearch: query => {
          handleSearch(query);
        },
      }}
      onAllFilterPanelToggle={() => {}}
    />
  );
};

export default ProductSearch;
