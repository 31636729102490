import { useEffect, useState } from "react";
import {
  fetchPackagingSystemById,
  fetchPackagingComponentCollection,
} from "src/apis/pkg";
import { isListEmpty } from "src/utils/general";
import { ComponentsProps } from "../../components/SelectPackagingComponent/types";
import { PackagingComponentData } from "../../types";
import { setComponentData } from "../../utils/helpers";

export interface PackagingSystemType {
  name: string;
  components: ComponentsProps[];
  allowsUpdate: boolean;
}

const usePackagingSystem = ({ packagingSystemId, setComponents }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [packagingSystem, setPackagingSystem] = useState<
    PackagingSystemType | undefined
  >();
  const [updatedRecords, setUpdatedRecords] = useState<
    PackagingComponentData[]
  >([]);

  const queryPackaging = async () => {
    try {
      setIsLoading(true);
      const data = await fetchPackagingSystemById(packagingSystemId);
      if (isListEmpty(data?.components)) {
        setPackagingSystem(data);
        return;
      }

      const componentIds = data?.components?.map(
        component => component?.componentId
      );

      const componentsCollection = await fetchPackagingComponentCollection(
        componentIds
      );

      const packagingSystemWithComponentsData = {
        ...data,
        components: componentsCollection,
      };

      setUpdatedRecords(prevState => [
        ...prevState,
        ...componentsCollection?.map(setComponentData),
      ]);
      setPackagingSystem(packagingSystemWithComponentsData);
    } catch (error) {
      console.error("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const setComponentValue = async newComponents => {
    try {
      setIsLoading(true);
      const arrayOfIds = newComponents.map(obj => obj.id);
      const componentsCollection = await fetchPackagingComponentCollection(
        arrayOfIds
      );
      setUpdatedRecords(prevState => [
        ...prevState,
        ...componentsCollection?.map(setComponentData),
      ]);
      setComponents(previousState => {
        const filteredComponent = previousState.filter(val => val.name !== "");
        return [...filteredComponent, ...componentsCollection];
      });
    } catch (_) {
      setComponents([]);
      setUpdatedRecords([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (packagingSystemId) {
      queryPackaging();
    }
  }, [packagingSystemId]);

  return {
    packagingSystem,
    isLoading,
    setComponentValue,
    updatedRecords,
    setUpdatedRecords,
  };
};

export default usePackagingSystem;
