import React from "react";
import { useIntl } from "react-intl";
import { Heading, Select } from "@trace-one/design-system";
import { settingsContentMessages } from "../../../../../translations/messages";
import { useTimezones } from "src/hooks";
import { TimeZonesProps } from "./types";

const TimeZone: React.FC<TimeZonesProps> = ({ user }) => {
  const { formatMessage } = useIntl();

  const { timezones, updateTimeZone } = useTimezones({
    userId: user.userId,
  });

  return (
    <div
      className="content-my-settings-details-container-timezone"
      data-test-id="user-profile-content-my-settings-details-timezone"
    >
      <Heading size="xxs">
        {formatMessage(settingsContentMessages.timezone)}
      </Heading>
      <div
        className="content-my-settings-timezone-select"
        data-test-id="user-profile-content-my-settings-details-timezone-select"
      >
        <Select
          options={timezones.map(timezone => ({
            value: timezone.timeZoneId,
            name: timezone.timeZoneDisplayName,
          }))}
          defaultValue={user.timeZone}
          onChange={updateTimeZone}
          allowClear={false}
          showSearch
          optionFilterProp="name"
        />
      </div>
    </div>
  );
};

export default TimeZone;
