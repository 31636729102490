import { defineMessages } from "react-intl";

export const messages = defineMessages({
  errorMessage: {
    id: "specificationDetails.signature.error",
    defaultMessage: "Sorry an error ocurred, please try again.",
  },
  progress: {
    id: "quickview.progressList.title",
    defaultMessage: "Progress",
  },
  detailsTitle: {
    id: "specificationDetails.details.title",
    defaultValue: "Specification's Details",
  },
  detailsSubtitle: {
    id: "specificationDetails.details.subtitle",
    defaultValue: "Fill, collaborate and validate Specification's sections",
  },
  validationLabel: {
    id: "specificationDetails.sections.validationLabel",
    defaultMessage: "Validate",
  },
  to: {
    id: "specificationDetails.widgets.seasonality.to",
    defaultMessage: "to",
  },
  none: {
    id: "specificationDetails.widgets.seasonality.none",
    defaultMessage: "None",
  },
  jan: {
    id: "specificationDetails.widgets.seasonality.jan",
    defaultMessage: "Jan",
  },
  feb: {
    id: "specificationDetails.widgets.seasonality.feb",
    defaultMessage: "Feb",
  },
  mar: {
    id: "specificationDetails.widgets.seasonality.mar",
    defaultMessage: "Mar",
  },
  apr: {
    id: "specificationDetails.widgets.seasonality.apr",
    defaultMessage: "Apr",
  },
  may: {
    id: "specificationDetails.widgets.seasonality.may",
    defaultMessage: "May",
  },
  jun: {
    id: "specificationDetails.widgets.seasonality.jun",
    defaultMessage: "Jun",
  },
  jul: {
    id: "specificationDetails.widgets.seasonality.jul",
    defaultMessage: "Jul",
  },
  aug: {
    id: "specificationDetails.widgets.seasonality.aug",
    defaultMessage: "Aug",
  },
  sep: {
    id: "specificationDetails.widgets.seasonality.sep",
    defaultMessage: "Sep",
  },
  oct: {
    id: "specificationDetails.widgets.seasonality.oct",
    defaultMessage: "Oct",
  },
  nov: {
    id: "specificationDetails.widgets.seasonality.nov",
    defaultMessage: "Nov",
  },
  dec: {
    id: "specificationDetails.widgets.seasonality.dec",
    defaultMessage: "Dec",
  },
  signatureDateUtcEmptyValue: {
    id: "specificationDetails.widgets.signatureDateUtc.emptyValue",
    defaultMessage: "Not signed",
  },
  firstBatchNumberPlaceholder: {
    id: "specificationDetails.widgets.firstBatchNumber.placeholder",
    defaultMessage: "Enter the first batch number",
  },
  lastBatchNumberPlaceholder: {
    id: "specificationDetails.widgets.lastBatchNumber.placeholder",
    defaultMessage: "Enter the last batch number",
  },
  firstProductionDateUtcPlaceholder: {
    id: "specificationDetails.widgets.productionDateUtc.placeholder",
    defaultMessage: "dd/mm/yyyy",
  },
  deadlinePlatformAcceptanceUnitPlaceholder: {
    id: "specificationDetails.widgets.deadlinePlatformAcceptance.unit.placeholder",
    defaultMessage: "Enter a number",
  },
  deadlinePlatformAcceptanceReflistPlaceholder: {
    id: "specificationDetails.widgets.deadlinePlatformAcceptance.reflist.placeholder",
    defaultMessage: "d (day)",
  },
  lastProductionDateUtcPlaceholder: {
    id: "specificationDetails.widgets.productionDateUtc.placeholder",
    defaultMessage: "dd/mm/yyyy",
  },
  startDatePlaceholder: {
    id: "specificationDetails.widgets.availabilitytDate.placeholder",
    defaultMessage: "dd Month",
  },
  endDatePlaceholder: {
    id: "specificationDetails.widgets.availabilitytDate.placeholder",
    defaultMessage: "dd Month",
  },
  caliberAndCategoryTitle: {
    id: "specificationDetails.widgets.caliberAndCategory.title",
    defaultMessage: "CALIBER & CATEGORY",
  },
  weightTextPlaceholder: {
    id: "specificationDetails.widgets.weight.text.placeholder",
    defaultMessage: "Type a weight",
  },
  weightListPlaceholder: {
    id: "specificationDetails.widgets.weight.list.placeholder",
    defaultMessage: "Unit",
  },
  materialPlaceholder: {
    id: "specificationDetails.widgets.material.placeholder",
    defaultMessage: "Choose a material in the classification",
  },
  sizeTextPlaceholder: {
    id: "specificationDetails.widgets.size.text.placeholder",
    defaultMessage: "Type a size",
  },
  sizeListPlaceholder: {
    id: "specificationDetails.widgets.size.list.placeholder",
    defaultMessage: "Unit",
  },
  caliberCodePlaceholder: {
    id: "specificationDetails.widgets.caliberCode.placeholder",
    defaultMessage: "Specify the caliber",
  },
  categoryCodePlaceholder: {
    id: "specificationDetails.widgets.categoryCode.placeholder",
    defaultMessage: "Specify the category",
  },
  originTitle: {
    id: `specificationDetails.widgets.origin.title`,
    defaultMessage: "Origin",
  },
  countryIdPlaceholder: {
    id: "specificationDetails.widgets.countryId.placeholder",
    defaultMessage: "Select the country of origin",
  },
  regionIdPlaceholder: {
    id: "specificationDetails.widgets.regionId.placeholder",
    defaultMessage: "Select the region",
  },
  zoneOrDepartmentIdPlaceholder: {
    id: "specificationDetails.widgets.zoneOrDepartmentId.placeholder",
    defaultMessage: "Select the zone",
  },
  seedsTitle: {
    id: "specificationDetails.widgets.seeds.title",
    defaultMessage: "SEEDS",
  },
  treatmentsTitle: {
    id: "specificationDetails.widgets.treatments.title",
    defaultMessage: "TREATMENTS",
  },
  productNamePlaceholder: {
    id: "specificationDetails.widgets.productName.placeholder",
    defaultMessage: "List the product(s) used",
  },
  addAComponent: {
    id: "specificationDetails.widgets.packagings.addAComponent",
    defaultMessage: "Add a component",
  },
  addATreatment: {
    id: "specificationDetails.widgets.treatments.addATreatment",
    defaultMessage: "Add a treatment",
  },
  addAnAllergen: {
    id: "specificationDetails.widgets.allergens.addAnAllergen",
    defaultMessage: "Add an allergen",
  },
  noAllergens: {
    id: "specificationDetails.widgets.allergens.noAllergens",
    defaultMessage: "No allergens",
  },
  noPackagingAdded: {
    id: "specificationDetails.widgets.packagings.noPackagingAdded",
    defaultMessage: "No packaging",
  },
  noTreatmentAdded: {
    id: "specificationDetails.widgets.treatments.noTreatmentAdded",
    defaultMessage: "No treatment added",
  },
  noControlPlanAdded: {
    id: "specificationDetails.widgets.tables.noControlPlanAdded",
    defaultMessage: "No Control Plan",
  },
  noAllergenAdded: {
    id: "specificationDetails.widgets.tables.noAllergenAdded",
    defaultMessage: "You can start to add allergens with the button bellow",
  },
  noIngredientAdded: {
    id: "specificationDetails.widgets.tables.noIngredientAdded",
    defaultMessage: "You can start to add ingredients with the buttons bellow",
  },
  noStorageConditions: {
    id: "specificationDetails.widgets.storageConditions.noStorageConditions",
    defaultMessage: "No storage conditions",
  },
  yes: {
    id: "general.yes",
    defaultMessage: "Yes",
  },
  no: {
    id: "general.no",
    defaultMessage: "No",
  },
  close: {
    id: "general.close",
    defaultMessage: "Close",
  },
  addAnOrigin: {
    id: "specificationDetails.widgets.alternativeOrigins.AddAnOrigin",
    defaultMessage: "Add an origin",
  },
  natureTitle: {
    id: "specificationDetails.widgets.nature.title",
    defaultMessage: "Nature",
  },
  specieIdPlaceholder: {
    id: "specificationDetails.widgets.specieId.placeholder",
    defaultMessage: "Select a specie",
  },
  varietyIdsPlaceholder: {
    id: "specificationDetails.widgets.varietyIds.placeholder",
    defaultMessage: "Select one or multiple varieties",
  },
  alternativeVarietyIdsPlaceholder: {
    id: "specificationDetails.widgets.alternativeVarietyIds.placeholder",
    defaultMessage: "Select one or multiple varieties",
  },
  acceptsAlternativeVarietyTitle: {
    id: "specificationDetails.widgets.acceptsAlternativeVariety.title",
    defaultMessage: "Accept alternative variety",
  },
  addAStorageCondition: {
    id: "specificationDetails.widgets.storageConditions.addAStorageCondition",
    defaultMessage: "Add a storage condition",
  },
  confirmRemoveTitle: {
    id: "specificationDetails.widgets.confirmRemove.title",
    defaultMessage: "This will remove related data. Are you sure ?",
  },
  radiationTitle: {
    id: "specificationDetails.widgets.radiation.title",
    defaultMessage: "RADIATION",
  },
  cleaningAndSanitationTitle: {
    id: "specificationDetails.widgets.cleaningAndSanitation.title",
    defaultMessage: "Cleaning & Sanitation",
  },
  negativePercentage: {
    id: "general.errors.negativePercentage",
    defaultMessage: "Percentage should be greater than 0",
  },
  missingSpecificationSignatory: {
    id: "specificationDetails.sections.errors.missingSpecificationSignatory",
    defaultMessage: "At least one contact must be set as signatory",
  },
  exclusiveMinimum: {
    id: "specificationDetails.widgets.errors.exclusiveMinimum",
    defaultMessage:
      "The selected number is lower than the minimum authorized value",
  },
  ExclusiveMinimum: {
    id: "specificationDetails.widgets.errors.exclusiveMinimum",
    defaultMessage:
      "The selected number is lower than the minimum authorized value",
  },
  maximum: {
    id: "specificationDetails.widgets.errors.maximum",
    defaultMessage:
      "The selected number is bigger than the maximum authorized value",
  },
  LessThan: {
    id: "specificationDetails.widgets.errors.lessThan",
    defaultMessage: "Minimum value must be greater than the maximum",
  },
  GreaterThan: {
    id: "specificationDetails.widgets.errors.greaterThan",
    defaultMessage: "Maximum value must be greater than the minimum",
  },
  ValidationRequired: {
    id: "specificationDetails.widgets.errors.validationRequired",
    defaultMessage: "This field is mandatory",
  },
  ProofFileRequired: {
    id: "specificationDetails.widgets.errors.proofFileRequired",
    defaultMessage: "Proof file is mandatory",
  },
  MinimumItemsValidationRequired: {
    id: "specificationDetails.widgets.errors.minimumItemsValidationRequired",
    defaultMessage: "At least one item is necessary",
  },
  MissingSpecificationSignatory: {
    id: "specificationDetails.widgets.errors.missingSpecificationSignatory",
    defaultMessage: "At least one contact must be set as signatory",
  },
  UnexpectedSeasonalityStartDate: {
    id: "specificationDetails.widgets.errors.unexpectedSeasonalityStartDate",
    defaultMessage: "Seasonality start date format is incorrect ",
  },
  UnexpectedSeasonalityEndDate: {
    id: "specificationDetails.widgets.errors.unexpectedSeasonalityEndDate",
    defaultMessage: "Seasonality end date format is incorrect",
  },
  MissingSeasonalityStartDate: {
    id: "specificationDetails.widgets.errors.missingSeasonalityStartDate",
    defaultMessage: "Please enter a start date for seasonality",
  },
  MissingSeasonalityEndDate: {
    id: "specificationDetails.widgets.errors.missingSeasonalityEndDate",
    defaultMessage: "Please enter an end date for seasonality",
  },
  InvalidSeasonalityRange: {
    id: "specificationDetails.widgets.errors.invalidSeasonalityRange",
    defaultMessage: "The selected seasonality range is not valid",
  },
  maxLength: {
    id: "specificationDetails.widgets.errors.maxLength",
    defaultMessage: "This field value can not exceed {maxLength} characters",
  },
  SumOfRootIngredientsPercentageNotHundred: {
    id: "specificationDetails.widgets.errors.sumOfRootIngredientsPercentageNotHundred",
    defaultMessage:
      "The sum of the ingredient's percentages in your recipe must be 100%",
  },
  packingDatePlaceholder: {
    id: "specificationDetails.widgets.packingDate.placeholder",
    defaultMessage: "Pick a date",
  },
  packingTextPlaceholder: {
    id: "specificationDetails.widgets.packingText.placeholder",
    defaultMessage: "Enter a code",
  },
  guaranteedShelfLifeTitle: {
    id: "specificationDetails.widgets.guaranteedShelfLife.title",
    defaultMessage: "Guaranteed shelf life (since occurrence)",
  },
  comboTitle: {
    id: "specificationDetails.widgets.comboTitle.title",
    defaultMessage: "Slaughter, catch, harvest",
  },
  rangeNumberMinValuePlaceholder: {
    id: "specificationDetails.widgets.rangeNumber.minValue.placeholder",
    defaultMessage: "Min.",
  },
  rangeNumberMaxValuePlaceholder: {
    id: "specificationDetails.widgets.rangeNumber.maxValue.placeholder",
    defaultMessage: "Max.",
  },
  rangeNumberUnitPlaceholder: {
    id: "specificationDetails.widgets.rangeNumber.unit.placeholder",
    defaultMessage: "Unit",
  },
  refListPlaceholder: {
    id: "specificationDetails.widgets.rangeNumber.reflist.placeholder",
    defaultMessage: "Select a value",
  },
  selectPreservationMethodsPlaceholder: {
    id: "specificationDetails.widgets.preservationMethods.placeholder",
    defaultMessage: "Select one or more entries",
  },
  calendar: {
    id: "specificationDetails.widgets.codesAndDates.calendar",
    defaultMessage: "calendar",
  },
  code: {
    id: "specificationDetails.widgets.codesAndDates.code",
    defaultMessage: "Code",
  },
  deadLinePlatformAcceptanceTitle: {
    id: "specificationDetails.widgets.deadLinePlatformAcceptance.title",
    defaultMessage: "Deadline for platform acceptance",
  },
  retailerInformationTitle: {
    id: "specificationDetails.widgets.retailerInformation.title",
    defaultMessage: "Retailer information",
  },
  addNetContent: {
    id: "specificationDetails.widgets.labelling.productInformation.netContent.addButton",
    defaultMessage: "Add net content",
  },
  informationTitle: {
    id: "specificationDetails.widgets.labelling.additionalMarketingInformation.information.title",
    defaultMessage: "Information",
  },
  consumerInformationTitle: {
    id: "specificationDetails.widgets.labelling.consumerInstruction.consumerInformation.title",
    defaultMessage: "Storage and conservation",
  },
  cookingInstructionsTitle: {
    id: "specificationDetails.widgets.labelling.consumerInstruction.cookingInstructions.title",
    defaultMessage: "Cooking instructions",
  },
  codeAndDatesTitle: {
    id: "specificationDetails.widgets.labelling.consumerInstruction.codeAndDates.title",
    defaultMessage: "Codes and dates",
  },
  consumerShelfLifeTitle: {
    id: "specificationDetails.sections.shelfLife.consumerShelfLifeTitle",
    defaultMessage: "Consumer shelf life indication(s) on pack",
  },
  productionDateTitle: {
    id: "specificationDetails.sections.shelfLife.productionDateTitle",
    defaultMessage: "Production date indication(s)",
  },
  placeholderMultipleSelect: {
    id: "specificationDetails.sections.shelfLife.placeholderMultipleSelect",
    defaultMessage: "Select one or more choices",
  },
  placeholderShelfLifeIndication: {
    id: "specificationDetails.sections.shelfLife.placeholderShelfLifeIndication",
    defaultMessage: "Select one or more choices",
  },
  placeholderProductionIndication: {
    id: "specificationDetails.sections.shelfLife.placeholderProductionIndication",
    defaultMessage: "Select one or more choices",
  },
  templateTypeIdTitle: {
    id: "specificationDetails.widgets.templateTypeId.title",
    defaultMessage: "Type",
  },
  categoryTitle: {
    id: "filters.category",
    defaultMessage: "Category",
  },
  netContentTitle: {
    id: "filters.netContent",
    defaultMessage: "Net content",
  },
  seasonalityTitle: {
    id: "specificationDetails.widgets.seasonality.title",
    defaultMessage: "Seasonal availability",
  },
  dryWeightTitle: {
    id: "specificationDetails.widgets.dryWeight.title",
    defaultMessage: "Dry weight",
  },
  mainPackagingTitle: {
    id: "specificationDetails.widgets.mainPackaging.title",
    defaultMessage: "Main packaging ",
  },
  packagingTypeTitle: {
    id: "specificationDetails.widgets.packagingType.title",
    defaultMessage: "Packaging type",
  },
  gtinTitle: {
    id: "filters.gtin",
    defaultMessage: "GTIN",
  },
  ean7Title: {
    id: "specificationDetails.widgets.ean7.title",
    defaultMessage: "EAN7 (variable weight)",
  },
  internalGencodeTitle: {
    id: "specificationDetails.widgets.internalGencode.title",
    defaultMessage: "Internal Gencode",
  },
  seasonalityTitleRow: {
    id: "specificationDetails.widgets.seasonalityRow.title",
    defaultMessage: "SEASONALITY",
  },
  displayGtin: {
    id: "specificationDetails.widgets.codesAndDates.displayGtin",
    defaultMessage: "Display Gtin",
  },
  noItemAdded: {
    id: "specificationDetails.widgets.array.noItemAdded",
    defaultMessage: "No results found",
  },
  displayProductNameOnPackTitle: {
    id: "specificationDetails.widgets.displayProductNameOnPack.title",
    defaultMessage: "Product name",
  },
  displayGtinOnPackTitle: {
    id: "specificationDetails.widgets.displayGtinOnPack.title",
    defaultMessage: "GTIN",
  },
  rawMaterial: {
    id: "library.table.type.rawMaterial",
    defaultMessage: "Raw material",
  },
  composite: {
    id: "library.table.type.composite",
    defaultMessage: "Composite",
  },
  packagingComponentActions: {
    id: "packaging.component.actions",
    defaultMessage: "Actions",
  },
  packagingComponentActionsEdit: {
    id: "shared.business.packaging.component.actions.edit",
    defaultMessage: "Edit",
  },
  packagingComponentNameLabel: {
    id: "packaging.component.nameLabel",
    defaultMessage: "Name",
  },
  packagingComponentUsageLevelLabel: {
    id: "packaging.component.usageLevelLabel",
    defaultMessage: "Usage level",
  },

  delete: {
    id: "shared.business.components.general.delete",
    defaultMessage: "Delete",
  },
  cancel: {
    id: "shared.business.components.general.cancel",
    defaultMessage: "Cancel",
  },
  duplicate: {
    id: "shared.business.components.general.duplicate",
    defaultMessage: "Duplicate",
  },
});
