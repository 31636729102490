import React from "react";
import { Button } from "antd";
import { FilterItemBodyProps } from "src/components/SearchFilter/types";
import { useIntl } from "react-intl";
import { searchFilterActions } from "src/components/SearchFilter/translations/defineMessage";

const FilterItemBody: React.FC<FilterItemBodyProps> = ({
  filter,
  selectedValues = [],
}) => {
  const { formatMessage } = useIntl();
  if (filter.controlType === "multi_selection") {
    return (
      <div className="allfilters-filter-control-wraper">
        {selectedValues.map((x, i) => {
          const isLast = i + 1 === selectedValues.length;
          return (
            <span key={i}>
              {x}
              {!isLast && ", "}
            </span>
          );
        })}

        <div>
          {!!selectedValues?.length && (
            <Button
              data-test-id={`bs-search-filter-muti-select-body-clear-btn-${filter.filterId}`}
              type="link"
              className="allfilters-clear-selection-btn"
              onClick={filter.clearSelection}
            >
              {formatMessage(
                searchFilterActions.allFiltersActionClearSelectionMultiselect
              )}
            </Button>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="allfilters-filter-control-wraper">
      {filter.elementToRender}
    </div>
  );
};

export default FilterItemBody;
