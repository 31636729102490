import { defineMessages } from "react-intl";

export const sidebarNavigationMessages = defineMessages({
  your_applications: {
    id: "sidebar_navigation.your_applications",
    defaultMessage: "Your applications",
  },
  help_center: {
    id: "sidebar_navigation.help_center",
    defaultMessage: "Help Center",
  },
});
