import React from "react";
import { useIntl } from "react-intl";
import { Heading, Select } from "@trace-one/design-system";
import { CountryProps } from "./types";
import { settingsContentMessages } from "../../../../../translations/messages";
import { useCountries } from "src/hooks";

const Country: React.FC<CountryProps> = ({ user }) => {
  const { formatMessage } = useIntl();

  const { countries, updateCountry } = useCountries({
    userId: user.userId,
    languageCode: user.userLanguagePreference,
  });

  return (
    <div
      className="content-my-settings-details-container-country"
      data-test-id="user-profile-content-my-settings-details-country"
    >
      <Heading size="xxs">
        {formatMessage(settingsContentMessages.country)}
      </Heading>
      <div
        className="content-my-settings-country-select"
        data-test-id="user-profile-content-my-settings-details-country-select"
      >
        <Select
          options={countries.map(country => ({
            value: country.value,
            name: country.name,
          }))}
          defaultValue={user.country}
          onChange={updateCountry}
          allowClear={false}
          showSearch
          optionFilterProp="name"
        />
      </div>
    </div>
  );
};

export default Country;
