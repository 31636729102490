import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { downloadFile as apiDownloadFile } from "src/apis/document";
import { errorMessages } from "src/components/UploadFiles/translations/messages";
import { downloadFile } from "src/utils/general";
import { FileStatusType } from "../../../types";
import { DraggerProps } from "../../../Dragger/types";

const useItem = ({
  initialStatus,
  initialErrorMessage,
  fileId,
  fileName,
  messages,
}: {
  initialStatus?: FileStatusType;
  initialErrorMessage?: string;
  fileId?: string;
  fileName?: string;
  messages?: DraggerProps["messages"];
}) => {
  const { formatMessage } = useIntl();
  const [status, setStatus] = useState(initialStatus);
  const [errorMessage, setErrorMessage] = useState(initialErrorMessage);

  const hasError = useMemo(() => status === "error", [status]);

  const isUploading = useMemo(
    () => status === "uploading" || status === "downloading",
    [status]
  );

  const onDownload = async () => {
    if (fileId && !isUploading) {
      try {
        setStatus("downloading");

        const { data } = await apiDownloadFile(fileId);

        downloadFile(data, fileName);

        setStatus("done");
      } catch (_) {
        setStatus("error");

        setErrorMessage(
          messages?.download ?? formatMessage(errorMessages.download)
        );
      } finally {
      }
    }
  };

  useEffect(() => {
    setStatus(initialStatus);
  }, [initialStatus]);

  useEffect(() => {
    setErrorMessage(initialErrorMessage);
  }, [initialErrorMessage]);

  return { status, errorMessage, hasError, isUploading, onDownload };
};

export default useItem;
