import { InputProps } from "antd";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { getHandleDebouncedValueChange } from "./utils";
import useDebounce from "../useDebounce/useDebounce";

export const useDebouncedActions = ({
  defaultValue,
  onChange,
  debounceTimer = 2000,
  shouldApplyDebounceImmediate,
}: {
  defaultValue: InputProps["defaultValue"];
  onChange: Function;
  debounceTimer?: number;
  shouldApplyDebounceImmediate?: boolean;
}): {
  onInputNumberChange: ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => void;
  onTextChange: ({ target: { value } }: ChangeEvent<HTMLInputElement>) => void;
  onTextareaChange: (value: string) => void;
  handleDebouncedValueChange: () => void;
  currentValue: InputProps["value"];
} => {
  const [currentValue, setCurrentValue] = useState(defaultValue);

  const debouncedValue = useDebounce(currentValue, debounceTimer);

  const currentValueRef = useRef(defaultValue);
  const defaultValueRef = useRef(defaultValue);

  const handleDebouncedValueChange = () => {
    if (currentValue === defaultValue) return;

    onChange(currentValue);
  };

  const onTextChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(value);
  };

  const onTextareaChange = (value: string) => {
    setCurrentValue(value);
  };

  const onInputNumberChange = value => {
    setCurrentValue(value);
  };

  useEffect(() => {
    handleDebouncedValueChange();
  }, [debouncedValue]);

  useEffect(() => {
    currentValueRef.current = currentValue;
  }, [currentValue]);

  useEffect(() => {
    setCurrentValue(defaultValue);

    defaultValueRef.current = defaultValue;
  }, [defaultValue]);

  useEffect(() => {
    return () => {
      if (shouldApplyDebounceImmediate) {
        getHandleDebouncedValueChange({
          currentValue: currentValueRef.current,
          defaultValue: defaultValueRef.current,
          onChange,
        })();
      }
    };
  }, []);

  return {
    onInputNumberChange,
    onTextChange,
    onTextareaChange,
    handleDebouncedValueChange,
    currentValue,
  };
};
