import React, { useRef } from "react";
import {
  Modal,
  ToggleButtons,
  Heading,
  Spinner,
  Search,
} from "@trace-one/design-system";
import { useIntl } from "react-intl";
import { Col, Row } from "antd";
import classnames from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";
import { useModalVisibility } from "src/hooks";
import { withLanguageData } from "src/hocs";
import {
  useDisplayItems,
  useDMS,
  useSelectedFiles,
  useUserDetails,
} from "./hooks";
import Item from "./components/Item";
import { DMSProps } from "./types";
import enUs from "./translations/en-US.json";
import frFr from "./translations/fr-FR.json";
import { LangList } from "../../../utils/const";
import { messages } from "./translations/messages";
import "./styles.less";

const DEFAULT_LANG = enUs;

const enhance = withLanguageData({
  translations: {
    [LangList.EN_US]: enUs,
    [LangList.FR_FR]: { ...DEFAULT_LANG, ...frFr },
  },
});

const DMS: React.FC<DMSProps> = ({
  visible,
  onClose,
  onSelectButtonClick,
  fileType,
  multiple,
  ownerCompanyId,
  disabledFileIds,
  metaDataSearches,
  showFullToolTip = false,
  maxCount,
  fileList,
}) => {
  const { formatMessage } = useIntl();
  const { isModalOpen, onCloseModal } = useModalVisibility({
    defaultVisibility: visible,
    onClose,
  });

  // eslint-disable-next-line no-undef
  const infiniteScrollWrapper = useRef<HTMLDivElement>(null);

  const {
    dmsFiles,
    canLoadMore,
    getDMSFiles,
    selectedFiles,
    setSelectedFiles,
    setSearchText,
    totalCount,
  } = useDMS({
    isModalOpen,
    fileType,
    ownerCompanyId,
    infiniteScrollWrapper,
    metaDataSearches,
  });
  const { userDetailsDictionary } = useUserDetails({ dmsFiles });
  const { numberOfFilesSelected, handleSelectAction, onSelectFile } =
    useSelectedFiles({
      onCloseModal,
      onSelectButtonClick,
      selectedFiles,
      setSelectedFiles,
      multiple,
    });
  const { displayItems, options, onDisplayItemChange } = useDisplayItems();

  return (
    <Modal
      className="dms"
      title={formatMessage(messages.title)}
      size="l"
      visible={isModalOpen}
      secondaryButtonText={formatMessage(messages.cancelBtn)}
      onCancel={onCloseModal}
      onSecondaryButtonClick={onCloseModal}
      primaryButtonText={formatMessage(messages.selectBtn, {
        length: numberOfFilesSelected,
      })}
      onPrimaryButtonClick={handleSelectAction}
      primaryButtonProps={{
        disabled:
          !numberOfFilesSelected ||
          (!!maxCount &&
            maxCount < numberOfFilesSelected + (fileList?.length || 0)),
      }}
      headerChildren={
        <Row>
          <Col span={24}>
            <Search
              onSearch={setSearchText}
              placeholder={formatMessage(messages.searchPlaceholder)}
            />
          </Col>
        </Row>
      }
    >
      <>
        <div className="dms-content">
          <Heading size="xs">{formatMessage(messages.contentTitle)}</Heading>
          &nbsp;
          <Heading size="xxs">({totalCount})</Heading>
          <ToggleButtons
            className="dms-content-toggle-buttons"
            onChange={onDisplayItemChange}
            defaultValue="display-3-columns"
            options={options}
          />
        </div>

        <div
          id="scrollableInfiniteScrollWrapper"
          ref={infiniteScrollWrapper}
          className={"dms-infinite-scroll-wrapper"}
        >
          <InfiniteScroll
            className={classnames(
              "dms-display",
              `dms-${displayItems}`,
              "dms-infinite-scroll"
            )}
            dataLength={dmsFiles.length}
            hasMore={canLoadMore}
            loader={
              <div className="dms-infinite-scroll-loading-container">
                <Spinner size="large" />
              </div>
            }
            next={getDMSFiles}
            scrollableTarget="scrollableInfiniteScrollWrapper"
          >
            {dmsFiles.map(dmsFile => (
              <Item
                key={dmsFile.fileId}
                fileId={dmsFile.fileId}
                fileName={dmsFile.fileName}
                uploadedAt={dmsFile.uploadedAt}
                version={dmsFile.version}
                onSelectFile={onSelectFile(dmsFile)}
                userName={userDetailsDictionary[dmsFile.createdBy]}
                isSelected={selectedFiles.some(
                  selectedFile => selectedFile.fileId === dmsFile.fileId
                )}
                disabled={disabledFileIds?.includes(dmsFile.fileId)}
                showFullToolTip={showFullToolTip}
              />
            ))}
          </InfiniteScroll>
        </div>
      </>
    </Modal>
  );
};

export default enhance(DMS);
