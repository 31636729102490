export const getUploadColor = ({
  isDraggerDisabled,
  hasError,
  defaultColor = "primary",
}: {
  isDraggerDisabled?: boolean;
  hasError?: boolean;
  defaultColor?: string;
}) => {
  if (isDraggerDisabled) return "grey-3";

  if (hasError) return "red";

  return defaultColor;
};

export const prepareAllowedFileExtensions = (allowedFileExtensions: string[]) =>
  allowedFileExtensions?.map(fileExtenstion => `.${fileExtenstion}`);
