import cleanDeep from 'clean-deep'
import { DEFAULT_CLASS_NAME } from "../constants";
import { ErrorDetail, ErrorResponseList, ErrorWithCode } from "src/types";

export const isListEmpty = (list: any[] | Set<any>) =>
  !list ||
  (!Array.isArray(list) && !(list instanceof Set)) ||
  // @ts-ignore
  (!list.length && !list.size) ||
  (Array.isArray(list) && !list.filter(Boolean).length);

export const isObjectEmpty = (object: unknown) =>
  !object ||
  typeof object !== "object" ||
  isListEmpty(Object.values(object).filter(value => value !== undefined));

/*istanbul ignore next */
export const downloadFile = (
  data: BlobPart,
  filename: string,
  mime?: string,
  bom?: string
) => {
  const blobData = typeof bom !== "undefined" ? [bom, data] : [data];
  const blob = new Blob(blobData, {
    type: mime || "application/octet-stream",
  });
  // @ts-ignore
  if (typeof window.navigator.msSaveBlob !== "undefined") {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    // @ts-ignore
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const blobURL =
      window.URL && window.URL.createObjectURL
        ? window.URL.createObjectURL(blob)
        : window.webkitURL.createObjectURL(blob);

    const tempLink = document.createElement("a");
    tempLink.style.display = "none";
    tempLink.href = blobURL;
    tempLink.setAttribute("download", filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === "undefined") {
      tempLink.setAttribute("target", "_blank");
    }

    document.body.appendChild(tempLink);

    tempLink.click();

    // Fixes "webkit blob resource error 1"
    setTimeout(function () {
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(blobURL);
    }, 200);
  }
};

export const prepareArrayForJsonSchema = <T>(value: Array<T>) => {
  const isObjectType = value.every(item => typeof item === "object");

  // we need to send empty object if we want to add something in the array
  //  good = { packagingItems: [{}, {}] };
  //  bad = { packagingItems: [{ size: {}, weight: {} }] };

  if (isObjectType) {
    return value.map(item => {
      return cleanDeep(item, {
        emptyArrays: false,
      });
    });
  }

  return cleanDeep(value, {
    emptyArrays: false,
  });
};

export const setCleanValue = ({ key, value }: { key: string; value: any }) => {
  // we need to keep the value if it is "" because if the field is read only and it was
  // received as "" from dataAsJson and we remove it in the payload BE consider that as a change of this field
  if (!value && value !== false && value !== "") {
    return {};
  }
  if (Array.isArray(value)) {
    return {
      [key]: prepareArrayForJsonSchema(value),
    };
  }
  if (typeof value === "object") {
    const cleanValue = cleanDeep(value, {
      emptyArrays: false,
      emptyStrings: false,
    });
    if (isObjectEmpty(cleanValue)) {
      return {};
    }
    return {
      [key]: cleanValue,
    };
  }
  return { [key]: value };
};

export const getParsedData = (data?: string): { [key: string]: any } => {
  let result = {};

  try {
    result = JSON.parse(data);
  } catch (error) {
    // error should be managed
  }

  return result;
};

export const postToUrl = (
  url: string,
  params: any,
  options: { newTab?: string | boolean } = {}
) => {
  const form = document.createElement("form");
  form.method = "POST";
  form.action = url;

  if (options.newTab) {
    form.target = "_blank";
  }

  for (let i in params) {
    if (params.hasOwnProperty(i)) {
      const input = document.createElement("input");
      input.type = "hidden";
      input.name = i;
      input.value = params[i];
      form.appendChild(input);
    }
  }
  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
};

export const getPrefixClassName = (componentClassName?: string) =>
  componentClassName
    ? `${DEFAULT_CLASS_NAME}-${componentClassName}`
    : DEFAULT_CLASS_NAME;

export const isErrorWithCode = (
  errorObject: any
): errorObject is ErrorWithCode =>
  !isObjectEmpty(errorObject) &&
  "error" in errorObject &&
  "errorCode" in errorObject;

export const isErrorResponseList = (
  errorObject: any
): errorObject is ErrorResponseList =>
  !isObjectEmpty(errorObject) &&
  "errors" in errorObject &&
  Array.isArray(errorObject.errors);

export const isErrorDetail = (errorObject: any): errorObject is ErrorDetail =>
  !isObjectEmpty(errorObject) &&
  "code" in errorObject &&
  "message" in errorObject &&
  (typeof errorObject.detail === "undefined" ||
    typeof errorObject.detail === "string") &&
  (typeof errorObject.legacyCode === "undefined" ||
    typeof errorObject.legacyCode === "string") &&
  (typeof errorObject.property === "undefined" ||
    typeof errorObject.property === "string") &&
  (typeof errorObject.extensions === "undefined" ||
    typeof errorObject.extensions === "object");
