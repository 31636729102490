import { createContext } from "react";
import { UserApplication } from "src/models";
import { DEFAULT_CLASS_NAME } from "src/utils/constants";

const AppsListContext = createContext<{
  appList: UserApplication[];
  accessToken?: string;
  userId?: string;
  prefixClassName?: string;
}>({
  appList: [],
  accessToken: "",
  userId: "",
  prefixClassName: DEFAULT_CLASS_NAME,
});

export default AppsListContext;
