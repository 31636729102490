/* istanbul ignore file */

import { useEffect, useState } from "react";
import * as RLMD from "src/apis/rlmd";
import { ReferenceListItem } from "src/models";
import { REFERENCE_LIST_NAME } from "src/utils/constants";
import * as CUMD from "src/apis/cumd";

const useCountries = ({
  languageCode,
  userId,
}: {
  languageCode: string;
  userId: string;
}) => {
  const [countries, setCountries] = useState<ReferenceListItem[]>([]);

  const getCountries = async () => {
    try {
      const { data } = await RLMD.getCountries({
        languageCode,
        referenceListName: REFERENCE_LIST_NAME.COUNTRIES,
      });

      setCountries(
        data.reduce((previousState, { isItemActive, itemCode, text }) => {
          const countries = [...previousState];
          if (isItemActive) {
            countries.push({
              value: itemCode,
              name: text,
            });
          }
          return countries;
        }, [])
      );
    } catch (error) {
      // error should be managed
    }
  };

  const updateCountry = async (value: string) => {
    try {
      await CUMD.updateUserProfile({
        userId,
        newCountry: value,
      });
    } catch (error) {
      // error should be managed
    }
  };

  useEffect(() => {
    getCountries();
  }, []);

  return {
    countries,
    updateCountry,
  };
};

export default useCountries;
