import { LibraryObjectType } from "../types";
import {
  LIBRARY_CHAPTER_TYPE,
  LIBRARY_OBJECT_TYPES,
  MATERIAL_TYPES,
  PRODUCT_TYPES,
  RECIPE_TYPES,
  REFLIST_IDS,
} from "../constants";

export const isLibraryObjectChaptersType = (
  type: LibraryObjectType
): boolean => {
  const chapterTypes: LibraryObjectType[] = [
    LIBRARY_OBJECT_TYPES.CHAPTERS,
    LIBRARY_OBJECT_TYPES.FINISHED_PRODUCTS,
    LIBRARY_OBJECT_TYPES.PRODUCTION,
    LIBRARY_OBJECT_TYPES.TESTING,
  ];

  return chapterTypes.includes(type);
};

export const isLibraryObjectPackagingSystemsType = (type: string): boolean =>
  type === LIBRARY_OBJECT_TYPES.PACKAGING_SYSTEMS;

export const isLibraryObjectTemplatesType = (type: string) =>
  type === LIBRARY_OBJECT_TYPES.TEMPLATES ||
  isProductTypeFAndV(type) ||
  isProductTypeFood(type);

export const isLibraryObjectRequirementType = (type: string) =>
  [
    "requirement",
    LIBRARY_OBJECT_TYPES.REQUIREMENTS,
    LIBRARY_OBJECT_TYPES.TEXT_REQUIREMENTS,
    LIBRARY_OBJECT_TYPES.DOCUMENT_REQUIREMENTS,
  ].includes(type);

export const isLibraryObjectMaterialType = (materialType: string) =>
  isMaterialRawMaterialType(materialType) ||
  isMaterialCompositeType(materialType) ||
  isMaterialMaterialsType(materialType) ||
  isMaterialAdditiveType(materialType) ||
  isMaterialProcessingAidType(materialType);

export const isMaterialRawMaterialType = (materialType: string) =>
  materialType === MATERIAL_TYPES.RAW_MATERIAL;

export const isMaterialCompositeType = (materialType: string) =>
  materialType === MATERIAL_TYPES.COMPOSITE ||
  isMaterialCompositeBoughtToThirdParty(materialType) ||
  isMaterialCompositeMadeInHouse(materialType);

export const isMaterialAdditiveType = (materialType: string) =>
  materialType === MATERIAL_TYPES.ADDITIVE;

export const isMaterialProcessingAidType = (materialType: string) =>
  materialType === MATERIAL_TYPES.PROCESSING_AID;

export const isMaterialMaterialsType = (materialType: string) =>
  materialType === LIBRARY_OBJECT_TYPES.MATERIALS;

export const isMaterialCompositeBoughtToThirdParty = (recipeType: string) =>
  recipeType === RECIPE_TYPES.BOUGHT_TO_THIRD_PARTY;

export const isMaterialCompositeMadeInHouse = (recipeType: string) =>
  recipeType === RECIPE_TYPES.MADE_IN_HOUSE;

export const isLibraryObjectTesting = (type: string) =>
  type === LIBRARY_CHAPTER_TYPE.TESTING;

export const isLibraryObjectProduction = (type: string) =>
  type === LIBRARY_CHAPTER_TYPE.PRODUCTION;

export const isLibraryObjectFinishedProduct = (type: string) =>
  type === LIBRARY_CHAPTER_TYPE.FINISHED_PRODUCT;

export const isProductTypeFood = (type: string) =>
  type === REFLIST_IDS.FOOD_PRODUCT_TYPE || type === PRODUCT_TYPES.FOOD;

export const isProductTypeFAndV = (type: string) =>
  type === REFLIST_IDS.F_AND_V_PRODUCT_TYPE || type === PRODUCT_TYPES.F_AND_V;
