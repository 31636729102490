import { MutableRefObject } from "react";
import { NavItem } from "./types";
import { isListEmpty } from "src/utils/general";

export const getOnOutsideMenuClick =
  ({
    menuRef,
    burgerIconRef,
    setMenuVisible,
  }: {
    // eslint-disable-next-line no-undef
    menuRef: MutableRefObject<HTMLElement>;
    // eslint-disable-next-line no-undef
    burgerIconRef: MutableRefObject<HTMLDivElement>;
    setMenuVisible: Function;
  }) =>
  // eslint-disable-next-line no-undef
  (e: React.MouseEvent<HTMLElement>) => {
    if (
      // @ts-ignore
      itemContainsTarget({ item: menuRef, target: e.target }) ||
      // @ts-ignore
      itemContainsTarget({ item: burgerIconRef, target: e.target })
    ) {
      return;
    }

    setMenuVisible(false);
  };

export const getOnMenuToggle =
  ({ setMenuVisible }: { setMenuVisible: Function }) =>
  () => {
    setMenuVisible(prev => !prev);
  };

export const hasSubNav = (subNav: NavItem[]) => {
  return !isListEmpty(subNav);
};

const itemContainsTarget = ({
  item,
  target,
}: {
  // eslint-disable-next-line no-undef
  item: MutableRefObject<HTMLElement>;
  // eslint-disable-next-line no-undef
  target: HTMLElement;
}) => item.current && item.current.contains(target);

export const isMainNavActive =
  ({
    pathname,
    path,
    subNav,
  }: {
    pathname: string;
    path: NavItem["path"];
    subNav: NavItem[];
  }) =>
  () => {
    if (subNav && subNav.length) {
      return subNav.some(({ path: subPath }) => pathname.includes(subPath));
    }

    return pathname.includes(path);
  };

export const getMainNavPathWithSubNav = (queryParams?: string) => {
  // if we have query params we are keeping them
  if (queryParams) {
    return queryParams;
  }
  return "#";
};
