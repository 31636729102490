import React from "react";
import classNames from "classnames";
import { Tooltip } from "@trace-one/design-system";
import "../../../styles.less";
import { TabWithOutBadgeProps } from "src/components/NotificationsPanel/types";

const TabWithOutBadge: React.FC<TabWithOutBadgeProps> = ({
  label,
  senderZone = "All",
  isSelected,
  onSelect,
  appName,
}) => {
  const lowerCaseSenderZone = senderZone.toLowerCase().split(" ").join("");

  const handleClick = () => {
    if (!isSelected) {
      onSelect(senderZone);
    }
  };

  return (
    <button
      data-test-id={`notifications-panel-tab-${appName
        .split(" ")
        .join("-")
        .toLowerCase()}`}
      onClick={handleClick}
      className={classNames(
        "bsc-notifications-tabs-tab",
        `bsc-notifications-tabs-tab-${lowerCaseSenderZone}`,
        {
          selected: isSelected,
        }
      )}
    >
      <Tooltip
        showFullText={true}
        text={appName.toLowerCase() === "all" ? label : appName}
        placement="left"
        size="small"
      >
        {appName.toLowerCase() === "all" ? "ALL" : label}
      </Tooltip>
    </button>
  );
};

export default TabWithOutBadge;
