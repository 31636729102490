import React from "react";
import {
  Heading,
  DateRangePicker,
  Radio,
  Checkbox,
} from "@trace-one/design-system";
import { useIntl } from "react-intl";
import { notificationsFilterMessage } from "../../translations/defineMessage";
import FiltersToolbar from "./FiltersToolbar";
import FiltersFooter from "./FiltersFooter";
import { NotificationFilterProps } from "../../types";
import useNotificationsFilterControls from "../../hooks/useNotificationsFilterControls";
import { NotificationPreference } from "src/models/notification";
import { localeData } from "moment";

const NotficationFilters: React.FC<NotificationFilterProps> = ({
  toggleFilters,
  setFilters,
  notificationTypes,
  filters,
  selectedTab,
}) => {
  const format = localeData().longDateFormat("L");

  const { formatMessage } = useIntl();
  const {
    handleReadUnReadFilterChange,
    handleDateSelection,
    handleNotificationTypeSelection,
    filterControlValues,
    applyFilters,
  } = useNotificationsFilterControls(filters, setFilters, notificationTypes);

  const handleApplyFilters = () => {
    applyFilters();
    toggleFilters();
  };

  const isAllNotificationsSelected = (
    notificationTypes: NotificationPreference[],
    selectedNotificationTypeIds: string[]
  ) => {
    const selectedNotificationTypeIdsSet = new Set(selectedNotificationTypeIds);
    if (
      notificationTypes.every(notifType =>
        selectedNotificationTypeIdsSet.has(notifType.notificationTypeId)
      )
    ) {
      return ["all"];
    }

    return [];
  };

  return (
    <>
      <FiltersToolbar toggleFilters={toggleFilters} />

      <div
        className="notifications-panel-filters-wrapper"
        data-test-id="notification-panel-filters"
      >
        <section className="filter-sections readunread-filter-section">
          <Heading size="xxs" className="nt-filter-title">
            {formatMessage(notificationsFilterMessage.readUnreadFilterTitle)}
          </Heading>
          <Radio.Group
            direction="vertical"
            defaultValue={filterControlValues.notificationStatus}
            onChange={handleReadUnReadFilterChange}
            options={[
              {
                label: formatMessage(notificationsFilterMessage.optionAll),
                value: "all",
                "data-test-id": "notifications-panel-radio-all",
              },
              {
                label: formatMessage(
                  notificationsFilterMessage.optionUnreadOnly
                ),
                value: "unread",
                "data-test-id": "notifications-panel-radio-unread",
              },
              {
                label: formatMessage(notificationsFilterMessage.optionReadOnly),
                value: "read",
                "data-test-id": "notifications-panel-radio-read",
              },
            ]}
          />
        </section>

        <section className="filter-sections date-filter-section">
          <Heading size="xxs" className="nt-filter-title">
            {formatMessage(notificationsFilterMessage.dateFilterTitle)}
          </Heading>

          <DateRangePicker
            format={format}
            allowEmpty={[true, true]}
            className="notification-panel-filter-date-picker"
            defaultValue={filterControlValues.dateRange}
            onChange={handleDateSelection}
            placeholder={[format.toLowerCase(), format.toLowerCase()]}
          />
        </section>

        {notificationTypes?.length > 0 && selectedTab !== "All" && (
          <section className="filter-sections nt-type-filter-section">
            <Heading size="xxs" className="nt-filter-title">
              {formatMessage(
                notificationsFilterMessage.notificationsTypeFilterTitle
              )}
            </Heading>
            <div className="notiffication-type-controls">
              {/* All selection option */}
              <Checkbox.Group
                value={isAllNotificationsSelected(
                  notificationTypes,
                  filterControlValues.selectedNotificationTypes
                )}
                onChange={handleNotificationTypeSelection}
                direction="vertical"
                options={[
                  {
                    label: formatMessage(notificationsFilterMessage.optionAll),
                    value: "all",
                    "data-test-id": "notifications-panel-checkbox-all",
                  },
                ]}
              />

              <Checkbox.Group
                value={filterControlValues.selectedNotificationTypes}
                onChange={handleNotificationTypeSelection}
                direction="vertical"
                options={notificationTypes.map(notificationType => ({
                  label: notificationType.notificationTypeTitle,
                  value: notificationType.notificationTypeId,
                  "data-test-id": `notifications-panel-checkbox-${notificationType.notificationTypeId}`,
                }))}
              />
            </div>
          </section>
        )}
      </div>

      <FiltersFooter
        onApplyFilters={handleApplyFilters}
        toggleFilters={toggleFilters}
      />
    </>
  );
};

export default NotficationFilters;
