import React, { useContext } from "react";
import { Paragraph, Tooltip } from "@trace-one/design-system";
import { FormattedMessage } from "react-intl";
import { APPLICATIONS } from "src/utils/constants";
import AppIcon from "./components/AppIcon";
import { AppsListContext } from "../../contexts";
import { handleAppClickFactory } from "./utils";
import { AppItemProps } from "./types";
import { AppSwitcherVariant } from "../../types";

const AppItem: React.FC<AppItemProps> = ({
  appItem,
  sendEnteredAppEvent,
  handleCreationPopUp,
  variant,
  collapsed,
}) => {
  const {
    applicationTypeId,
    stsEndpointUrl,
    getEndpointUrl,
    applicationOwnerId,
    applicationName,
  } = appItem;

  const { accessToken, prefixClassName } = useContext(AppsListContext);
  const graphicName = APPLICATIONS.find(
    app => app.id === applicationTypeId
  )?.graphic;
  const shouldShowTooltip = variant === "vertical" && collapsed;

  return (
    <>
      {shouldShowTooltip
        ? (
        <Tooltip
          title={<FormattedMessage id={`app.${applicationTypeId}`} />}
          placement="right"
          size="small"
        >
          <div
            data-test-id={`${prefixClassName}-link-${graphicName}`}
            onClick={handleAppClickFactory({
              applicationTypeId,
              getEndpointUrl,
              stsEndpointUrl,
              applicationOwnerId,
              applicationName,
              sendEnteredAppEvent,
              accessToken,
              handleCreationPopUp,
            })}
            className={`${prefixClassName}-list-item`}
          >
            <AppIcon
              graphicName={graphicName}
              size={
                variant === AppSwitcherVariant.VERTICAL ? "medium-large" : null
              }
            />
            <Paragraph
              size="xs"
              color="grey-5"
              className={`${prefixClassName}-list-item-app-name`}
            >
              <FormattedMessage id={`app.${applicationTypeId}`} />
            </Paragraph>
          </div>
        </Tooltip>
          )
        : (
        <div
          data-test-id={`${prefixClassName}-link-${graphicName}`}
          onClick={handleAppClickFactory({
            applicationTypeId,
            getEndpointUrl,
            stsEndpointUrl,
            applicationOwnerId,
            applicationName,
            sendEnteredAppEvent,
            accessToken,
            handleCreationPopUp,
          })}
          className={`${prefixClassName}-list-item`}
        >
          <AppIcon
            graphicName={graphicName}
            size={
              variant === AppSwitcherVariant.VERTICAL ? "medium-large" : null
            }
          />
          <Paragraph
            size="xs"
            color="grey-5"
            className={`${prefixClassName}-list-item-app-name`}
          >
            <FormattedMessage id={`app.${applicationTypeId}`} />
          </Paragraph>
        </div>
          )}
    </>
  );
};

export default AppItem;
