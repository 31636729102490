import React from "react";
import { Col, Form, Row } from "antd";
import { Input, Select } from "@trace-one/design-system";
import { getParsedData, isListEmpty } from "src/utils/general";
import FormSchema from "src/components/FormSchema";
import { useFormSchemaTranslation } from "src/hooks/useFormSchemaTranslation";
import { useDebouncedActions } from "src/hooks/useDebouncedActions";
import { getUsageLevelOptions } from "./utils";
import { PackagingSystemComponentObjectData } from "src/types";
import { BasicInformationProps } from "./types";

const BasicInformation: React.FC<BasicInformationProps> = ({
  sections,
  isEditable,
  refListDictionary,
  initialValues,
  onChangeName,
  onChangeUsageLevel,
  onSaveTemplateBlock,
  widgets,
  FieldTemplate,
  ObjectFieldTemplate,
  ArrayFieldTemplate,
}) => {
  const [form] = Form.useForm();
  const { getTranslationForKey } = useFormSchemaTranslation();

  const handleChangeName = () =>
    onChangeName({ value: currentValue as string });

  const { currentValue, onTextChange } = useDebouncedActions({
    defaultValue: initialValues["packaging-component-name"],
    onChange: handleChangeName,
    debounceTimer: 500,
  });

  const handleChangeUsageLevel = (
    value: PackagingSystemComponentObjectData["level"]
  ) => {
    onChangeUsageLevel({ value });
  };

  const usageLevelOptions = getUsageLevelOptions([
    "Undefined",
    "Primary",
    "Secondary",
    "Tertiary",
  ]);

  const colSpans = {
    labelCol: { xs: 6 },
    wrapperCol: { xs: 10 },
  };

  if (isListEmpty(sections)) {
    return null;
  }

  return (
    <>
      {sections.map(({ sectionBlocks }) => {
        return sectionBlocks.map(
          ({ dataAsJson, jsonSchema, uiSchema, templateBlockId }) => {
            const formData = getParsedData(dataAsJson);

            return (
              <React.Fragment key={templateBlockId}>
                <Form
                  name="basicInformationForm"
                  initialValues={initialValues}
                  form={form}
                  {...colSpans}
                >
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        rules={[{ max: 250, whitespace: true }]}
                        name="packaging-component-name"
                        label={getTranslationForKey(
                          "packagingComponentNameLabel"
                        )}
                        labelAlign="left"
                      >
                        <Input
                          allowClear={false}
                          data-test-id="packaging-component-name"
                          value={currentValue}
                          onChange={onTextChange}
                          disabled={!isEditable}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="packaging-component-usage-level"
                        label={getTranslationForKey(
                          "packagingComponentUsageLevelLabel"
                        )}
                        labelAlign="left"
                      >
                        <Select
                          allowClear={false}
                          data-test-id="packaging-component-usage-level"
                          options={usageLevelOptions}
                          placeholder={getTranslationForKey(
                            "refListPlaceholder"
                          )}
                          onChange={handleChangeUsageLevel}
                          disabled={!isEditable}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>

                <FormSchema
                  key={templateBlockId}
                  uiSchema={getParsedData(uiSchema)}
                  schema={getParsedData(jsonSchema)}
                  formData={formData}
                  formContext={{
                    type: "packaging",
                    refListDictionary,
                    displayActions: true,
                    labelAlign: "left",
                    formData,
                    shouldCallApi: false,
                    isWidgetEditable: () => isEditable,
                    saveTemplateBlock: onSaveTemplateBlock(templateBlockId),
                  }}
                  widgets={widgets}
                  FieldTemplate={FieldTemplate}
                  ObjectFieldTemplate={ObjectFieldTemplate}
                  ArrayFieldTemplate={ArrayFieldTemplate}
                />
              </React.Fragment>
            );
          }
        );
      })}
    </>
  );
};

export default BasicInformation;
