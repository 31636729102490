import React from "react";
import { Select } from "@trace-one/design-system";
import useDropdown from "./hooks/useDropdown";
import { CustomDropdownProps } from "../../../types";

export interface DropdownProps extends CustomDropdownProps {
  fileId: string;
}

const Dropdown: React.FC<DropdownProps> = ({
  fileId,
  onChange: onChangeCallback,
  defaultValue,
  options,
  value: selectedValue,
  ...rest
}) => {
  const { onChange, value } = useDropdown({
    onChangeCallback,
    defaultValue,
    selectedValue,
    options,
  });

  return (
    <Select
      value={value}
      options={options}
      onChange={onChange(fileId)}
      {...rest}
    />
  );
};

export default Dropdown;
