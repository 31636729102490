import React from "react";
import { Heading, Button } from "@trace-one/design-system";
import { FullPageModalProps } from "../../types";
import { getPrefixClassName } from "src/utils/general";
import classNames from "classnames";

const prefixCls = getPrefixClassName("modal-product-select");

const FullPageModal: React.FC<FullPageModalProps> = ({
  title,
  open = false,
  onClose = () => {},
  children = null,
  className,
}) => {
  if (!open) {
    return null;
  }

  return (
    <div
      className={classNames(
        prefixCls,
        className,
        `${prefixCls}--fullpage-modal`
      )}
      data-test-id="bs-modal-product-select-modal"
    >
      <div className="ds-d-flex ds-justify-content-between">
        <Heading size="s" data-test-id="bs-modal-product-select-modal-title">
          {title}
        </Heading>

        <Button
          data-test-id="bs-modal-product-select-modal-close-btn"
          onClick={onClose}
          color="secondary"
          type="tertiary"
          iconName="close"
        />
      </div>
      {children}
    </div>
  );
};

export default FullPageModal;
