import React, { useRef } from "react";
import { Modal, Search, Paragraph } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import InfiniteScrollLibraryContent from "../InfiniteScrollLibraryContent";
import useSelectedLibraryObject from "../../hooks/useSelectedLibraryObject";
import useAddPackagingComponents from "../../hooks/useAddPackagingComponents";
import { createPackagingMessage } from "../../translations/messages";
import "./styles.less";

export interface ComponentObject {
  componentId: string;
  componentTypeId: string | null;
  name: string;
}
export interface AddPackagingComponentsModalProps {
  onCloseModal: Function;
  onSelect: Function;
  ownerCompanyId: string;
  components: Array<ComponentObject>;
  languageCode: string;
  multiple?: boolean;
}

const ExtistingPackagingComponentsModal: React.FC<
  AddPackagingComponentsModalProps
> = ({
  onCloseModal,
  ownerCompanyId,
  languageCode,
  components,
  onSelect,
  multiple = true,
}) => {
  const { formatMessage } = useIntl();
  // eslint-disable-next-line no-undef
  const infiniteScrollWrapper = useRef<HTMLDivElement>(null);
  const existingLibraryObjectIds = components.map(obj => obj.componentId);

  const {
    selectedLibraryObjects: selectedPackagingComponents,
    setSelectedLibraryObjects: setSelectedPackagingComponents,
    onSelectLibraryObject: onSelectPackagingComponent,
    selectedLibraryObjectsIds: selectedPackagingComponentsIds,
  } = useSelectedLibraryObject({
    existingLibraryObjectIds,
    multiple,
  });

  const {
    packagingComponentsList,
    canLoadMore,
    totalNumberOfItems,
    searchPackagingComponents,
    onCloseModalHandler,
    isPrimaryButtonDisabled,
    searchText,
    setSearchText,
    handleSelectAction,
  } = useAddPackagingComponents({
    selectedPackagingComponents,
    setSelectedPackagingComponents,
    onCloseModal,
    ownerCompanyId,
    languageCode,
    infiniteScrollWrapper,
    onSelect,
  });

  return (
    <Modal
      open
      size="l"
      className="bc-existing-packaging-component-modal"
      onCancel={onCloseModalHandler}
      title={formatMessage(createPackagingMessage.modalTitle)}
      secondaryButtonText={formatMessage(createPackagingMessage.cancel)}
      onSecondaryButtonClick={onCloseModalHandler}
      primaryButtonText={formatMessage(createPackagingMessage.select, {
        length: selectedPackagingComponents?.length || null,
      })}
      onPrimaryButtonClick={handleSelectAction}
      primaryButtonProps={{
        disabled: isPrimaryButtonDisabled,
      }}
      headerChildren={
        <>
          <Paragraph>
            {formatMessage(createPackagingMessage.modalSubTitle)}
          </Paragraph>
          <Search
            className={"searchBar"}
            onSearch={setSearchText}
            placeholder={formatMessage(
              createPackagingMessage.searchPlaceholder
            )}
          />
        </>
      }
    >
      <InfiniteScrollLibraryContent
        title={formatMessage(createPackagingMessage.modalBodyTitle)}
        totalNumberOfItems={totalNumberOfItems}
        canLoadMore={canLoadMore}
        libraryObjectList={packagingComponentsList}
        nextFetchCallback={() => searchPackagingComponents({ searchText })}
        onSelectLibraryItem={onSelectPackagingComponent}
        existingLibraryObjectIds={existingLibraryObjectIds}
        selectedLibraryObjectIds={selectedPackagingComponentsIds}
        infiniteScrollWrapper={infiniteScrollWrapper}
      />
    </Modal>
  );
};

export default ExtistingPackagingComponentsModal;
