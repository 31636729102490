import { defineMessages } from "react-intl";

export const messages = defineMessages({
  generalMessage: {
    id: "business.components.apis.errors.general.message",
    defaultMessage: "An error has occurred",
  },
  generalDescription: {
    id: "business.components.apis.errors.general.description",
    defaultMessage:
      "An unidentified error has occurred, please contact try again later or contact our support team if the error persists.",
  },

  "400Message": {
    id: "business.components.apis.errors.400.message",
    defaultMessage: "Bad Request (400)",
  },
  "400Description": {
    id: "business.components.apis.errors.400.description",
    defaultMessage:
      "The server encountered an error due to an incorrect request, please try again later or contact our support team if the error persists.",
  },

  "401Message": {
    id: "business.components.apis.errors.401.message",
    defaultMessage: "Not Authorized (401)",
  },
  "401Description": {
    id: "business.components.apis.errors.401.description",
    defaultMessage:
      "It seems like you do not have the necessary rights to perform this action, please contact your administrator for more information.",
  },

  "403Message": {
    id: "business.components.apis.errors.403.message",
    defaultMessage: "Forbidden (403)",
  },
  "403Description": {
    id: "business.components.apis.errors.403.description",
    defaultMessage:
      "It seems like you do not have the necessary rights to access this functionality, please contact your administrator for more information.",
  },

  "404Message": {
    id: "business.components.apis.errors.404.message",
    defaultMessage: "Page not Found (404)",
  },
  "404Description": {
    id: "business.components.apis.errors.404.description",
    defaultMessage:
      "This page or service was not found or has been moved, please contact our support team for more information.",
  },

  "500Message": {
    id: "business.components.apis.errors.500.message",
    defaultMessage: "Internal server error (500)",
  },
  "500Description": {
    id: "business.components.apis.errors.500.description",
    defaultMessage:
      "The server encountered an internal error, it's not you, it's us ! Please contact our support team if the error persists.",
  },

  "503Message": {
    id: "business.components.apis.errors.503.message",
    defaultMessage: "Service Unavailable",
  },
  "503Description": {
    id: "business.components.apis.errors.503.description",
    defaultMessage:
      "The service is unavailable, please try again later or contact our support team if the error persists.",
  },
});
