import React from "react";
import "../../styles.less";

import frFr from "../../translations/fr-FR.json";
import enUs from "../../translations/en-US.json";
import deDe from "../../translations/de-DE.json";
import esEs from "../../translations/es-ES.json";
import ptPt from "../../translations/pt-PT.json";
import itIt from "../../translations/it-IT.json";
import nlBe from "../../translations/nl-BE.json";

import { LangList } from "../../../../../utils/const";
import NotificationsSidebar from "../Sidebar";
import { withLanguageData } from "src/hocs";
import { NotificationPanelModalProps } from "../../types";

const DEFAULT_LANG = enUs;
const enhance = withLanguageData({
  translations: {
    [LangList.EN_US]: enUs,
    [LangList.FR_FR]: { ...DEFAULT_LANG, ...frFr },
    [LangList.DE_DE]: { ...DEFAULT_LANG, ...deDe },
    [LangList.ES_ES]: { ...DEFAULT_LANG, ...esEs },
    [LangList.PT_PT]: { ...DEFAULT_LANG, ...ptPt },
    [LangList.IT_IT]: { ...DEFAULT_LANG, ...itIt },
    [LangList.NL_BE]: { ...DEFAULT_LANG, ...nlBe },
  },
});

export const NotificationPanelModal: React.FC<NotificationPanelModalProps> =
  enhance(
    ({
      isOpen,
      setIsOpen,
      unreadNotifications,
      pathNotificationsSettings,
      countsBySenderZones,
      refreshCount,
      digestURLState,
    }) => {
      const onOverlayClick = () => {
        setIsOpen(false);
      };

      return (
        <div
          onClick={onOverlayClick}
          data-test-id="notifications-overlay"
          className={`notifications-overlay notifications-overlay-${
            isOpen ? "open" : "close"
          }`}
        >
          <NotificationsSidebar
            isOpen={isOpen}
            refreshCount={refreshCount}
            unreadNotifications={unreadNotifications}
            pathNotificationsSettings={pathNotificationsSettings}
            countsBySenderZones={countsBySenderZones}
            digestURLState={digestURLState}
          />
        </div>
      );
    }
  );

export default NotificationPanelModal;
