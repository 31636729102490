const useRedirects = () => {
  const redirectToMyProfile = () => {
    // @ts-ignore
    const url = `${window.env.TON_URL}/user-profile`;
    window.open(url, "_blank");
  };

  const redirectToMySettings = () => {
    // @ts-ignore
    const url = `${window.env.TON_URL}/user-profile/my-settings`;
    window.open(url, "_blank");
  };

  const redirectToNotificationSettings = () => {
    // @ts-ignore
    const url = `${window.env.TON_URL}/user-profile/notification-settings`;
    window.open(url, "_blank");
  };

  const redirectToAdminSettings = () => {
    // @ts-ignore
    const url = `${window.env.TON_URL}/company-parameters`;
    window.open(url, "_blank");
  };

  const redirectToCompanyProfile = () => {
    // @ts-ignore
    const url = `${window.env.TON_URL}/company`;
    window.open(url, "_blank");
  };

  const redirectToChangePassword = () => {
    // @ts-ignore
    const url = `${window.env.IDSV_URL}/user/setpassword?ReturnURL=${window.env.IDSV_URL}`;
    window.open(url, "_blank");
  };

  const redirectToPrivacy = () => {
    // @ts-ignore
    const url = `${window.env.TON_URL}/user-parameters`;
    window.open(url, "_blank");
  };

  const redirectToMyBookmarks = () => {
    // @ts-ignore
    const url = `${window.env.TON_URL}/bookmarks`;
    window.open(url, "_blank");
  };

  const redirectToUnmasquerade = () => {
    // @ts-ignore
    const url = `${window.env.UNIMPERSONATE_URL}`;

    window.open(url, "_self");
  };

  return {
    redirectToMyProfile,
    redirectToMySettings,
    redirectToNotificationSettings,
    redirectToAdminSettings,
    redirectToCompanyProfile,
    redirectToChangePassword,
    redirectToPrivacy,
    redirectToMyBookmarks,
    redirectToUnmasquerade,
  };
};

export default useRedirects;
