import React from "react";
import classnames from "classnames";
import { NavLink, useLocation } from "react-router-dom";
import { Icon, Paragraph } from "@trace-one/design-system";
import { getPrefixClassName } from "src/utils/general";
import { HeaderLinkProps } from "./types";
import {
  getMainNavPathWithSubNav,
  hasSubNav,
  isMainNavActive,
} from "../../utils";

const HeaderLink: React.FC<HeaderLinkProps> = ({
  index,
  getOnMainNavClick,
  text,
  path,
  subNav,
  isSelected,
  ...rest
}) => {
  const { pathname, search } = useLocation();
  const prefixCls = getPrefixClassName("header");
  const linkHasSubNav = hasSubNav(subNav);

  return (
    <li
      className={classnames({ isSelected })}
      data-test-id="nav-list-item"
    >
      <NavLink
        to={linkHasSubNav ? getMainNavPathWithSubNav(search) : path}
        isActive={isMainNavActive({ pathname, path, subNav })}
        activeClassName={`${prefixCls}-active-nav`}
        onClick={linkHasSubNav ? getOnMainNavClick({ index }) : null}
        {...rest}
      >
        <Paragraph>{text}</Paragraph>
        {linkHasSubNav && (
          <Icon name="arrow-down" color="primary" size="small" />
        )}
      </NavLink>
      {linkHasSubNav && (
        <div className={`${prefixCls}-dropdown`}>
          <ul>
            {subNav.map(
              ({ text: subText, path: subPath, ...restSubNavLink }) => {
                return (
                  <li key={subPath}>
                    <NavLink to={{ pathname: subPath }} {...restSubNavLink}>
                      <Paragraph>{subText}</Paragraph>
                    </NavLink>
                  </li>
                );
              }
            )}
          </ul>
        </div>
      )}
    </li>
  );
};

export default HeaderLink;
