import { defineMessages } from "react-intl";

export const quickLinksMessages = defineMessages({
  myProfile: {
    id: "businessComponents.userProfilePage.myProfile.title",
    defaultMessage: "My Profile",
  },
  mySettings: {
    id: "businessComponents.userProfilePage.mySettings.title",
    defaultMessage: "MySettings",
  },
  languages: {
    id: "businessComponents.userProfilePage.languages.title",
    defaultMessage: "Languages",
  },
  notificationSettings: {
    id: "businessComponents.userProfilePage.notificationSettings.title",
    defaultMessage: "Notification Settings",
  },
  administrationSettings: {
    id: "businessComponents.userProfilePage.administrationSettings.title",
    defaultMessage: "Administration settings",
  },
  companyProfile: {
    id: "businessComponents.userProfilePage.companyProfile.title",
    defaultMessage: "Company profile",
  },
  changePassword: {
    id: "businessComponents.userProfilePage.changePassword.title",
    defaultMessage: "Change password",
  },
  privacy: {
    id: "businessComponents.userProfilePage.privacy.title",
    defaultMessage: "Privacy",
  },
  logOutButton: {
    id: "businessComponents.userProfilePage.logOut.title",
    defaultMessage: "Log out",
  },
  unmasquerade: {
    id: "businessComponents.userProfilePage.unmasquerade.title",
    defaultMessage: "Unmasquerade",
  },
  bookmarks: {
    id: "businessComponents.userProfilePage.bookmarks.title",
    defaultMessage: "My bookmarks",
  },
  toastMasqueradeActivated: {
    id: "businessComponents.userProfilePage.toast.masquerade.activated",
    defaultMessage: "Masquerade activated",
  },
  toastMasqueradeActivatedDescription: {
    id: "businessComponents.userProfilePage.toast.masquerade.activated.description",
    defaultMessage: "You are now in masquerade mode and logged as {userName}",
  },
});
