import React, { memo } from "react";
import { Typography } from "@trace-one/design-system";
import { NotificationActionItemProps } from "../../types";

const NotificationActionItem: React.FC<NotificationActionItemProps> = ({
  title,
  description,
}) => {
  return (
    <div>
      <Typography variant="heading-xxs" component="div">
        {title}
      </Typography>
      <Typography variant="badge-m" component="div">
        {description}
      </Typography>
    </div>
  );
};

export default memo(NotificationActionItem);
