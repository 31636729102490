import React from "react";
import { toaster } from "@trace-one/design-system";
import { RequestApps, notificationType } from "../../types";
import messages from "../../../../translations/messages";
import { useIntl } from "react-intl";
import {
  getAppAccessNotifStatus,
  isTypeConfirmation,
} from "src/components/AskAccessModal/utils/utils";

const useForm = ({
  userId,
  langcode,
  onClose,
  checkedApps,
  setCheckedApps,
}: {
  userId: string;
  langcode: string;
  onClose: CallableFunction;
  checkedApps: string[];
  setCheckedApps: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const { formatMessage } = useIntl();

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedApps(prevState => {
      let updatedList = [...prevState];
      if (event.target.checked) {
        updatedList = [...updatedList, event.target.name];
      } else {
        updatedList.splice(updatedList.indexOf(event.target.name), 1);
      }
      return updatedList;
    });
  };

  const requestAppsValidation = () => {
    if (checkedApps.length) {
      return Promise.resolve();
    }
    return Promise.reject(formatMessage(messages.requestAppsError));
  };

  const showNotification = (type: notificationType) => {
    toaster.open({
      message: formatMessage(
        isTypeConfirmation(type)
          ? messages.notificationTitleConfirmation
          : messages.notificationTitleAlert
      ),
      description: formatMessage(
        isTypeConfirmation(type)
          ? messages.notificationSubtitleConfirmation
          : messages.notificationSubtitleAlert
      ),
      type: type,
    });
  };

  const requestMessageValidation = (
    _,
    value: string,
    callback: CallableFunction
  ) => {
    if (value && value.trim() === "") {
      callback(formatMessage(messages.requestMessageMandatoryError));
    } else {
      callback();
    }
  };

  const onFinish = async (values: RequestApps) => {
    try {
      const result = await getAppAccessNotifStatus(values, userId, langcode);
      result ? showNotification("confirmation") : showNotification("alert");
      onClose();
    } catch (error) {
      showNotification("alert");
    }
  };

  return {
    checkedApps,
    handleCheck,
    requestAppsValidation,
    requestMessageValidation,
    onFinish,
  };
};

export default useForm;
