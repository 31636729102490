import React from "react";
import { Popover } from "antd";
import { Button } from "@trace-one/design-system";
import { getPrefixClassName, isObjectEmpty } from "src/utils/general";
import HelperButton from "../HelperButton";
import Notifications from "./components/Notifications";
import { AppSwitcher, UserMenu } from "IndexComponents";
import { RightMenuProps } from "./types";

const RightMenu: React.FC<RightMenuProps> = ({
  messageIcon,
  appSwitcherProps,
  onLogout,
  buttonProps,
  helpLinks,
  userId,
  isTon,
  isNameShown,
  isMasquerade,
}) => {
  const prefixCls = getPrefixClassName("header");

  return (
    <div
      data-test-id="right-menu"
      className={`${prefixCls}-right-menu-wrapper`}
    >
      {!isObjectEmpty(buttonProps) && (
        <Button data-test-id="header-button" {...buttonProps}>
          {/* @ts-ignore */}
          {buttonProps.text || buttonProps.children}
        </Button>
      )}
      <ul className={`${prefixCls}-right-menu`}>
        <Notifications />
        {messageIcon
          ? (
          <li key="messageIcon" data-test-id="header-message-icon">
            {messageIcon}
          </li>
            )
          : null}
        <li key="userMenu">
          <UserMenu
            userId={userId}
            onLogout={onLogout}
            isTon={isTon}
            isMasquerade={isMasquerade}
            isNameShown={isNameShown}
          />
        </li>
        {!isObjectEmpty(helpLinks) && <HelperButton helpLinks={helpLinks} />}
      </ul>
      {!isObjectEmpty(appSwitcherProps) && (
        <div
          data-test-id="app-switcher"
          className={`${prefixCls}-app-switcher`}
        >
          <Popover
            placement="bottomRight"
            trigger="click"
            content={<AppSwitcher userId={userId} {...appSwitcherProps} />}
            overlayClassName={`${prefixCls}-app-switcher-overlay`}
          >
            <Button
              data-test-id="header-appSwitcher-button"
              type="tertiary"
              iconName="applications"
              color="grey"
            />
          </Popover>
        </div>
      )}
    </div>
  );
};

RightMenu.displayName = "RightMenu";

export default RightMenu;
