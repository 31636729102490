import React from "react";

import { useIntl } from "react-intl";
import { notificationServiceMessage } from "../../translations/defineMessage";
import { NotificationToasterInput } from "../../types";
import { toaster } from "@trace-one/design-system";
import DOMPurify from "dompurify";

const openNotification = ({
  title = "",
  description = "",
  actions,
}: NotificationToasterInput) => {
  toaster.open({
    placement: "topRight",
    type: "information",
    size: "default",
    description,
    message: title,
    actions,
  });
};

const useNotificationAlert = () => {
  const { formatMessage } = useIntl();

  const getNotifcationAlertActions = notification => {
    if (notification.notificationUrl) {
      return {
        actions: [
          {
            text: formatMessage(notificationServiceMessage.alertLinkText),
            href: notification.notificationUrl,
          },
        ],
      };
    }

    return {};
  };

  const showNewNotificationAlert = notification => {
    openNotification({
      description: (
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(notification.notificationContent),
          }}
        />
      ),
      title: `${notification.senderZone} - ${notification.notificationSubject}`,
      ...getNotifcationAlertActions(notification),
    });
  };

  const showNewNotificationCount = (count: number) => {
    openNotification({
      title: formatMessage(notificationServiceMessage.title, {
        count,
      }),
    });
  };

  return {
    showNewNotificationCount,
    showNewNotificationAlert,
  };
};

export default useNotificationAlert;
