import React from "react";
import { Heading, Button } from "@trace-one/design-system";

import "../../styles.less";
import { FiltersToolbarProps } from "../../types";
import { useIntl } from "react-intl";
import { notificationsFilterMessage } from "../../translations/defineMessage";

const FiltersToolbar: React.FC<FiltersToolbarProps> = ({ toggleFilters }) => {
  const { formatMessage } = useIntl();
  const title = formatMessage(notificationsFilterMessage.toolbarTitle);

  return (
    <header
      className="notifications-filter-toolbar"
      data-test-id="notification-panel-filters-toolbar"
    >
      <div>
        <Button
          data-test-id="notifications-panel-toolbar-back-btn"
          type="tertiary"
          iconName="long-arrow-left"
          onClick={toggleFilters}
        ></Button>
        <Heading size="xs">{title}</Heading>
      </div>
    </header>
  );
};

export default FiltersToolbar;
