import React from "react";
import { Icon, Badge } from "@trace-one/design-system";
import { withLanguageData } from "src/hocs";

import frFr from "./translations/fr-FR.json";
import enUs from "./translations/en-US.json";
import deDe from "./translations/de-DE.json";
import esEs from "./translations/es-ES.json";
import ptPt from "./translations/pt-PT.json";
import itIt from "./translations/it-IT.json";
import nlBe from "./translations/nl-BE.json";
import { LangList } from "../../../utils/const";
import { useLanguagePreference } from "src/hooks";
import { NotificationPanelProps } from "./types";
import useUnreadCounts from "./hooks/useUnreadCounts";

import "./styles.less";
import useNotificationPanelOpen from "./hooks/useNotificationPanelOpen";
import { NotificationsPanelPortal } from "./components/Modal";
import useNotificationDigestParams from "./hooks/useNotificationDigestParams";

const DEFAULT_LANG = enUs;

const enhance = withLanguageData({
  translations: {
    [LangList.EN_US]: enUs,
    [LangList.FR_FR]: { ...DEFAULT_LANG, ...frFr },
    [LangList.DE_DE]: { ...DEFAULT_LANG, ...deDe },
    [LangList.ES_ES]: { ...DEFAULT_LANG, ...esEs },
    [LangList.PT_PT]: { ...DEFAULT_LANG, ...ptPt },
    [LangList.IT_IT]: { ...DEFAULT_LANG, ...itIt },
    [LangList.NL_BE]: { ...DEFAULT_LANG, ...nlBe },
  },
});

export const NotificationsPanel: React.FC<NotificationPanelProps> = ({
  history,
  pathNotificationsSettings,
  languageCode,
  onNotificationPanelToggle = () => {},
  iconColor = "grey",
}) => {
  const languageCodePreference = useLanguagePreference();
  languageCode = languageCode ?? languageCodePreference;
  const { unreadNotifications, countsBySenderZones, refreshCount } =
    useUnreadCounts();
  const { open, setOpen, isNodeMounted, nodeRef, toggleOpen } =
    useNotificationPanelOpen(history, onNotificationPanelToggle);
  const digestURLState = useNotificationDigestParams({ setOpen });

  if (!isNodeMounted || languageCode == null) {
    return null;
  }

  return (
    <>
      <div className="notifications-button">
        <Badge
          color="red-dark"
          count={unreadNotifications}
          placement="topRight"
        >
          <Icon
            data-test-id="notifications-switch-icon"
            name="notification"
            color={iconColor}
            onClick={toggleOpen}
          />
        </Badge>
      </div>
      <NotificationsPanelPortal
        digestURLState={digestURLState}
        node={nodeRef.current}
        languageCode={languageCode}
        unreadNotifications={unreadNotifications}
        refreshCount={refreshCount}
        countsBySenderZones={countsBySenderZones}
        isOpen={open}
        setIsOpen={setOpen}
        pathNotificationsSettings={pathNotificationsSettings}
      />
    </>
  );
};

export default enhance(NotificationsPanel);
