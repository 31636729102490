import { defineMessages } from "react-intl";

export const sideBarMessages = defineMessages({
  myProfile: {
    id: "businessComponents.userProfilePage.myProfile.title",
    defaultMessage: "My Profile",
  },
  notification: {
    id: "businessComponents.userProfilePage.notificationSettings.title",
    defaultMessage: "Notification Settings",
  },
  mySettings: {
    id: "businessComponents.userProfilePage.mySettings.title",
    defaultMessage: "MySettings",
  },
  administrationSettings: {
    id: "businessComponents.userProfilePage.administrationSettings.title",
    defaultMessage: "Administration settings",
  },
  companyProfile: {
    id: "businessComponents.userProfilePage.companyProfile.title",
    defaultMessage: "Company profile",
  },
  changePassword: {
    id: "businessComponents.userProfilePage.changePassword.title",
    defaultMessage: "Change password",
  },
  logOutButton: {
    id: "businessComponents.userProfilePage.logOut.button.text",
    defaultMessage: "LOG OUT",
  },
});

export const settingsContentMessages = defineMessages({
  title: {
    id: "businessComponents.userProfilePage.mySettings.title",
    defaultMessage: "My settings",
  },
  languageDescription: {
    id: "businessComponents.userProfilePage.content.language.description",
    defaultMessage:
      "The language you choose will be applied in all your Trace One applications",
  },
  country: {
    id: "businessComponents.userProfilePage.content.mySettings.country",
    defaultMessage: "Country",
  },
  timezone: {
    id: "businessComponents.userProfilePage.content.mySettings.timezone",
    defaultMessage: "Timezone",
  },
  login: {
    id: "businessComponents.userProfilePage.content.mySettings.login",
    defaultMessage: "Login",
  },
  loginErrorMessage: {
    id: "businessComponents.userProfilePage.content.mySettings.login.message.isRequired",
    defaultMessage: "Login field is required.",
  },
  toasterConfirmation: {
    id: "businessComponents.userProfilePage.content.mySettings.login.toaster.confirmation",
    defaultMessage: "Confirmation",
  },
  toasterConfirmationMsg: {
    id: "businessComponents.userProfilePage.content.mySettings.login.toaster.confirmation.msg",
    defaultMessage: "Your login has been saved.",
  },
  toasterAlert: {
    id: "businessComponents.userProfilePage.content.mySettings.login.toaster.alert",
    defaultMessage: "Alert",
  },
  toasterAlertMsg: {
    id: "businessComponents.userProfilePage.content.mySettings.login.toaster.alert.msg",
    defaultMessage: "Your changes could not be saved.",
  },

  confirmationLogin: {
    id: "businessComponents.userProfilePage.content.mySettings.loginConfirmation",
    defaultMessage: "Confirm login",
  },
  loginConfirmationErrorMessage: {
    id: "businessComponents.userProfilePage.content.mySettings.login.message.noMatch",
    defaultMessage: "The specified login do not match.",
  },
  save: {
    id: "businessComponents.userProfilePage.content.mySettings.btn",
    defaultMessage: "SAVE LOGIN",
  },
});

export const notifSettingsContentMessages = defineMessages({
  title: {
    id: "businessComponents.userProfilePage.notificationSettings.title",
    defaultMessage: "Notification settings",
  },
  email: {
    id: "businessComponents.userProfilePage.content.notif.email",
    defaultMessage: "Notification e-mail",
  },
  emailPlaceholder: {
    id: "businessComponents.userProfilePage.content.notif.email.placeholder",
    defaultMessage: "Enter an e-mail address",
  },
  emailErrorMessage: {
    id: "businessComponents.userProfilePage.content.notif.email.message.isRequired",
    defaultMessage: "Notification e-mail field is required.",
  },
  toasterConfirmationMsg: {
    id: "businessComponents.userProfilePage.content.notif.email.toaster.confirmation.msg",
    defaultMessage: "Your notification e-mail has been saved.",
  },
  emailVerification: {
    id: "businessComponents.userProfilePage.content.notif.emailVerification",
    defaultMessage: "Confirm notification e-mail",
  },
  btnSave: {
    id: "businessComponents.userProfilePage.content.notif.btn",
    defaultMessage: "SAVE EMAIL",
  },
  emailVerificationErrorMessage: {
    id: "businessComponents.userProfilePage.content.notif.emailVerification.message.noMatch",
    defaultMessage: "The specified mail do not match.",
  },
  emailIsRequiredErrorMessage: {
    id: "businessComponents.userProfilePage.content.notif.email.message.isRequired",
    defaultMessage: "Notification e-mail field is required.",
  },
  emailDigest: {
    id: "businessComponents.userProfilePage.content.notif.emailDigest",
    defaultMessage: "Email digest",
  },
  deactivated: {
    id: "businessComponents.userProfilePage.content.notif.emailDigest.deactivated",
    defaultMessage: "Deactivated",
  },
  activated: {
    id: "businessComponents.userProfilePage.content.notif.emailDigest.activated",
    defaultMessage: "Activated",
  },
  daily: {
    id: "businessComponents.userProfilePage.content.notif.emailDigest.daily",
    defaultMessage: "Daily",
  },
  dailyDescription: {
    id: "businessComponents.userProfilePage.content.notif.emailDigest.daily.description",
    defaultMessage:
      "Automatically generate every morning a single email that combines all received notifications during a time period",
  },
  weekly: {
    id: "businessComponents.userProfilePage.content.notif.emailDigest.weekly",
    defaultMessage: "Weekly",
  },
  weeklyDescription: {
    id: "businessComponents.userProfilePage.content.notif.emailDigest.weekly.description",
    defaultMessage:
      "Automatically generate every Monday morning a single email that combines all received notifications during a time period",
  },
});
