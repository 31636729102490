import React, { memo, useMemo } from "react";
import "../../styles.less";
import Tab from "./Tab";
import { TabsProps } from "../../types";

const Tabs: React.FC<TabsProps> = ({
  selected,
  setSelectedTab,
  countsBySenderZones = [],
  apps,
}) => {
  const tabs = useMemo(() => {
    return apps.map(app => {
      let count = 0;
      if (app.senderZone !== "All") {
        count = countsBySenderZones.find(
          senderZoneCount =>
            senderZoneCount.senderZone.toUpperCase() ===
            app.senderZone.toUpperCase()
        )?.unreadCount;
      }
      return (
        <Tab
          unreadCount={count}
          key={app.senderZone}
          label={app.displayText}
          appName={app.name}
          isSelected={selected === app.senderZone}
          senderZone={app.senderZone}
          onSelect={setSelectedTab}
        />
      );
    });
  }, [selected, apps, countsBySenderZones]);

  return (
    <aside
      className="notifications-tabs-container"
      data-test-id="notifications-tabs-container"
    >
      {tabs}
    </aside>
  );
};

export default memo(Tabs);
