import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Form, Collapse, Row, Col } from "antd";
import { Input, Select, Toggle, Tooltip } from "@trace-one/design-system";
import InputUnit from "../InputUnit";
import { useCreatePackagingContext } from "src/components/PackagingSystem/context";
import { isObjectEmpty } from "src/utils/general";
import { checkComboField, filterOption } from "../CreatePackagingSystem/utils";
import { ComponentItemProps } from "../CreatePackagingSystem/types";
import { PACKAGING_COMPONENT_PROPERTIES } from "../../constants";
import { messages as commonMessages } from "src/messages";
import { createPackagingMessage } from "../../translations/messages";
import "./styles.less";

const ComponentItem: React.FC<ComponentItemProps> = ({
  component,
  index,
  duplicateNames,
}) => {
  const {
    isComponentPrintable,
    activeCollapse,
    setActiveCollapse,
    unitOfMeasureMass,
    unitOfMeasureLength,
    types,
    onHandleChange,
    componentDimensionDictionary,
  } = useCreatePackagingContext();

  const intl = useIntl();
  const [psComponentNameError, setPsComponentNameError] = useState(null);
  const psItemPrefixCls = "business-components-create-packaging";
  useEffect(() => {
    setPsComponentNameError(
      duplicateNames?.some(
        duplicateName =>
          duplicateName?.toUpperCase() === component?.name?.trim().toUpperCase()
      )
        ? intl.formatMessage(createPackagingMessage.duplicateName)
        : null
    );
  }, [duplicateNames]);

  const usageLevelData = [
    {
      name: intl.formatMessage(createPackagingMessage.usageLevelPrimary),
      value: "primary",
      title: "",
    },
    {
      name: intl.formatMessage(createPackagingMessage.usageLevelSecondary),
      value: "secondary",
      title: "",
    },
    {
      name: intl.formatMessage(createPackagingMessage.usageLevelTertiary),
      value: "tertiary",
      title: "",
    },
  ];

  const { Panel } = Collapse;
  const componentId =
    component?.id?.length > 0 ? component?.id : component?.componentId;

  const getFormItemMinimumNumberValidation = (
    value,
    formatMessage,
    validationMessage
  ) => {
    return value?.value <= 0
      ? (
      <span className={`${psItemPrefixCls}-min-number-validation`}>
        {formatMessage(validationMessage)}
      </span>
        )
      : null;
  };

  useEffect(() => {
    if (activeCollapse !== null) {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [activeCollapse]);

  return (
    <div className={psItemPrefixCls}>
      <Collapse
        activeKey={
          activeCollapse === componentId ? [componentId.toString()] : []
        }
        onChange={activeIndexes => {
          if (activeIndexes.length === 0) {
            setActiveCollapse(null);
          } else {
            setActiveCollapse(activeIndexes[0]);
          }
        }}
      >
        <Panel
          header={
            <div className={`${psItemPrefixCls}-panel-header`}>
              <div className={`${psItemPrefixCls}-header-text`}>
                {component?.name
                  ? (
                  <Tooltip text={component?.name} showFullText={true}>
                    <span>{component?.name}</span>
                  </Tooltip>
                    )
                  : (
                  <span>
                    {intl.formatMessage(createPackagingMessage.componentName)}
                  </span>
                    )}
              </div>
              <div className={`${psItemPrefixCls}-printable-component`}>
                <span className="name">
                  {intl.formatMessage(
                    createPackagingMessage.printableComponent
                  )}
                </span>
                <Toggle
                  onChange={(checked, event) => {
                    event.stopPropagation();
                    isComponentPrintable(checked, index);
                  }}
                  defaultChecked={component?.printableComponent}
                  data-test-id="printableComponent"
                />
              </div>
            </div>
          }
          key={componentId}
        >
          <Row gutter={[16, 16]}>
            <Col span={16}>
              <div className="component-name" data-test-id="name-title">
                {intl.formatMessage(commonMessages.packagingComponentNameLabel)}
              </div>
              <Form.Item
                name={`${componentId}.componentName`}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage(
                      createPackagingMessage.mandatoryComponentname
                    ),
                  },
                ]}
                initialValue={component?.name}
              >
                <Input
                  placeholder={intl.formatMessage(
                    createPackagingMessage.psComponentNamePlaceholder
                  )}
                  name={`${componentId}.componentName`}
                  data-test-id={"componentName"}
                  value={component?.name}
                  defaultValue={component?.name}
                  onChange={({ target: { value } }) => {
                    onHandleChange({ value, name: "name", index });
                  }}
                  error={!!psComponentNameError}
                  errorMessage={psComponentNameError}
                />
              </Form.Item>
            </Col>
            <Col span={16}>
              <div className="type-name" data-test-id="type-title">
                Type
              </div>
              <Form.Item
                name={`${componentId}.componentType`}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage(
                      createPackagingMessage.mandatoryTypesname
                    ),
                  },
                ]}
                initialValue={component?.componentTypeId}
              >
                <Select
                  name={`${componentId}.componentType`}
                  placeholder={intl.formatMessage(
                    createPackagingMessage.typePlaceholder
                  )}
                  defaultValue={component?.componentTypeId}
                  data-test-id={"Componenttype"}
                  options={types}
                  onChange={value =>
                    onHandleChange({
                      value,
                      name: "componentTypeId",
                      index,
                    })
                  }
                  showSearch
                  filterOption={filterOption}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <div
                data-test-id="usage-level-title"
                className={`${psItemPrefixCls}-usage-level`}
              >
                {intl.formatMessage(createPackagingMessage.usageLevelTitle)}
              </div>
              <Form.Item
                name={`${componentId}.usageLevel`}
                initialValue={component?.usageLevel}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage(
                      createPackagingMessage.mandatory
                    ),
                  },
                ]}
              >
                <Select
                  name={`${componentId}.usageLevel`}
                  placeholder={intl.formatMessage(
                    createPackagingMessage.usageLevelPlaceholder
                  )}
                  data-test-id={"componentUsageLevel"}
                  options={usageLevelData}
                  onChange={value =>
                    onHandleChange({ value, name: "usageLevel", index })
                  }
                  defaultValue={component?.usageLevel}
                  allowClear={true}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={10} className={`${psItemPrefixCls}-Column`}>
              <Form.Item
                name={PACKAGING_COMPONENT_PROPERTIES.HEIGHT}
                help={getFormItemMinimumNumberValidation(
                  component?.height,
                  intl.formatMessage,
                  createPackagingMessage.minNumberValidation
                )}
                rules={[
                  {
                    validator: checkComboField(
                      intl.formatMessage,
                      component?.height
                    ),
                  },
                ]}
              >
                <div className={`${psItemPrefixCls}-form-items`}>
                  <div
                    className={`${psItemPrefixCls}-feild-names`}
                    data-test-id="height-title"
                  >
                    {intl.formatMessage(createPackagingMessage.heightTitle)}
                  </div>
                  <InputUnit
                    dataTestId="heightComponent"
                    options={unitOfMeasureLength}
                    name={PACKAGING_COMPONENT_PROPERTIES.HEIGHT}
                    index={index}
                    previousValues={component?.height || undefined}
                    component={component}
                    {...(!isObjectEmpty(
                      componentDimensionDictionary[
                        PACKAGING_COMPONENT_PROPERTIES.HEIGHT
                      ]
                    ) &&
                      componentDimensionDictionary[
                        PACKAGING_COMPONENT_PROPERTIES.HEIGHT
                      ])}
                  />
                </div>
              </Form.Item>
            </Col>
            <Col span={10} className={`${psItemPrefixCls}-Column`}>
              <Form.Item
                name={PACKAGING_COMPONENT_PROPERTIES.LENGTH}
                help={getFormItemMinimumNumberValidation(
                  component?.length,
                  intl.formatMessage,
                  createPackagingMessage.minNumberValidation
                )}
                rules={[
                  {
                    validator: checkComboField(
                      intl.formatMessage,
                      component?.length
                    ),
                  },
                ]}
              >
                <div className={`${psItemPrefixCls}-form-items`}>
                  <div
                    className={`${psItemPrefixCls}-feild-names`}
                    data-test-id="length-title"
                  >
                    {intl.formatMessage(createPackagingMessage.lengthTitle)}
                  </div>
                  <InputUnit
                    dataTestId={"lengthComponent"}
                    options={unitOfMeasureLength}
                    index={index}
                    name={PACKAGING_COMPONENT_PROPERTIES.LENGTH}
                    previousValues={component?.length || undefined}
                    component={component}
                    {...(!isObjectEmpty(
                      componentDimensionDictionary[
                        PACKAGING_COMPONENT_PROPERTIES.LENGTH
                      ]
                    ) &&
                      componentDimensionDictionary[
                        PACKAGING_COMPONENT_PROPERTIES.LENGTH
                      ])}
                  />
                </div>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={10}>
              <Form.Item
                name={PACKAGING_COMPONENT_PROPERTIES.WIDTH}
                help={getFormItemMinimumNumberValidation(
                  component?.width,
                  intl.formatMessage,
                  createPackagingMessage.minNumberValidation
                )}
                rules={[
                  {
                    validator: checkComboField(
                      intl.formatMessage,
                      component?.width
                    ),
                  },
                ]}
              >
                <div className={`${psItemPrefixCls}-form-items`}>
                  <div
                    className={`${psItemPrefixCls}-feild-names`}
                    data-test-id="width-title"
                  >
                    {intl.formatMessage(createPackagingMessage.widthTitle)}
                  </div>
                  <InputUnit
                    options={unitOfMeasureLength}
                    dataTestId={"widthComponent"}
                    index={index}
                    name={PACKAGING_COMPONENT_PROPERTIES.WIDTH}
                    previousValues={component?.width || undefined}
                    component={component}
                    {...(!isObjectEmpty(
                      componentDimensionDictionary[
                        PACKAGING_COMPONENT_PROPERTIES.WIDTH
                      ]
                    ) &&
                      componentDimensionDictionary[
                        PACKAGING_COMPONENT_PROPERTIES.WIDTH
                      ])}
                  />
                </div>
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                name={PACKAGING_COMPONENT_PROPERTIES.WEIGHT}
                help={getFormItemMinimumNumberValidation(
                  component?.weight,
                  intl.formatMessage,
                  createPackagingMessage.minNumberValidation
                )}
                rules={[
                  {
                    validator: checkComboField(
                      intl.formatMessage,
                      component?.weight
                    ),
                  },
                ]}
              >
                <div className={`${psItemPrefixCls}-form-items`}>
                  <div
                    className={`${psItemPrefixCls}-feild-names`}
                    data-test-id="weight-title"
                  >
                    {intl.formatMessage(createPackagingMessage.weightTitle)}
                  </div>
                  <InputUnit
                    options={unitOfMeasureMass}
                    dataTestId={"weightComponent"}
                    index={index}
                    name={PACKAGING_COMPONENT_PROPERTIES.WEIGHT}
                    previousValues={component?.weight || undefined}
                    component={component}
                    {...(!isObjectEmpty(
                      componentDimensionDictionary[
                        PACKAGING_COMPONENT_PROPERTIES.WEIGHT
                      ]
                    ) &&
                      componentDimensionDictionary[
                        PACKAGING_COMPONENT_PROPERTIES.WEIGHT
                      ])}
                  />
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12} className={`${psItemPrefixCls}-Column`}>
              <Form.Item
                name={PACKAGING_COMPONENT_PROPERTIES.DIAMETER}
                help={getFormItemMinimumNumberValidation(
                  component?.diameter,
                  intl.formatMessage,
                  createPackagingMessage.minNumberValidation
                )}
                rules={[
                  {
                    validator: checkComboField(
                      intl.formatMessage,
                      component?.diameter
                    ),
                  },
                ]}
              >
                <div className={`${psItemPrefixCls}-form-items`}>
                  <div
                    className={`${psItemPrefixCls}-feild-names`}
                    data-test-id="diameter-title"
                  >
                    {intl.formatMessage(createPackagingMessage.diameterTitle)}
                  </div>
                  <InputUnit
                    options={unitOfMeasureLength}
                    dataTestId="diameterComponent"
                    index={index}
                    name={PACKAGING_COMPONENT_PROPERTIES.DIAMETER}
                    previousValues={component?.diameter || undefined}
                    {...(!isObjectEmpty(
                      componentDimensionDictionary[
                        PACKAGING_COMPONENT_PROPERTIES.DIAMETER
                      ]
                    ) &&
                      componentDimensionDictionary[
                        PACKAGING_COMPONENT_PROPERTIES.DIAMETER
                      ])}
                    component={component}
                  />
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </div>
  );
};

export default ComponentItem;
