import { useEffect, useMemo, useState } from "react";
import { ToogleButtonsValue } from "@trace-one/design-system";
import { useLanguagePreference } from "src/hooks";
import * as API from "src/apis/notif";
import { NotificationSettings } from "src/models/notification";
import {
  DIGEST_FREQUENCY,
  NOTIFICATION_LEVEL,
  NOTIFICATION_TYPE,
} from "src/utils/constants";

const useNotificationSettings = ({
  applicationType,
}: {
  applicationType: ToogleButtonsValue;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [reloadNotificationSettings, setReloadNotificationSettings] =
    useState(false);
  const [notificationSettings, setNotificationSettings] =
    useState<NotificationSettings>(notificationSettingsInitialState);
  const languageCode = useLanguagePreference();

  const getNotificationSettings = async () => {
    if (applicationType) {
      setIsLoading(true);
      try {
        const { data } = await API.getNotificationSettings({
          languageCode,
          senderZone: applicationType,
          level: NOTIFICATION_LEVEL.NOTIFICATION_TYPE,
        });
        setNotificationSettings(data);
      } catch (error) {
        setNotificationSettings(notificationSettingsInitialState);
      } finally {
        setIsLoading(false);
        setReloadNotificationSettings(false);
      }
    }
  };

  const updateNotificationSettings = async ({
    notificationSettings,
  }: {
    notificationSettings: NotificationSettings;
  }) => {
    try {
      await API.updateNotificationSettings({
        notificationSettings,
      });
      setReloadNotificationSettings(true);
    } catch (_) {}
  };

  const onChangeNotificationCommunicationMethod =
    ({ id, type }: { id: string; type: string }) =>
    (checked: boolean) => {
      const notificationPreference =
        notificationSettings.notificationPreferences.find(
          value => value.notificationTypeId === id
        );
      notificationPreference.communicationMethods = checked
        ? [...notificationPreference.communicationMethods, type]
        : notificationPreference.communicationMethods.filter(
            value => value !== type
          );

      updateNotificationSettings({
        notificationSettings: {
          notificationPreferences: [notificationPreference],
        },
      });
    };

  const onChangeNotificationDigest =
    ({ id }: { id: string }) =>
    (checked: boolean) => {
      const newNotificationPreferences =
        notificationSettings.notificationPreferences.map(previousState => {
          if (previousState.notificationTypeId === id) {
            return {
              ...previousState,
              isDigestActive: checked,
              communicationMethods: [
                NOTIFICATION_TYPE.WEB,
                NOTIFICATION_TYPE.EMAIL,
              ],
            };
          }

          return previousState;
        });

      updateNotificationSettings({
        notificationSettings: {
          ...notificationSettings,
          notificationPreferences: newNotificationPreferences,
        },
      });
    };

  const isDigestAvailable = useMemo(
    () => notificationSettings.digestFrequency !== DIGEST_FREQUENCY.OFF,
    [notificationSettings.digestFrequency]
  );

  useEffect(() => {
    getNotificationSettings();
  }, [applicationType, languageCode]);

  useEffect(() => {
    if (reloadNotificationSettings) {
      getNotificationSettings();
    }
  }, [reloadNotificationSettings]);

  return {
    notificationSettings,
    onChangeNotificationCommunicationMethod,
    onChangeNotificationDigest,
    isDigestAvailable,
    isLoading,
  };
};

const notificationSettingsInitialState = {
  notificationPreferences: [],
  digestFrequency: DIGEST_FREQUENCY.OFF,
};

export default useNotificationSettings;
