import React, { useMemo } from "react";
import { Heading, Button, Badge } from "@trace-one/design-system";
import useToolbarTitle from "src/components/NotificationsPanel/hooks/useToolbarTitle";

import "../../styles.less";
import { ToolbarProps } from "../../types";
import { useIntl } from "react-intl";
import { toolbarMessage } from "../../translations/defineMessage";

const Toolbar: React.FC<ToolbarProps> = ({
  selectedTab,
  tools = false,
  unreadNotificationsCount = 0,
  countsBySenderZones = [],
  pathNotificationsSettings,
  markAllNotificationsAsRead,
  toggleFilters,
}) => {
  const title = useToolbarTitle(selectedTab);
  const { formatMessage } = useIntl();

  const unreadCount = useMemo(() => {
    let count = unreadNotificationsCount || 0;
    if (selectedTab !== "All") {
      count = countsBySenderZones.find(
        senderZoneCount =>
          senderZoneCount.senderZone.toUpperCase() === selectedTab.toUpperCase()
      )?.unreadCount;
    }

    return count;
  }, [selectedTab, unreadNotificationsCount, countsBySenderZones]);

  function handleSettingsClick () {
    if (pathNotificationsSettings) {
      window.open(pathNotificationsSettings, "_self");
    }
  }
  return (
    <header className="notifications-toolbar">
      <div>
        <Heading size="xs">
          {title} <Badge color="red-dark" count={unreadCount} />
        </Heading>
      </div>
      <div>
        {tools && (
          <div className="notifications-tools-wrapper">
            <Button
              data-test-id="notifications-panel-toolbar-mark-all-read-btn"
              type="link"
              iconName="check"
              disabled={unreadCount === 0}
              onClick={markAllNotificationsAsRead}
            >
              {formatMessage(toolbarMessage.markAllAsRead)}
            </Button>

            <Button
              data-test-id="notifications-panel-toolbar-filter-btn"
              type="tertiary"
              onClick={toggleFilters}
              iconName="filter"
            ></Button>
            {!!pathNotificationsSettings && (
              <Button
                data-test-id="notifications-panel-toolbar-settings-btn"
                onClick={handleSettingsClick}
                type="tertiary"
                iconName="settings"
              ></Button>
            )}
          </div>
        )}
      </div>
    </header>
  );
};

export default Toolbar;
