import React from "react";
import { getPrefixClassName } from "src/utils/general";
import "./styles.less";
import { withLanguageData } from "src/hocs";
import { LangList } from "../../../utils/const";
import enUs from "./translations/en-US.json";
import frFr from "./translations/fr-FR.json";
import deDe from "./translations/de-DE.json";
import nlBe from "./translations/nl-BE.json";
import esEs from "./translations/es-ES.json";
import itIt from "./translations/it-IT.json";
import ptPt from "./translations/pt-PT.json";
import { ModalProductSelectProps } from "./types";
import { Basket } from "IndexComponents";
import FullPageModal from "./components/FullPageModal";
import useSelectedProducts from "./hooks/useSelectedProducts";
import useProducts from "./hooks/useProducts";
import ProductSearch from "./components/ProductSearch";
import useNetContents from "./hooks/useNetContents";
import ProductList from "./components/ProductList";
import { useIntl } from "react-intl";
import messages from "./translations/messages";

const DEFAULT_LANG = enUs;

const enhance = withLanguageData({
  translations: {
    [LangList.EN_US]: enUs,
    [LangList.FR_FR]: { ...DEFAULT_LANG, ...frFr },
    [LangList.DE_DE]: { ...DEFAULT_LANG, ...deDe },
    [LangList.ES_ES]: { ...DEFAULT_LANG, ...esEs },
    [LangList.PT_PT]: { ...DEFAULT_LANG, ...ptPt },
    [LangList.IT_IT]: { ...DEFAULT_LANG, ...itIt },
    [LangList.NL_BE]: { ...DEFAULT_LANG, ...nlBe },
  },
});

const prefixCls = getPrefixClassName("modal-product-select");

const ModalProductSelect: React.FC<ModalProductSelectProps> = ({
  className,
  "data-test-id": dataTestId,
  open = false,
  ownerCompanyId,
  onClose = () => {},
}) => {
  const { formatMessage } = useIntl();
  const { products, total, pagination, filter } = useProducts({
    ownerCompanyId,
  });
  const { netContentMeasures } = useNetContents({ languageCode: "en" });
  const {
    selectedProducts,
    handleAddSelectedProduct,
    handleRemoveSelectedProduct,
    onValidateClick,
    isProductSelected,
  } = useSelectedProducts({ onClose });

  return (
    <FullPageModal
      className={className}
      data-test-id={dataTestId}
      open={open}
      title={formatMessage(messages.title)}
    >
      <div>{formatMessage(messages.description)}</div>
      <div className={`${prefixCls}--search-container`}>
        <ProductSearch {...filter} isRetailer ownerCompanyId=""></ProductSearch>
      </div>
      <div className={`${prefixCls}--content-container`}>
        <ProductList
          total={total}
          products={products}
          handleAddSelectedProduct={handleAddSelectedProduct}
          handleRemoveSelectedProduct={handleRemoveSelectedProduct}
          isProductSelected={isProductSelected}
          netContentMeasures={netContentMeasures}
          pagination={pagination}
        ></ProductList>
        <div>
          <Basket
            title={formatMessage(messages.basketTitle)}
            items={selectedProducts.map(p => ({ id: p.id, text: p.itemName }))}
            emptyNote={formatMessage(messages.title)}
            validateButtonText={formatMessage(messages.basketValidate)}
            onValidate={onValidateClick}
            onDelete={item => handleRemoveSelectedProduct(item.id as string)}
          ></Basket>
        </div>
      </div>
    </FullPageModal>
  );
};

export default enhance(ModalProductSelect);
