import { fetchRefList, fetchRefListCollection } from "src/apis/rlmd";
import { optionsByRefList } from "../../utils/helpers";
import { rlmdIds } from "src/apis/constants";
import { REFLIST_IDS } from "../../constants";

export interface UseGetRefListsProps {
  languageCode: string;
  companyId: string;
}

interface UnitMeasurementsProps {
  name: string;
  value: string;
}
export const useGetRefLists = ({
  languageCode,
  companyId,
}: UseGetRefListsProps) => {
  const getRefLists = async (
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setTypesOptions: React.Dispatch<
      React.SetStateAction<UnitMeasurementsProps[]>
    >,
    setUnitOfMeasureLengthOptions: React.Dispatch<
      React.SetStateAction<UnitMeasurementsProps[]>
    >,
    setUnitOfMeasureMassOptions: React.Dispatch<
      React.SetStateAction<UnitMeasurementsProps[]>
    >
  ) => {
    setIsLoading(true);

    try {
      await Promise.all([
        fetchRefList({
          languageCode,
          id: rlmdIds.componentTypes,
          companyId,
        }),
        fetchRefListCollection({
          languageCode,
          id: rlmdIds.netContentUnitOfMeasure,
          parentItemIds: [rlmdIds.typeUnitOfMeasureLength],
        }),
        fetchRefListCollection({
          languageCode,
          id: rlmdIds.netContentUnitOfMeasure,
          parentItemIds: [
            rlmdIds.typeUnitOfMeasureMass,
            REFLIST_IDS.INTERNATIONAL_SYSTEM_SOURCE,
          ],
        }),
      ]).then(([types, unitOfMeasureLength, unitOfMeasureMass]) => {
        setTypesOptions(optionsByRefList(types));
        setUnitOfMeasureLengthOptions(optionsByRefList(unitOfMeasureLength));
        setUnitOfMeasureMassOptions(optionsByRefList(unitOfMeasureMass));
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    getRefLists,
  };
};

export default useGetRefLists;
