import { defineMessages } from "react-intl";

export const createPackagingMessage = defineMessages({
  title: {
    id: "shared.business.components.createPackaging.title",
    defaultMessage: "Create a new packaging system",
  },
  editTitle: {
    id: "shared.business.components.editPackaging.title",
    defaultMessage: "Edit packaging system",
  },
  subTitle: {
    id: "shared.business.components.createPackaging.subTitle",
    defaultMessage:
      "Define the component(s) that constitute the packaging system",
  },
  formComponentTitle: {
    id: "shared.business.components.createPackaging.formComponentTitle",
    defaultMessage: "Component(s)",
  },
  mandatoryPsname: {
    id: "shared.business.components.createPackaging.mandatory.psName",
    defaultMessage: " Please fill in the Packaging System Name",
  },
  mandatoryComponentname: {
    id: "shared.business.components.createPackaging.mandatory.ComponentName",
    defaultMessage: " Please fill in the Component Name",
  },
  mandatoryTypesname: {
    id: "shared.business.components.createPackaging.mandatory.typesName",
    defaultMessage: " Please fill in the Component type",
  },
  minNumberValidation: {
    id: "shared.business.components.createPackaging.minNumberValidation",
    defaultMessage:
      "The selected number is lower than the minimum authorized value",
  },
  printableMandatory: {
    id: "shared.business.components.createPackaging.printableMandatory",
    defaultMessage: "Please select at least one document type",
  },
  addAComponent: {
    id: "shared.business.components.createPackaging.add.a.components",
    defaultMessage: "Add a component",
  },
  createComponents: {
    id: "shared.business.components.createPackaging.create.components",
    defaultMessage: "Create your component(s)",
  },
  createComponent: {
    id: "shared.business.components.createPackaging.create.component",
    defaultMessage: "Create a component",
  },
  psName: {
    id: "shared.business.components.createPackaging.psName",
    defaultMessage: "Packaging system name",
  },
  psNamePlaceholder: {
    id: "shared.business.components.createPackaging.psNamePlaceholder",
    defaultMessage: "Enter a packaging system name",
  },
  addExistingComponent: {
    id: "shared.business.components.createPackaging.addExistingComponent",
    defaultMessage: "Add existing component(s)",
  },
  createComponentTooltip: {
    id: "shared.business.components.createPackaging.crate.component.tooltip",
    defaultMessage: "You cannot create 2 components at the same time.",
  },
  componentName: {
    id: "shared.business.components.createPackaging.componentName",
    defaultMessage: "Component name",
  },
  addComponentButton: {
    id: "shared.business.components.createPackaging.addComponentButton",
    defaultMessage: "ADD COMPONENT",
  },
  createButton: {
    id: "shared.business.components.createPackaging.createButton",
    defaultMessage: "CREATE & ASSOCIATE",
  },
  editButton: {
    id: "shared.business.components.editPackaging.editButton",
    defaultMessage: "EDIT & ASSOCIATE",
  },
  psComponentNamePlaceholder: {
    id: "shared.business.components.createPackaging.psComponentNamePlaceholder",
    defaultMessage: "Enter a component name",
  },
  materialTitle: {
    id: "shared.business.components.createPackaging.materialTitle",
    defaultMessage: "Material",
  },
  materialPlaceholder: {
    id: "shared.business.components.createPackaging.materialPlaceholder",
    defaultMessage: "Enter a material",
  },
  usageLevelTitle: {
    id: "shared.business.components.createPackaging.usageLevelTitle",
    defaultMessage: "Usage level",
  },
  usageLevelPlaceholder: {
    id: "shared.business.components.createPackaging.usageLevelPlaceholder",
    defaultMessage: "Select a value",
  },
  usageLevelPrimary: {
    id: "shared.business.components.createPackaging.usageLevelPrimary",
    defaultMessage: "Primary",
  },
  usageLevelSecondary: {
    id: "shared.business.components.createPackaging.usageLevelSecondary",
    defaultMessage: "Secondary",
  },
  usageLevelTertiary: {
    id: "shared.business.components.createPackaging.usageLevelTertiary",
    defaultMessage: "Tertiary",
  },
  heightTitle: {
    id: "shared.business.components.createPackaging.heightTitle",
    defaultMessage: "Height",
  },
  lengthTitle: {
    id: "shared.business.components.createPackaging.lengthTitle",
    defaultMessage: "Length",
  },
  widthTitle: {
    id: "shared.business.components.createPackaging.widthTitle",
    defaultMessage: "Width",
  },
  diameterTitle: {
    id: "shared.business.components.createPackaging.diameterTitle",
    defaultMessage: "Diameter",
  },
  typeTitle: {
    id: "shared.business.components.createPackaging.typeTitle",
    defaultMessage: "Type",
  },
  weightTitle: {
    id: "shared.business.components.createPackaging.weightTitle",
    defaultMessage: "Weight",
  },
  filesToValidate: {
    id: "shared.business.components.createPackaging.filesToValidate",
    defaultMessage: "Files to validate",
  },
  printableComponent: {
    id: "shared.business.components.createPackaging.printableComponent",
    defaultMessage: "Printable",
  },
  copy: {
    id: "shared.business.components.createPackaging.copy",
    defaultMessage: "Copy",
  },
  type: {
    id: "shared.business.components.createPackaging.type",
    defaultMessage: "Type",
  },
  typePlaceholder: {
    id: "shared.business.components.createPackaging.typePlaceholder",
    defaultMessage: "Select a value",
  },
  duplicateName: {
    id: "shared.business.components.createPackaging.duplicateName",
    defaultMessage:
      "This packaging component name is already used. Please modify it",
  },
  componentSummary: {
    id: "shared.business.components.createPackaging.component.summary",
    defaultMessage: "Component Summary",
  },
  deletetooltip: {
    id: "shared.business.components.createPackaging.component.deletetooltip",
    defaultMessage:
      "You are about to delete this component. This action is irreversible.",
  },
  mandatory: {
    id: "shared.business.components.createPackaging.mandatory",
    defaultMessage: "Mandatory",
  },
  confirm: {
    id: "shared.business.components.createPackaging.component.deletetooltipConfirm",
    defaultMessage: "Confirm",
  },
  packagingSystemDuplicateName: {
    id: "shared.business.components.createPackaging.component.packagingsystemduplicatename",
    defaultMessage:
      "This packaging system name is already used. Please modify it",
  },
  modalTitle: {
    id: "library.packagingSystemForm.addComponent.modalTitle",
    defaultMessage: "Browse existing components",
  },
  modalSubTitle: {
    id: "library.packagingSystemForm.addComponent.modalSubTitle",
    defaultMessage: "Add one or more component(s) to your packaging system",
  },
  modalBodyTitle: {
    id: "library.packagingSystemForm.addComponent.modalBodyTitle",
    defaultMessage: "Component list",
  },
  searchPlaceholder: {
    id: "library.packagingSystemForm.addComponent.searchPlaceholder",
    defaultMessage: "Enter a component name",
  },
  loading: {
    id: "general.loading",
    defaultMessage: "Loading",
  },
  cancel: {
    id: "library.packagingSystemForm.addComponent.actions.cancel",
    defaultMessage: "Cancel",
  },
  errorMessage: {
    id: "general.error.message",
    defaultMessage: "Sorry an error ocurred, please try again.",
  },
  select: {
    id: "library.packagingSystemForm.addComponent.actions.select",
    defaultMessage: "Select",
  },
});
