import { useRef, useState, useEffect } from "react";
import isEqual from "lodash/isEqual";
import { useLanguagePreference } from "src/hooks";
import {
  NotificationFiltersQueryState,
  UseNotificationPanelInputs,
} from "../../types";

import {
  deactivateNotificationType,
  deleteNotifications,
  getNotifications,
  markAllNotificationsAsReadBySenderZone,
} from "src/apis/notif";
import {
  NotificationListItem,
  NotificationStatus,
} from "src/models/notification";
import { getEndOfTheDay, getStartOfTheDay } from "../../utils";
import useNotificationAlert from "../useNotificationAlert";

const DEFAULT_OFFSET = 50;
const REFRESH_INTERVAL = 60000;

const useNotificationPanel = ({
  isOpen,
  refreshCount,
  digestURLState,
}: UseNotificationPanelInputs) => {
  const lastCheck = useRef<Date | string | null>(null);
  const interval = useRef<number | null>(null);
  const timeout = useRef<number | null>(null);
  const [canLoadMore, setCanLoadMore] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<NotificationListItem[]>(
    []
  );
  const [selectedTab, setSelectedTab] = useState<string>(
    digestURLState?.senderZone || "All"
  );
  const [filters, setFilters] = useState<NotificationFiltersQueryState>({});

  const languageCode = useLanguagePreference();
  const isDigestURLParamsUsedOnce = useRef(false);
  const { showNewNotificationAlert, showNewNotificationCount } =
    useNotificationAlert();

  const defineCountRefreshTimeOut = value => 500 + Math.round(value * 0.5);
  const toggleNotificationsReadStatus = (
    ids: string[],
    status: NotificationStatus
  ) => {
    setNotifications(currentNotifications => {
      return currentNotifications.map(notification => {
        if (ids.includes(notification.occurrenceUserInstanceId)) {
          return { ...notification, status };
        }

        return { ...notification };
      });
    });

    timeout.current = window.setTimeout(
      () => refreshCount(),
      defineCountRefreshTimeOut(ids.length)
    );
  };

  const toggleNotificationsReadStatusBySenderZone = (
    senderZone: string,
    status: NotificationStatus
  ) => {
    let countOfLoadedNotificationsBeingMarkedAsRead = 0;

    setNotifications(currentNotifications => {
      if (senderZone) {
        return currentNotifications.map(notification => {
          const updatedNotification = { ...notification };
          if (notification.senderZone === senderZone) {
            countOfLoadedNotificationsBeingMarkedAsRead += 1;
            updatedNotification.status = status;
          }

          return updatedNotification;
        });
      } else {
        // updates all notifications regardless of senderzone
        countOfLoadedNotificationsBeingMarkedAsRead =
          currentNotifications.length;
        return currentNotifications.map(notification => ({
          ...notification,
          status,
        }));
      }
    });

    timeout.current = window.setTimeout(
      () => refreshCount(),
      defineCountRefreshTimeOut(countOfLoadedNotificationsBeingMarkedAsRead)
    );
  };

  const removeNotifications = async (notificationIds: string[]) => {
    try {
      await deleteNotifications({
        occurrenceUserInstanceIds: notificationIds,
      });
      setNotifications(current => {
        return current.filter(
          notification =>
            !notificationIds.includes(notification.occurrenceUserInstanceId)
        );
      });
      refreshCount();
    } catch (error) {
      console.error(error);
      console.error("failed to delete notification");
    }
  };

  const deactivateNotificationsType = async (notificationTypeId: string) => {
    try {
      await deactivateNotificationType({ notificationTypeId });
    } catch (error) {
      console.error(error);
      console.error("faield to deactivate notificationTypeId");
      throw error;
    }
  };

  const markAllNotificationsAsRead = async () => {
    try {
      const senderZone = selectedTab !== "All" ? selectedTab : "";
      await markAllNotificationsAsReadBySenderZone(senderZone);
      toggleNotificationsReadStatusBySenderZone(
        senderZone,
        NotificationStatus.READ
      );
      refreshCount();
    } catch (error) {
      console.error(error);
    }
  };

  const updateLastCheck = async (timestamp = null) => {
    if (timestamp) {
      lastCheck.current = timestamp;
      return;
    }

    try {
      const {
        data: {
          notifications: newNotifications,
          skipAndTakeQueryStats: { totalCount: count },
          serverDateTimeUtc,
        },
      } = await getNotifications({
        skip: 0,
        take: 1,
        languageCode,
      });
      if (count === 0) {
        lastCheck.current = serverDateTimeUtc;
        return;
      }
      const [notification] = newNotifications;
      lastCheck.current = notification.notificationEventUtcDateTime;
    } catch (error) {
      console.warn("Failed to updated last check");
      console.error(error);
    }
  };

  const refreshNewNotifications = async () => {
    if (isLoading) {
      return;
    }

    try {
      const {
        data: {
          notifications: newNotifications,
          skipAndTakeQueryStats: { totalCount: count },
        },
      } = await getNotifications({
        notificationEventUtcDateTimeAfter: String(lastCheck.current),
        take: DEFAULT_OFFSET,
        languageCode,
        senderZone: selectedTab,
      });

      if (count > 0) {
        updateLastCheck(newNotifications[0]?.notificationEventUtcDateTime);
      }
      if (!isOpen && count > 0) {
        if (count === 1) {
          const [notification] = newNotifications;
          showNewNotificationAlert(notification);
        } else {
          showNewNotificationCount(count);
        }
      } else {
        if (!isEqual(notifications, newNotifications)) {
          setNotifications(current => newNotifications.concat(current));
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const loadMore = async (reload = false, skip?) => {
    if (!reload && (!canLoadMore || isLoading)) {
      return;
    }
    setIsLoading(true);

    const { notificationTypeIds, ...restOfTheFilters } = filters;
    if (restOfTheFilters.notificationEventUtcDateTimeAfter) {
      restOfTheFilters.notificationEventUtcDateTimeAfter = getStartOfTheDay(
        restOfTheFilters.notificationEventUtcDateTimeAfter
      );
    }

    if (restOfTheFilters.notificationEventUtcDateTimeBefore) {
      restOfTheFilters.notificationEventUtcDateTimeBefore = getEndOfTheDay(
        restOfTheFilters.notificationEventUtcDateTimeBefore
      );
    }

    try {
      const {
        data: {
          notifications: newNotifications,
          skipAndTakeQueryStats: { totalCount: count },
        },
      } = await getNotifications(
        {
          skip: skip ?? notifications.length,
          take: DEFAULT_OFFSET,
          languageCode,
          senderZone: selectedTab,
          ...restOfTheFilters,
        },
        { notificationTypeIds }
      );

      if (count > 0) {
        updateLastCheck(newNotifications[0]?.notificationEventUtcDateTime);
      }
      if (reload) {
        setNotifications(newNotifications);
      } else {
        setNotifications(current => current.concat(newNotifications));
      }

      setCanLoadMore(count > notifications.length + DEFAULT_OFFSET);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setNotifications([]);
      setCanLoadMore(true);
    } else {
      if (digestURLState && !isDigestURLParamsUsedOnce.current) {
        return;
      }
      loadMore();
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      // this will prevent notificatioons from loading for other state updates when the url query params from digest present
      if (digestURLState && !isDigestURLParamsUsedOnce.current) {
        return;
      }

      setNotifications([]);
      setIsLoading(true);
      loadMore(true, 0);
    }
  }, [selectedTab, languageCode, filters]);

  useEffect(() => {
    setFilters({});
  }, [selectedTab]);

  useEffect(() => {
    updateLastCheck();
    return () => {
      clearInterval(interval.current);
      clearTimeout(timeout.current);
    };
  }, []);

  const restartInterval = () => {
    clearInterval(interval.current);
    interval.current = window.setInterval(
      () => refreshNewNotifications(),
      REFRESH_INTERVAL
    );
  };

  /**
   * The interval will create an isolated instance of itself.
   * The only way to pass new parameters is to restart the interval each time
   */
  useEffect(() => {
    if (isLoading) {
      clearInterval(interval.current);
    } else {
      restartInterval();
    }
  }, [isLoading, isOpen, languageCode, selectedTab]);

  useEffect(() => {
    window.setTimeout(() => {
      isDigestURLParamsUsedOnce.current = true;
      if (digestURLState?.openNotificationPanel) {
        setFilters(filters => {
          return {
            ...filters,
            notificationEventUtcDateTimeAfter: digestURLState.startDate || null,
            notificationEventUtcDateTimeBefore: digestURLState.endDate || null,
            notificationTypeIds: [digestURLState.notificationTypeId],
          };
        });
      }
    }, 200);
  }, [digestURLState]);

  return {
    toggleNotificationsReadStatus,
    setSelectedTab,
    selectedTab,
    isLoading,
    notifications,
    markAllNotificationsAsRead,
    canLoadMore,
    loadMore,
    filters,
    setFilters,
    removeNotifications,
    deactivateNotificationsType,
  };
};

export default useNotificationPanel;
