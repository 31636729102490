import { useEffect, useState } from "react";
import {
  ToggleButtonsProps,
  ToogleButtonsValue,
} from "@trace-one/design-system";
import { isListEmpty } from "src/utils/general";
import { APPLICATIONS } from "../../../NotificationsPanel/constants";

const useApplicationToggleButtons = ({
  applicationTypeIds,
}: {
  applicationTypeIds?: number[];
}) => {
  const [applicationToggleButtons, setApplicationToggleButtons] = useState<
    ToggleButtonsProps[]
  >([]);
  const [selectedApplicationType, setSelectedApplicationType] =
    useState<ToogleButtonsValue>(null);

  useEffect(() => {
    if (!isListEmpty(applicationToggleButtons)) {
      setSelectedApplicationType(applicationToggleButtons[0].value);
    }
  }, [applicationToggleButtons]);

  const getApplicationsType = () => {
    if (!isListEmpty(applicationTypeIds)) {
      setApplicationToggleButtons(
        APPLICATIONS.filter(({ id }) => applicationTypeIds.includes(id))
      );
    }
  };

  const onChangeApplicationToggle = (value: ToogleButtonsValue) => {
    setSelectedApplicationType(value);
  };

  useEffect(() => {
    getApplicationsType();
  }, [applicationTypeIds]);

  return {
    applicationToggleButtons,
    selectedApplicationType,
    onChangeApplicationToggle,
  };
};

export default useApplicationToggleButtons;
