import { useEffect, useRef, useState } from "react";
export const ALL_FILTER_MODAL_ROOT = "allFiltersModalRoot";

const useAllFiltersPanelOpen = (history: any, onAllFilterPanelToggle: any) => {
  const nodeRef = useRef<null | HTMLElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [isNodeMounted, setIsNodeMounted] = useState<boolean>(false);

  useEffect(() => {
    onAllFilterPanelToggle(open);
  }, [open]);

  useEffect(() => {
    history.listen(() => {
      setOpen(false);
    });
  }, [history]);

  useEffect(() => {
    const interval = window.setInterval(() => {
      const node = document.getElementById(ALL_FILTER_MODAL_ROOT);
      if (node) {
        window.clearInterval(interval);
        nodeRef.current = node;
        setIsNodeMounted(true);
      }
    }, 500);

    return () => {
      window.clearInterval(interval);
    };
  }, []);

  const toggleOpen = () => setOpen(isOpen => !isOpen);

  return {
    isNodeMounted,
    open,
    setOpen,
    nodeRef,
    toggleOpen,
  };
};

export default useAllFiltersPanelOpen;
