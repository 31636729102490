import { useEffect, useMemo } from "react";
import {
  NotificationDigestURLParamKeys,
  NotificationDigestURLState,
} from "../../types";
import { APPLICATIONS_MAP_BY_ID } from "../../constants";

const extractNotifPanelRelatedQueryParams = (): NotificationDigestURLState => {
  const notificationRelatedParams: NotificationDigestURLParamKeys[] = [
    "notificationpanel",
    "senderzone",
    "startdate",
    "enddate",
    "notificationtype",
  ];
  const searchParams = new URLSearchParams(window.location.search);
  const notificationPanelParams = [...searchParams.entries()].filter(([k]) =>
    notificationRelatedParams.includes(k as NotificationDigestURLParamKeys)
  ) as [NotificationDigestURLParamKeys, string][];

  const digestURLParamsMap = new Map(notificationPanelParams);
  const senderZone =
    APPLICATIONS_MAP_BY_ID.get(Number(digestURLParamsMap.get("senderzone")))
      ?.value || "";

  const digestURLState = {
    openNotificationPanel: digestURLParamsMap.get("notificationpanel") === "1",
    senderZone,
    startDate: digestURLParamsMap.get("startdate"),
    endDate: digestURLParamsMap.get("enddate"),
    notificationTypeId: digestURLParamsMap.get("notificationtype"),
  };

  return digestURLState;
};

const useNotificationDigestParams = ({
  setOpen,
}: {
  setOpen: (open: boolean) => void;
}) => {
  const digestURLState = useMemo(extractNotifPanelRelatedQueryParams, []);

  useEffect(() => {
    if (digestURLState.openNotificationPanel) {
      setOpen(true);
    }
  }, []);

  return digestURLState;
};

export default useNotificationDigestParams;
