/* istanbul ignore file */

import { useContext } from "react";
import { LanguagePreferenceContext } from "src/components/LanguagePreferenceProvider/contexts";

const useLanguagePreference = () => {
  const languagePreference = useContext(LanguagePreferenceContext);

  return languagePreference;
};

export default useLanguagePreference;
