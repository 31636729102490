import React, { useState, useEffect, useRef } from "react";

import { Picker, Typography } from "@trace-one/design-system";
import { useDebounce } from "src/hooks";

const AsyncPickerDropdown = ({
  defaultOptions,
  onAsyncSearch,
  onSearch,
  minLengthToSearch = 3,
  ...rest
}) => {
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const debouncedSearchValue = useDebounce(searchValue, 300);
  const optionsRef = useRef([]);
  const shouldDisplayDefaultOptionsNow =
    !searchValue && Array.isArray(defaultOptions);
  const hasEnoughTextLength = (text: string) => {
    return text?.trim()?.length >= minLengthToSearch;
  };

  useEffect(() => {
    // Race condition secure
    if (!onAsyncSearch) {
      return;
    }

    let mount = true;
    if (hasEnoughTextLength(debouncedSearchValue)) {
      onAsyncSearch(debouncedSearchValue)
        .then(options => {
          const isOptionsListArray = Array.isArray(options);
          if (!isOptionsListArray) {
            console.warn(
              "[AsyncSearchSelect] onAsyncSearch type ({ searchValue }) => Promise<{ label, value }[]>"
            );
          }
          if (mount && isOptionsListArray) {
            setFilteredOptions(options);
            optionsRef.current = options;
          }
          setLoading(false);
        })
        .catch(error => {
          if (mount) {
            console.log(error);
            setFilteredOptions([]);
            optionsRef.current = [];
          }
          setLoading(false);
        });
    }
    return () => {
      mount = false;
      optionsRef.current = [];
    };
  }, [debouncedSearchValue]);

  return (
    <Picker.Filter
      renderAlternateContent={
        !!searchValue && !hasEnoughTextLength(searchValue)
          ? (
          <div>
            <Typography component="span">Atleast type 3 charcters</Typography>
          </div>
            )
          : loading
            ? (
          <div>Loading..</div>
              )
            : !(shouldDisplayDefaultOptionsNow ? defaultOptions : filteredOptions)
                .length
                ? (
          <div>
            <Typography component="h6">No Data</Typography>
          </div>
                  )
                : undefined
      }
      onSearch={str => {
        setSearchValue(str);
        if (onAsyncSearch) {
          if (!str) {
            setLoading(false);
            return;
          }
          if (hasEnoughTextLength(str)) {
            setLoading(true);
          }

          // Need to memorize ref of options if user type in fast value that will not trigger useEffect
          // Clearing options in cleanup will create vanish effect
          if (str === debouncedSearchValue) {
            setFilteredOptions(optionsRef.current);
            setLoading(false);
          } else {
            setFilteredOptions([]);
          }
          return;
        }
        setFilteredOptions(onSearch(str));
      }}
      items={
        shouldDisplayDefaultOptionsNow
          ? defaultOptions ?? filteredOptions
          : filteredOptions
      }
      {...rest}
    />
  );
};

export default AsyncPickerDropdown;
