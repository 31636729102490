import React from "react";
import { InputNumber } from "@trace-one/design-system";
import { InputUnitProps } from "./types";
import { useCreatePackagingContext } from "../../context";

const InputUnit: React.FC<InputUnitProps> = ({
  previousValues,
  precision,
  step,
  options,
  dataTestId,
  name,
  index,
  component,
}) => {
  const { updateUnitId, updateUnitValue } = useCreatePackagingContext();

  const onChangeUnit = value => {
    if (value?.key === "clearItemSelected") {
      updateUnitId("", name, index);
    } else {
      const unitItem = options.find(unit => unit.name === value.key);
      updateUnitId(unitItem?.value, name, index);
    }
  };

  const getTypeOfComponentUnit = type => {
    const unitName = options.find(item => item.value === type);
    return unitName ? unitName?.name : "";
  };

  const unitOptions = options.map(({ name }) => ({
    label: name,
    onClick: value => {
      onChangeUnit(value);
    },
  }));

  const handleInputChange = value => {
    updateUnitValue(value, name, index);
  };

  return (
    <div className="input-unit-wrapper">
      <InputNumber.Dual
        name={name}
        value={previousValues?.value}
        items={unitOptions}
        onChange={value => {
          handleInputChange(value);
        }}
        onClearButton={() => {
          const clearValue = { key: "clearItemSelected" };
          onChangeUnit(clearValue);
        }}
        buttonValue={getTypeOfComponentUnit(component[name]?.unitId)}
        precision={precision}
        step={step}
        data-test-id={dataTestId}
      />
    </div>
  );
};

export default InputUnit;
