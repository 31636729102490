import React from "react";
import { Spinner, Button } from "@trace-one/design-system";
import Remove from "./components/Remove";
import { ActionsProps } from "./types";
const Actions: React.FC<ActionsProps> = ({
  isUploading,
  onRemove,
  hasError,
  fileId,
  itemPrefixCls,
  onDownload,
  showFileDownload,
  showRemoveTooltip,
  messages,
}) => {
  if (isUploading) {
    return <Spinner size="small" />;
  }

  return (
    <div className={`${itemPrefixCls}-fileDownloadAction`}>
      {showFileDownload && (
        <Button
          data-test-id="download-files-item"
          className={`${itemPrefixCls}-downloadIcon`}
          iconName="download"
          iconOnly={true}
          size="large"
          type="tertiary"
          onClick={onDownload}
        />
      )}
      {onRemove && (
        <Remove
          itemPrefixCls={itemPrefixCls}
          hasError={hasError}
          onRemove={onRemove}
          fileId={fileId}
          showRemoveTooltip={showRemoveTooltip}
          messages={messages}
        />
      )}
    </div>
  );
};

export default Actions;
