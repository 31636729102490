import React from "react";
import { useIntl } from "react-intl";
import { Button, Input } from "@trace-one/design-system";
import { Form } from "antd";
import { UserEmailProps } from "./types";
import { settingsContentMessages } from "../../../../../translations/messages";
import useUserEmail from "src/hooks/useUserEmail";

const Email: React.FC<UserEmailProps> = ({ user }) => {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();

  const {
    login,
    updateLogin,
    confirmationLogin,
    updateConfirmationLogin,
    updateLoginEmail,
  } = useUserEmail({
    userId: user.userId,
    userLogin: user.userLogin,
  });

  return (
    <Form form={form} layout="vertical">
      <div
        className="content-my-settings-details-container-email"
        data-test-id="user-profile-content-my-settings-login-container"
      >
        <Form.Item
          name="login"
          label={formatMessage(settingsContentMessages.login)}
          data-test-id="user-profile-content-my-settings-login"
        >
          <div
            className="content-my-settings-email-select"
            data-test-id="user-profile-content-my-settings-login-select"
          >
            <Input
              placeholder={formatMessage(settingsContentMessages.login)}
              onChange={e => {
                updateLogin(e.target.value);
              }}
              value={login}
            />
          </div>
        </Form.Item>
        <Form.Item
          name="confirmationLogin"
          label={formatMessage(settingsContentMessages.confirmationLogin)}
          required={false}
          rules={[
            {
              required: true,
              message: formatMessage(
                settingsContentMessages.loginConfirmationErrorMessage
              ),
            },
            ({ getFieldValue }) => ({
              validator (_, value) {
                if (!value || getFieldValue("login") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(
                    formatMessage(
                      settingsContentMessages.loginConfirmationErrorMessage
                    )
                  )
                );
              },
            }),
          ]}
          data-test-id="user-profile-content-my-settings-confirmationLogin"
        >
          <div
            className="content-my-settings-email-select"
            data-test-id="user-profile-content-my-settings-confirmationLogin-select"
          >
            <Input
              placeholder={formatMessage(
                settingsContentMessages.confirmationLogin
              )}
              onChange={e => updateConfirmationLogin(e.target.value)}
              value={confirmationLogin}
              disabled={login === user.userLogin}
            />
          </div>
        </Form.Item>
        <Button
          htmlType="submit"
          onClick={updateLoginEmail}
          disabled={login === user.userLogin}
          data-test-id="user-profile-content-my-settings-save-btn"
        >
          {formatMessage(settingsContentMessages.save)}
        </Button>
      </div>
    </Form>
  );
};

export default Email;
