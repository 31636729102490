import { Dictionary } from "../../../models";
import { PackagingComponentData } from "../types";

export const optionsByRefList = array =>
  Array.isArray(array)
    ? array.map(({ id, text }) => ({ name: text, value: id, title: "" }))
    : [];

export const getVersionName = (version: number): string => `V${version}`;

export const getDictionary = ({
  data = [],
  key,
  value,
}: {
  data: object[];
  key: string;
  value: string;
}): Dictionary => {
  let temporaryDictionary = {};

  data.forEach(item => {
    if (item.hasOwnProperty(key) && item.hasOwnProperty(value))
      temporaryDictionary[item[key]] = item[value];
  });

  return temporaryDictionary;
};

export const getSkipParamForApi = ({
  isNewSearch,
  currentPage,
  pageSize,
}: {
  isNewSearch: boolean;
  currentPage: number;
  pageSize: number;
}) => (isNewSearch ? 0 : (currentPage - 1) * pageSize);

export const setComponentData = (component: PackagingComponentData) => {
  const {
    componentId,
    componentTypeId,
    diameter = null,
    height = null,
    length = null,
    name,
    printableComponent,
    usageLevel,
    weight = null,
    width = null,
  } = component;

  return {
    componentId,
    componentTypeId,
    diameter,
    height,
    length,
    name,
    printableComponent,
    usageLevel,
    weight,
    width,
  };
};
