import axios, { AxiosError } from "axios";
import { ErrorDetail } from "src/types";
import { ERROR_STATUSES } from "src/utils/constants";
import { isErrorWithCode, isObjectEmpty } from "src/utils/general";

export const shouldNotificationErrorBeDisplayed = ({
  error,
  errorCodesToSkip = [],
  errorStatusesToSkip = [],
  errorDetail,
}: {
  error: AxiosError;
  errorCodesToSkip: string[];
  errorStatusesToSkip: number[];
  errorDetail?: ErrorDetail;
}) => {
  if (axios.isCancel(error)) {
    return false;
  }

  if (error?.response?.status === ERROR_STATUSES.PAYLOAD_TOO_LARGE) {
    return false;
  }

  if (isErrorWithCode(error?.response?.data)) {
    if (errorCodesToSkip.includes(error?.response?.data?.errorCode)) {
      return false;
    }
  }

  if (!isObjectEmpty(errorDetail)) {
    if (
      errorCodesToSkip.includes(errorDetail?.code || errorDetail?.legacyCode)
    ) {
      return false;
    }
  }

  if (errorStatusesToSkip.includes(error?.response?.status)) {
    return false;
  }

  return true;
};
