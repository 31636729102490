import { UserApplication } from "src/models";
import { APPLICATIONS } from "src/utils/constants";

export const isApplicationHidden = ({
  applicationTypeId,
  excludeApps = [],
}: {
  applicationTypeId: UserApplication["applicationTypeId"];
  excludeApps?: number[];
}) => {
  return (
    APPLICATIONS.findIndex(app => app.id === applicationTypeId) === -1 ||
    excludeApps.findIndex(excludedApp => excludedApp === applicationTypeId) !==
      -1
  );
};

export const getAppsToDisplay = ({
  apps = [],
  excludeApps = [],
  includeApps = [],
}: {
  apps: UserApplication[];
  excludeApps?: number[];
  includeApps: UserApplication[];
}) => {
  const appsToDisplay = apps.filter(
    ({ applicationTypeId }) =>
      !isApplicationHidden({ applicationTypeId, excludeApps })
  );

  for (let app of includeApps) {
    const { applicationTypeId } = app;
    if (
      !appsToDisplay.some(item => item.applicationTypeId === applicationTypeId)
    ) {
      appsToDisplay.push(app);
    }
  }

  return appsToDisplay;
};
