import React, { useEffect, useRef, useState } from "react";
import { Graphic, Paragraph, Tooltip, Heading } from "@trace-one/design-system";
import classnames from "classnames";
import { withLanguageData } from "src/hocs";
import { useItem } from "./hooks";
import Actions from "./components/Actions";
import { ItemProps } from "./types";

import enUs from "../../translations/en-US.json";
import frFr from "../../translations/fr-FR.json";
import { LangList } from "../../../../../utils/const";
import UploadedFileDescription from "./components/Description";

const DEFAULT_LANG = enUs;

const enhance = withLanguageData({
  translations: {
    [LangList.EN_US]: enUs,
    [LangList.FR_FR]: { ...DEFAULT_LANG, ...frFr },
  },
});

const Item: React.FC<ItemProps> = ({
  fileId,
  fileName,
  fileSize,
  errorMessage: initialErrorMessage,
  status: initialStatus,
  size = "large",
  onRemove,
  getTooltipContainer,
  fileUploadedUserName,
  fileUploadedDate,
  fileUploadedTime,
  showResponsibility,
  showFileSize,
  showUploadTime,
  showUploadUserName,
  showUploadDate,
  showFileDescription,
  showFileDownload = true,
  showRemoveTooltip = true,
  messages,
}) => {
  const { errorMessage, hasError, isUploading, onDownload } = useItem({
    initialStatus,
    initialErrorMessage,
    fileId,
    fileName,
    messages,
  });

  const headingRef = useRef(null);
  const [hasEllipsis, setHasEllipsis] = useState(false);

  useEffect(() => {
    const headingElement = headingRef.current;
    if (headingElement) {
      setHasEllipsis(headingElement.scrollWidth > headingElement.clientWidth);
    }
  }, [fileName]);

  const itemPrefixCls = "business-components-upload-files-item";
  const infoPrefixCls = `${itemPrefixCls}-info`;

  return (
    <>
      <div>
        <div
          className={classnames(itemPrefixCls, `${itemPrefixCls}-${size}`, {
            "has-error": hasError,
          })}
          data-test-id="upload-files-item"
        >
          <div className={`${itemPrefixCls}-section`}>
            <div className={`${itemPrefixCls}-description`}>
              <div
                className={`${itemPrefixCls}-icon`}
                data-test-id="upload-files-item-icon"
              >
                <Graphic name="document" />
              </div>
              <div
                className={classnames(
                  `${infoPrefixCls}`,
                  `${infoPrefixCls}-${size}`
                )}
              >
                {hasEllipsis
                  ? (
                  <Tooltip
                    getPopupContainer={
                      getTooltipContainer &&
                      (triggerNode => getTooltipContainer(triggerNode))
                    }
                    placement="bottomLeft"
                    text={fileName}
                  >
                    <Heading
                      size="xxs"
                      className={`${infoPrefixCls}-name`}
                      data-test-id="upload-files-item-name"
                      ref={headingRef}
                    >
                      {fileName}
                    </Heading>
                  </Tooltip>
                    )
                  : (
                  <Heading
                    size="xxs"
                    className={`${infoPrefixCls}-name`}
                    data-test-id="upload-files-item-name"
                    ref={headingRef}
                  >
                    {fileName}
                  </Heading>
                    )}
              </div>
            </div>
            <Actions
              isUploading={isUploading}
              onRemove={onRemove}
              hasError={hasError}
              itemPrefixCls={itemPrefixCls}
              fileId={fileId}
              onDownload={onDownload}
              showFileDownload={showFileDownload}
              showRemoveTooltip={showRemoveTooltip}
              messages={messages}
            />
          </div>
        </div>
        {hasError && errorMessage && (
          <Paragraph color="red" data-test-id="upload-files-item-error-message">
            {errorMessage}
          </Paragraph>
        )}
      </div>
      {showFileDescription && (
        <UploadedFileDescription
          fileUploadedDate={fileUploadedDate}
          fileUploadedTime={fileUploadedTime}
          fileUploadedUserName={fileUploadedUserName}
          fileSize={fileSize}
          size={size}
          showResponsibility={showResponsibility}
          showFileSize={showFileSize}
          showUploadTime={showUploadTime}
          showUploadUserName={showUploadUserName}
          showUploadDate={showUploadDate}
        />
      )}
    </>
  );
};
export default enhance(Item);
