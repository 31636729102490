import React from "react";
import { Badge } from "@trace-one/design-system";
import "../../../styles.less";
import { TabProps } from "src/components/NotificationsPanel/types";
import TabWithOutBadge from "./TabWithOutBadge";

const Tab: React.FC<TabProps> = ({ unreadCount = 0, ...otherProps }) => {
  return (
    <div
      className="bsc-notifications-tabs-tab-wrapper"
      data-test-id="notifications-application-tab"
    >
      {unreadCount > 0
        ? (
        <Badge color="red-dark" dot placement="topRight">
          <TabWithOutBadge {...otherProps} />
        </Badge>
          )
        : (
        <TabWithOutBadge {...otherProps} />
          )}
    </div>
  );
};

export default Tab;
