import { APPLICATIONS as apps } from "src/utils/constants";

export const NotificationApps = [
  "Project",
  "QMS",
  "Spec",
  "Ton",
  "MD",
  "Rfx",
  "Administration",
  "Quality Portal",
];

export const APPLICATIONS = apps.reduce((acc, curr) => {
  if (NotificationApps.includes(curr.value)) {
    const { graphic, ...rest } = curr;
    acc.push({ ...rest });
  }

  return acc;
}, []);

export const APPLICATIONS_MAP_BY_ID = new Map(APPLICATIONS.map(x => [x.id, x]));
