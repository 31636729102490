import React, { useRef } from "react";
import { Modal } from "@trace-one/design-system";
import { withLanguageData } from "IndexComponents";
import { AskAccessModalContextProvider } from "./context/AskAccessModalContext";
import { Form } from "./components/Form/";
import { useLanguagePreference, useModalVisibility } from "src/hooks";
import { FormattedMessage } from "react-intl";
import messages from "./translations/messages";
import { LangList } from "../../../utils/const";
import { AskAccessModalProps } from "./types";

import enUs from "./translations/en-US.json";
import frFr from "./translations/fr-FR.json";
import deDe from "./translations/de-DE.json";
import nlBe from "./translations/nl-BE.json";
import esEs from "./translations/es-ES.json";
import itIt from "./translations/it-IT.json";
import ptPt from "./translations/pt-PT.json";

const enhance = withLanguageData({
  translations: {
    [LangList.EN_US]: enUs,
    [LangList.FR_FR]: frFr,
    [LangList.DE_DE]: deDe,
    [LangList.NL_BE]: nlBe,
    [LangList.ES_ES]: esEs,
    [LangList.IT_IT]: itIt,
    [LangList.PT_PT]: ptPt,
  },
});

export const AskAccessModal: React.FC<AskAccessModalProps> = ({
  onClose,
  userId,
  open,
}) => {
  const languageCode = useLanguagePreference();
  const formRef = useRef(null);
  const { onCloseModal, isModalOpen } = useModalVisibility({
    defaultVisibility: open,
    onClose,
  });

  return (
    <AskAccessModalContextProvider languageCode={languageCode}>
      <Modal.Simple
        title={<FormattedMessage {...messages.title} />}
        open={isModalOpen}
        onCancel={onCloseModal}
        size="s"
        primaryButtonText={<FormattedMessage {...messages.send} />}
        onPrimaryButtonClick={() => formRef.current && formRef.current.submit()}
      >
        <Form
          langcode={languageCode}
          onClose={onCloseModal}
          userId={userId}
          formRef={formRef}
        />
      </Modal.Simple>
    </AskAccessModalContextProvider>
  );
};

export default enhance(AskAccessModal);
