/* istanbul ignore file */

import React from "react";
import { IntercomProvider } from "react-use-intercom";

const IntercomInstanceProvider = ({
  children,
  intercomAppId,
  autoBoot = true,
}) => {
  return (
    <IntercomProvider appId={intercomAppId} autoBoot={autoBoot}>
      {children}
    </IntercomProvider>
  );
};

export const withIntercomInstanceProvider = WrappedComponent => {
  const Component = props => {
    const { intercomAppId, autoBoot = true, ...rest } = props;

    return (
      <IntercomInstanceProvider
        intercomAppId={intercomAppId}
        autoBoot={autoBoot}
      >
        <WrappedComponent {...rest} />
      </IntercomInstanceProvider>
    );
  };

  return Component;
};

export default IntercomInstanceProvider;
