import React from "react";
import { Col, Row } from "antd";
import Item from "../Item";
import Dropdown from "./components/Dropdown";
import { ListProps } from "./types";
const List: React.FC<ListProps> = ({
  fileList,
  onRemove,
  size = "large",
  showResponsibility,
  showFileSize,
  showUploadTime,
  showUploadUserName,
  showUploadDate,
  showFileDescription,
  showDropdown,
  messages,
}) => {
  return (
    <div
      className="business-components-upload-files-list"
      data-test-id="business-components-list-of-upload-files"
    >
      {fileList.map(file => (
        <div
          className="business-components-upload-files-list-items"
          key={file.uid || file.id}
        >
          <Row gutter={[8, 0]}>
            <Col xs={showDropdown ? 16 : 24}>
              <Item
                errorMessage={file.errorMessage}
                fileId={file.id}
                fileName={file.name}
                fileSize={file?.size}
                status={file.status}
                size={showDropdown ? "large" : size}
                fileUploadedUserName={file?.fileUploadedUserName}
                fileUploadedTime={file?.fileUploadedTime}
                fileUploadedDate={file?.fileUploadedDate}
                showResponsibility={showResponsibility}
                showFileSize={showFileSize}
                showUploadTime={showUploadTime}
                showUploadUserName={showUploadUserName}
                showFileDescription={showFileDescription}
                showUploadDate={showUploadDate}
                showRemoveTooltip={file.showRemoveTooltip}
                messages={messages}
                {...(typeof onRemove === "function" && {
                  onRemove: () => {
                    onRemove(file);
                  },
                })}
              />
            </Col>
            {showDropdown && (
              <Col xs={8}>
                <Dropdown
                  fileId={file.id || file.uid}
                  {...file.customDropdownProp}
                />
              </Col>
            )}
          </Row>
        </div>
      ))}
    </div>
  );
};
export default List;
