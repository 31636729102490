import React from "react";
import Toolbar from "../Toolbar";
import Tabs from "../Tabs";
import Content from "../Content";

import "../../styles.less";

import useNotificationPanel from "../../hooks/useNotificationPanel";
import useNotificationsFilter from "../../hooks/useNotificationsFilter";
import NotficationFilters from "../NotficationFilters";
import useTabs from "../../hooks/useTabs";
import { NotificationsSidebarProps } from "../../types";

const NotificationsSidebar: React.FC<NotificationsSidebarProps> = ({
  isOpen,
  pathNotificationsSettings,
  unreadNotifications,
  countsBySenderZones,
  refreshCount,
  digestURLState,
}) => {
  const {
    selectedTab,
    setSelectedTab,
    toggleNotificationsReadStatus,
    markAllNotificationsAsRead,
    isLoading,
    notifications,
    canLoadMore,
    loadMore,
    setFilters,
    filters,
    removeNotifications,
    deactivateNotificationsType,
  } = useNotificationPanel({ isOpen, refreshCount, digestURLState });
  const {
    isFiltersVisible,
    toggleFiltersVisibility,
    notificationTypes,
    filtersToDisplay,
    removeFilterByTag,
  } = useNotificationsFilter(filters, setFilters, selectedTab);
  const apps = useTabs();

  const cancelPropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <div
      onClick={cancelPropagation}
      className={`notifications-sidebar notifications-sidebar-${
        isOpen ? "open" : "close"
      }`}
    >
      {isFiltersVisible
        ? (
        <NotficationFilters
          setFilters={setFilters}
          toggleFilters={toggleFiltersVisibility}
          notificationTypes={notificationTypes}
          filters={filters}
          selectedTab={selectedTab}
        />
          )
        : (
        <>
          <Toolbar
            toggleFilters={toggleFiltersVisibility}
            selectedTab={selectedTab}
            tools={true}
            pathNotificationsSettings={pathNotificationsSettings}
            countsBySenderZones={countsBySenderZones}
            unreadNotificationsCount={unreadNotifications}
            markAllNotificationsAsRead={markAllNotificationsAsRead}
          />
          <section className="notifications-panel-content-wrapper">
            <Tabs
              apps={apps}
              selected={selectedTab}
              countsBySenderZones={countsBySenderZones}
              setSelectedTab={setSelectedTab}
            />
            <Content
              notifications={notifications}
              unreadNotificationsCount={unreadNotifications}
              loadMoreNotifs={loadMore}
              hasMoreData={canLoadMore}
              isLoading={isLoading}
              toggleNotificationsReadStatus={toggleNotificationsReadStatus}
              filtersToDisplay={filtersToDisplay}
              removeFilterByTag={removeFilterByTag}
              removeNotifications={removeNotifications}
              deactivateNotificationsType={deactivateNotificationsType}
            />
          </section>
        </>
          )}
    </div>
  );
};

export default NotificationsSidebar;
