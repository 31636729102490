import { defineMessages } from "react-intl";

export const searchFilterActions = defineMessages({
  allFiltersHeaderTitle: {
    id: "searchfilter.allFilters.header.title",
    defaultMessage: "All filters",
  },
  allFiltersHeaderDescription: {
    id: "searchfilter.allFilters.header.description",
    defaultMessage: "Here you can manage every filter related to this page.",
  },
  allFiltersActionClear: {
    id: "searchfilter.allFilters.actions.clear",
    defaultMessage: "CLEAR SELECTION",
  },
  allFiltersActionValidate: {
    id: "searchfilter.allFilters.actions.validate",
    defaultMessage: "VALIDATE",
  },
  allFiltersActionApply: {
    id: "searchfilter.allFilters.actions.apply",
    defaultMessage: "APPLY",
  },
  allFiltersActionBack: {
    id: "searchfilter.allFilters.actions.back",
    defaultMessage: "BACK",
  },
  allFiltersActionClearSelectionMultiselect: {
    id: "searchfilter.allFilters.actions.multiselectionClear",
    defaultMessage: "Clear selection",
  },
  actionClearAll: {
    id: "searchfilter.actions.clearAll",
    defaultMessage: "Clear all",
  },
});
