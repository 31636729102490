import React, { useContext } from "react";
import { useIntercom } from "react-use-intercom";
import { withIntercomInstanceProvider } from "src/components/IntercomInstanceProvider";
import { AppsListContext } from "../../contexts";
import AppItem from "../AppItem";
import { IntercomAppsListProps } from "./types";

const IntercomAppsList: React.FC<IntercomAppsListProps> = ({
  handleCreationPopUp,
  variant,
}) => {
  const { trackEvent } = useIntercom();
  const { appList, userId } = useContext(AppsListContext);

  const sendEnteredAppEvent = applicationName => {
    trackEvent("Entered-" + applicationName, {
      created_at: Date.now(),
      user_id: userId,
    });
  };

  return (
    <>
      {appList.map(appItem => (
        <AppItem
          key={appItem.applicationTypeId}
          appItem={appItem}
          sendEnteredAppEvent={sendEnteredAppEvent}
          handleCreationPopUp={handleCreationPopUp}
          variant={variant}
        />
      ))}
    </>
  );
};

export default withIntercomInstanceProvider(IntercomAppsList);
