import { useState } from "react";

const useRemove = ({
  onDelete,
  cancelText,
  confirmText,
}: {
  onDelete?: Function;
  cancelText?: string;
  confirmText?: string;
}) => {
  const [tooltipVisibility, setTooltipVisibility] = useState({});

  const onOpenChange = (index: number, open: boolean) => {
    setTooltipVisibility(prev => ({
      ...prev,
      [index]: open,
    }));
  };

  const onRemoveClick = (index: number) => {
    onOpenChange(index, true);
  };

  const closeTooltip = (index: number) => {
    onOpenChange(index, false);
  };

  const onYesClick = (index: number) => {
    onDelete(index);
    closeTooltip(index);
  };

  const onNoClick = (index: number) => {
    closeTooltip(index);
  };

  function createActions(index: number) {
    return [
      {
        text: cancelText,
        onClick: () => onNoClick(index),
      },
      {
        text: confirmText,
        onClick: () => onYesClick(index),
      },
    ];
  }

  const isTooltipVisible = (index: number) => {
    return tooltipVisibility[index] || false;
  };

  return {
    isTooltipVisible,
    onOpenChange,
    onRemoveClick,
    createActions,
  };
};

export default useRemove;
