import { useEffect, useState } from "react";

const useScrollHide = (disabled: boolean = false) => {
  const [open, setOpen] = useState(false);
  const hideUserMenu = () => {
    if (!disabled) {
      setOpen(false);
    }
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  useEffect(() => {
    window.addEventListener("scroll", hideUserMenu);

    return () => {
      window.removeEventListener("scroll", hideUserMenu);
    };
  }, []);

  return {
    open,
    handleOpenChange,
    hideUserMenu,
  };
};

export default useScrollHide;
