import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { Paragraph, Tooltip } from "@trace-one/design-system";
import Info from "../Info";
import { DescriptionProps } from "./types";
import { Divider } from "antd";

const UploadedFileDescription: React.FC<DescriptionProps> = ({
  fileUploadedDate,
  fileUploadedTime,
  fileUploadedUserName,
  fileSize,
  size,
  showResponsibility,
  showFileSize,
  showUploadTime,
  showUploadUserName,
  showUploadDate,
}) => {
  const uploadedByRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    const uploadedByElement = uploadedByRef.current;

    if (uploadedByElement) {
      setShowTooltip(doesTextOverflow(uploadedByElement));
    }
  }, []);

  const doesTextOverflow = element => {
    return element?.scrollWidth > element?.clientWidth;
  };

  const renderTooltip = () => {
    return (
      <React.Fragment>
        {showTooltip
          ? (
          <Tooltip placement="bottomLeft" text={fileUploadedUserName}>
            <span>{fileUploadedUserName}</span>
          </Tooltip>
            )
          : (
          <span>{fileUploadedUserName}</span>
            )}
      </React.Fragment>
    );
  };

  const itemPrefixCls = "business-components-upload-files-item";
  const infoPrefixCls = `${itemPrefixCls}-info`;

  const descriptionElements = [];

  if (fileUploadedUserName?.length && showUploadUserName) {
    descriptionElements.push(
      <Paragraph
        className={`${itemPrefixCls}-uploadedby`}
        size="xs"
        color="grey-4"
        ref={uploadedByRef}
        key="uploadedby"
      >
        {renderTooltip()}
      </Paragraph>
    );
  }

  if (showResponsibility) {
    descriptionElements.push(
      <>
        <Paragraph
          className={`${itemPrefixCls}-responsibility`}
          size="xs"
          color="grey-4"
        >
          <Tooltip placement="bottomLeft" text="Responsibility">
            Responsibility
          </Tooltip>
        </Paragraph>
      </>
    );
  }

  if (showUploadDate || showUploadTime) {
    const datedescriptionElements = [];
    if (fileUploadedDate?.length && showUploadDate) {
      datedescriptionElements.push(
        <Paragraph
          key="date"
          className={`${itemPrefixCls}-uploadedDate`}
          size="xs"
          color="grey-4"
        >
          {fileUploadedDate}
        </Paragraph>
      );
    }
    if (fileUploadedTime?.length && showUploadTime) {
      datedescriptionElements.push(
        <Paragraph
          key="time"
          className={`${itemPrefixCls}-uploadedTime`}
          size="xs"
          color="grey-4"
        >
          {fileUploadedTime}
        </Paragraph>
      );
    }

    if (datedescriptionElements.length > 0) {
      descriptionElements.push(
        <div
          key="dateTime"
          className={classnames(`${itemPrefixCls}-uploadedDateTime`)}
        >
          {datedescriptionElements}
        </div>
      );
    }
  }

  if (fileSize >= 0 && showFileSize) {
    descriptionElements.push(
      <Info key="info" infoPrefixCls={infoPrefixCls} fileSize={fileSize} />
    );
  }

  return (
    <div
      className={classnames(
        `${itemPrefixCls}-fileDescription`,
        `${itemPrefixCls}-${size}`
      )}
    >
      {descriptionElements.map((element, index) => (
        <>
          {element}
          {index < descriptionElements.length - 1 && (
            <Divider type="vertical" className={`${itemPrefixCls}-divider`} />
          )}
        </>
      ))}
    </div>
  );
};

export default UploadedFileDescription;
