import { useEffect, useState } from "react";

const useModalVisibility = (props?: {
  defaultVisibility?: boolean;
  onClose?: Function;
}) => {
  const { defaultVisibility = false, onClose } = props || {};
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onOpenModal = () => {
    setIsModalOpen(true);
  };

  const onCloseModal = () => {
    if (typeof onClose === "function") {
      onClose();
    }
    setIsModalOpen(false);
  };

  useEffect(() => {
    setIsModalOpen(defaultVisibility);
  }, [defaultVisibility]);

  return {
    isModalOpen,
    onOpenModal,
    onCloseModal,
  };
};

export default useModalVisibility;
