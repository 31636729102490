import React from "react";
import { CommunicationMethodToggleProps } from "./types";
import { Toggle } from "@trace-one/design-system";

export const CommunicationMethodToggle: React.FC<
  CommunicationMethodToggleProps
> = ({ settingsItem, notificationType, statusForDigest, onChange }) => {
  return (
    <Toggle
      checked={
        settingsItem.isDigestActive
          ? statusForDigest
          : settingsItem.communicationMethods.includes(notificationType)
      }
      disabled={
        settingsItem.isDigestActive ||
        !settingsItem.notificationTypeDefaultCommunicationMethods.includes(
          notificationType
        )
      }
      size="small"
      onClick={onChange({
        id: settingsItem.notificationTypeId,
        type: notificationType,
      })}
    />
  );
};

export default CommunicationMethodToggle;
