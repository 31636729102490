import React from "react";
import UserInfo from "./components/UserInfo";
import Menu from "./components/Menu";
import LogOut from "./components/LogOut";
import { SidebarProps } from "./types";

const SideBar: React.FC<SidebarProps> = ({
  user,
  onLogout,
  selectedTile,
  setSelectedTile,
}) => {
  return (
    <div className="side-bar" data-test-id="user-profile-page-sidebar">
      <UserInfo user={user} />
      <Menu
        user={user}
        selectedTile={selectedTile}
        setSelectedTile={setSelectedTile}
      />
      <LogOut onLogout={onLogout} />
    </div>
  );
};

export default SideBar;
