/* istanbul ignore file */

import { useEffect, useState } from "react";
import * as RLMD from "src/apis/rlmd";
import { ReferenceListItem } from "src/models";
import { REFERENCE_LIST_NAME } from "src/utils/constants";

const useJobTitles = ({ languageCode }: { languageCode: string }) => {
  const [jobTitles, setJobTitles] = useState<ReferenceListItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const getJobTitles = async () => {
    setIsLoading(true);

    try {
      const { data } = await RLMD.getJobTitles({
        languageCode,
        referenceListName: REFERENCE_LIST_NAME.JOB_TITLES,
      });

      setJobTitles(
        data.reduce((previousState, { isItemActive, itemCode, text }) => {
          const jobTitles = [...previousState];
          if (isItemActive) {
            jobTitles.push({
              value: itemCode,
              name: text,
            });
          }
          return jobTitles;
        }, [])
      );
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (languageCode) {
      getJobTitles();
    }
  }, [languageCode]);

  return {
    jobTitles,
    isLoading,
  };
};

export default useJobTitles;
