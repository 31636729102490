import React from "react";
import { getPrefixClassName } from "src/utils/general";
import { BasketWithItemsProps } from "../../types";
import { Button } from "@trace-one/design-system";

const prefixClass = getPrefixClassName("basket");

const BasketWithItems: React.FC<BasketWithItemsProps> = ({
  items,
  onDelete,
  onValidate,
  validateButtonText,
  validateButtonDisabled,
}) => {
  return (
    <div
      data-test-id="bs-basket-with-items"
      className={`${prefixClass}--basket-with-items ds-d-flex ds-flex-column`}
    >
      <div className={`${prefixClass}--basket-items-container`}>
        {items.map(item => {
          return (
            <div
              key={item.id}
              className={`${prefixClass}--basket-item ds-d-flex ds-align-items-center`}
            >
              <div>{item.text}</div>
              <div>
                <Button
                  data-test-id={`bs-basket-item-delete-${item.id}`}
                  onClick={() => onDelete(item)}
                  type="tertiary"
                  size="small"
                  iconName="trash"
                />
              </div>
            </div>
          );
        })}
      </div>
      <div
        className={`${prefixClass}--basket-footer ds-d-flex ds-justify-content-center`}
      >
        <Button
          data-test-id="bs-basket-validate-btn"
          onClick={onValidate}
          type="primary"
          disabled={validateButtonDisabled}
        >
          {validateButtonText}
        </Button>
      </div>
    </div>
  );
};

export default BasketWithItems;
