import React from "react";
import { Button, Heading, Typography, Badge } from "@trace-one/design-system";
import { FilterItemHeaderProps } from "src/components/SearchFilter/types";

const FilterItemHeader: React.FC<FilterItemHeaderProps> = ({
  filter,
  selectedValues,
  openMultiSelectionSideBar,
}) => {
  const isFilterValueSelected = selectedValues?.length > 0;
  const headingSize = "xxs";

  if (filter.controlType === "multi_selection") {
    return (
      <div className="muti-select-header">
        <Heading
          {...(isFilterValueSelected && { color: "primary" })}
          size={headingSize}
          data-test-id={`bs-search-filter-title-${filter.filterId}`}
          className="filter-item__title--centered filter-item__title--clickable"
          onClick={() => openMultiSelectionSideBar(filter)}
        >
          <Typography variant="link-m">{filter.title}</Typography>
          {isFilterValueSelected && (
            <Badge count={selectedValues.length} overflowCount={100} />
          )}
        </Heading>

        <Button
          data-test-id={`bs-search-filter-muti-select-nav-btn-${filter.filterId}`}
          type="tertiary"
          iconName="arrow-right"
          onClick={() => openMultiSelectionSideBar(filter)}
        ></Button>
      </div>
    );
  }

  return (
    <Heading
      data-test-id={`bs-search-filter-title-${filter.filterId}`}
      className="filter-item__title--centered"
      size={headingSize}
      {...(isFilterValueSelected && { color: "primary" })}
    >
      {filter.title}{" "}
      {isFilterValueSelected && (
        <Badge count={selectedValues.length} overflowCount={100} />
      )}
    </Heading>
  );
};

export default FilterItemHeader;
