import { useState } from "react";
import * as CUMD from "src/apis/cumd";
import { toaster } from "@trace-one/design-system";
import { settingsContentMessages } from "../../components/UserProfilePage/translations/messages";
import { useIntl } from "react-intl";

const useUserEmail = ({
  userId,
  userLogin,
}: {
  userId: string;
  userLogin: string;
}) => {
  const { formatMessage } = useIntl();

  const [login, setLogin] = useState<string>(userLogin);
  const [confirmationLogin, setConfirmationLogin] = useState<string>(userLogin);

  const updateLogin = (value: string) => {
    setLogin(value);
  };

  const updateConfirmationLogin = (value: string) => {
    setConfirmationLogin(value);
  };

  const updateLoginEmail = async () => {
    if (login !== confirmationLogin) {
      return;
    }
    try {
      await CUMD.updateUserEmail({
        userId,
        newEmail: login,
      });
      toaster.open({
        message: formatMessage(settingsContentMessages.toasterConfirmation),
        description: formatMessage(
          settingsContentMessages.toasterConfirmationMsg
        ),
        type: "confirmation",
      });
    } catch (error) {
      toaster.open({
        message: formatMessage(settingsContentMessages.toasterAlert),
        description: formatMessage(settingsContentMessages.toasterAlertMsg),
        type: "alert",
      });
    }
  };

  return {
    login,
    updateLogin,
    confirmationLogin,
    updateConfirmationLogin,
    updateLoginEmail,
  };
};

export default useUserEmail;
