import { postToUrl } from "src/utils/general";

const redirectUserToApp = ({
  applicationTypeId,
  getEndpointUrl,
  stsEndpointUrl,
  applicationOwnerId,
  accessToken,
}) => {
  if (getEndpointUrl) {
    window.location.assign(getEndpointUrl);
  } else if (stsEndpointUrl) {
    const params = {
      OwnerId: applicationOwnerId,
      ApplicationType: applicationTypeId,
      identityToken: accessToken,
    };
    postToUrl(stsEndpointUrl, params);
  }
};

export const handleAppClickFactory =
  ({
    applicationTypeId,
    getEndpointUrl,
    stsEndpointUrl,
    applicationOwnerId,
    applicationName,
    sendEnteredAppEvent,
    accessToken,
    handleCreationPopUp,
  }) =>
  () => {
    const params = {
      OwnerId: applicationOwnerId,
      ApplicationType: applicationTypeId,
      identityToken: accessToken,
    };

    if (handleCreationPopUp({ getEndpointUrl, stsEndpointUrl, params })) return;

    if (typeof sendEnteredAppEvent === "function") {
      sendEnteredAppEvent(applicationName);
    }

    redirectUserToApp({
      applicationTypeId,
      getEndpointUrl,
      stsEndpointUrl,
      applicationOwnerId,
      accessToken,
    });
  };
