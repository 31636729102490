import React from "react";
import { FormattedMessage } from "react-intl";
import { Paragraph, Button, Illustration } from "@trace-one/design-system";
import { AskAccessModal } from "IndexComponents";
import { useModalVisibility } from "src/hooks";
import { askAppAccessMessages } from "src/components/AppSwitcher/translations/messages";
import { DiscoverSolutionsProps } from "./types";
import { getPrefixClassName } from "src/utils/general";
import { AppSwitcherVariant } from "../../types";

const DiscoverSolutions: React.FC<DiscoverSolutionsProps> = ({
  handleClose,
  userId,
  variant,
}) => {
  const { isModalOpen, onOpenModal, onCloseModal } = useModalVisibility({
    onClose: handleClose,
  });

  const prefixClassName = getPrefixClassName("app-switcher");

  return (
    <>
      <div
        className={`${prefixClassName}-discover-solutions`}
        data-test-id={`${prefixClassName}-discover-solutions`}
      >
        {variant === AppSwitcherVariant.VERTICAL && (
          <Illustration color="primary" name="products" size={48} />
        )}
        <Paragraph size="xs">
          <FormattedMessage {...askAppAccessMessages.title} />
        </Paragraph>
        {variant === AppSwitcherVariant.VERTICAL
          ? (
          <Button type="link" onClick={onOpenModal}>
            <FormattedMessage {...askAppAccessMessages.requestAccess} />
          </Button>
            )
          : (
          <Button type="link" onClick={onOpenModal}>
            <FormattedMessage {...askAppAccessMessages.link} />
          </Button>
            )}
      </div>

      {isModalOpen && (
        <AskAccessModal open={true} onClose={onCloseModal} userId={userId} />
      )}
    </>
  );
};

export default DiscoverSolutions;
