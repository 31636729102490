import moment from "moment";
import { NotificationStatus } from "src/models/notification";

import { UserApplication } from "src/models";
import { APPLICATIONS_MAP_BY_ID } from "../constants";
import { Tab } from "../types";

export function makeNotificationAppTabs(userApps: UserApplication[]): Tab[] {
  return userApps
    .filter(({ applicationTypeId }) =>
      APPLICATIONS_MAP_BY_ID.has(applicationTypeId)
    )
    .map(({ applicationTypeId }) => {
      const app = APPLICATIONS_MAP_BY_ID.get(applicationTypeId);
      return {
        displayText: app.name[0],
        senderZone: app.value,
        name: app.name,
      };
    });
}

export const getDefaultNotificationStatus = (status?: NotificationStatus) => {
  if (status === NotificationStatus.READ) {
    return "read";
  }

  if (status === NotificationStatus.UNREAD) {
    return "unread";
  }

  return "all";
};

export const getDefaultDaterangeValue = (
  from: string,
  to: string
): null | [moment.Moment, moment.Moment] => {
  if (!from && !to) {
    return null;
  }

  if (from && !to) {
    return [moment(from), null];
  }

  if (!from && to) {
    return [null, moment(to)];
  }

  return [moment(from), moment(to)];
};

/*
@arg date - ISO date string or Js Date object
*/
export const getStartOfTheDay = (date: string | Date) => {
  return moment(date).startOf("day").toISOString();
};

/*
@arg date - ISO date string or Js Date object
*/
export const getEndOfTheDay = (date: string | Date) => {
  return moment(date).endOf("day").toISOString();
};
