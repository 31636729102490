import { DMSFileData } from "src/models";

const useSelectedFiles = ({
  onSelectButtonClick,
  onCloseModal,
  selectedFiles,
  setSelectedFiles,
  multiple,
}: {
  onCloseModal: Function;
  onSelectButtonClick?: Function;
  selectedFiles?: DMSFileData[];
  setSelectedFiles?: Function;
  multiple?: boolean;
}) => {
  const handleSelectAction = () => {
    if (typeof onSelectButtonClick === "function") {
      onSelectButtonClick(selectedFiles);
    }

    onCloseModal();
  };

  const onSelectFile = (file: DMSFileData) => (selected: boolean) => {
    if (selected) {
      multiple
        ? setSelectedFiles(previousState => [...previousState, file])
        : setSelectedFiles([file]);
    } else {
      setSelectedFiles(previousState =>
        previousState.filter(({ fileId }) => file.fileId !== fileId)
      );
    }
  };

  return {
    numberOfFilesSelected: selectedFiles.length,
    handleSelectAction,
    onSelectFile,
  };
};

export default useSelectedFiles;
