import { useState } from "react";

const usePagination = () => {
  const [pageAndSize, setPageAndSize] = useState({
    page: 1,
    pageSize: 10,
  });

  const onPageChange = (page: number, pageSize: number) => {
    setPageAndSize({ page, pageSize });
  };

  const onPageSizeChange = (pageSize: number) => {
    setPageAndSize({ page: 1, pageSize });
  };

  const resetPage = () => {
    setPageAndSize(current => ({ ...current, page: 1 }));
  };

  return {
    onPageChange,
    onPageSizeChange,
    resetPage,
    pageAndSize,
  };
};

export default usePagination;
