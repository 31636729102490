import { defineMessages } from "react-intl";

export const notificationServiceMessage = defineMessages({
  title: {
    id: "notification.service.title",
    defaultMessage: "You have {count} new notifications",
  },
  alertLinkText: {
    id: "notificacion.alert.linkText",
    defaultMessage: "View",
  },
});

export const headingMessages = defineMessages({
  earlier: {
    id: "notification.heading.earlier",
    defaultMessage: "Earlier",
  },
  today: {
    id: "notification.heading.today",
    defaultMessage: "Today",
  },
  noData: {
    id: "notification.heading.noData",
    defaultMessage: "No data",
  },
});

export const cardTime = defineMessages({
  momentAgo: {
    id: "notification.card.momentAgo",
    defaultMessage: "a moment ago",
  },
  minutesAgo: {
    id: "notification.card.minutesAgo",
    defaultMessage: "{minutes} minute{plural} ago",
  },
  hoursAgo: {
    id: "notification.card.hoursAgo",
    defaultMessage: "{hours} hour{plural} ago",
  },
});

export const tab = defineMessages({
  all: {
    id: "notification.tab.all",
    defaultMessage: "ALL",
  },
});

export const alertMessage = defineMessages({
  successMarkAsRead: {
    id: "notification.alertMessage.successMarkAsRead",
    defaultMessage:
      "{count} {count, plural, one {notification has been marked as read.} other {notifications have been marked as read.}}",
  },
  successAsDelete: {
    id: "notification.alertMessage.successAsDelete",
    defaultMessage:
      "{count} {count, plural, one {notification has been deleted.} other {notifications have been deleted.}}",
  },
  warningSelected: {
    id: "notification.alertMessage.warningSelected",
    defaultMessage:
      "{count} {count, plural, one {notification selected} other {notifications selected}}",
  },
  error: {
    id: "notification.alertMessage.error",
    defaultMessage: "The action could not be performed.",
  },
});

export const confirmationModalMessage = defineMessages({
  description: {
    id: "notification.confirmationModal.description",
    defaultMessage:
      "Do you want to delete {count} {count, plural, one {notification} other {notifications}} ?",
  },
  delete: {
    id: "notification.confirmationModal.delete",
    defaultMessage: "DELETE",
  },
});

export const toolbarMessage = defineMessages({
  markAllAsRead: {
    id: "notification.toolbar.markAllAsRead",
    defaultMessage: "Mark all as read",
  },
  title: {
    id: "notification.toolbar.title",
    defaultMessage: "All",
  },
});

export const notificationsFilterMessage = defineMessages({
  toolbarTitle: {
    id: "notification.filters.title.toolbar",
    defaultMessage: "All filters",
  },
  footerButtonsApply: {
    id: "notification.filters.footer.buttons.apply",
    defaultMessage: "Apply",
  },
  footerButtonsBack: {
    id: "notification.filters.footer.buttons.back",
    defaultMessage: "Back",
  },
  readUnreadFilterTitle: {
    id: "notification.filters.title.displayNotifications",
    defaultMessage: "Display notifications",
  },
  dateFilterTitle: {
    id: "notification.filters.title.date",
    defaultMessage: "Date / Period",
  },
  notificationsTypeFilterTitle: {
    id: "notification.filters.title.notificationType",
    defaultMessage: "Filter by notification type",
  },
  optionAll: {
    id: "notification.filters.option.all",
    defaultMessage: "All",
  },
  optionUnreadOnly: {
    id: "notification.filters.option.unreadOnly",
    defaultMessage: "Unread only",
  },
  optionReadOnly: {
    id: "notification.filters.option.readOnly",
    defaultMessage: "Read only",
  },
  optionFolderCreation: {
    id: "notification.filters.option.folderCreation",
    defaultMessage: "Folder creation",
  },
  optionSpecificationCreation: {
    id: "notification.filters.option.specificationCreation",
    defaultMessage: "Specification creation",
  },
  optionSpecificationInvitation: {
    id: "notification.filters.option.specificationInvitation",
    defaultMessage: "Specification invitation",
  },
  optionContactAdded: {
    id: "notification.filters.option.contactAdded",
    defaultMessage: "Contact added",
  },
  optionRevisionRequestAcceptation: {
    id: "notification.filters.option.revisionRequestAcceptation",
    defaultMessage: "Revision request acceptation",
  },
  optionSectionValidation: {
    id: "notification.filters.option.sectionValidation",
    defaultMessage: "Section validation",
  },
  optionChapterValidation: {
    id: "notification.filters.option.chapterValidation",
    defaultMessage: "Chapter validation",
  },
  optionSpecificationSignature: {
    id: "notification.filters.option.specificationSignature",
    defaultMessage: "Specification signature",
  },
  optionRevisionRequest: {
    id: "notification.filters.option.revisionRequest",
    defaultMessage: "Revision request",
  },
  optionRevisionRequestRejection: {
    id: "notification.filters.option.revisionRequestRejection",
    defaultMessage: "Revision request rejection",
  },
  dateAfter: {
    id: "notification.filters.selected.date.after",
    defaultMessage: "After",
  },
  dateBefore: {
    id: "notification.filters.selected.date.before",
    defaultMessage: "Before",
  },
});

export const notificationActionTitles = defineMessages({
  deactivateNotification: {
    id: "notification.actions.deactivate",
    defaultMessage: "Deactivate",
  },
  deleteNotification: {
    id: "notification.actions.delete",
    defaultMessage: "Delete",
  },
  markAsRead: {
    id: "notification.actions.markasread",
    defaultMessage: "Mark as read",
  },
  markAsUnread: {
    id: "notification.actions.markasunread",
    defaultMessage: "Mark as unread",
  },
  deactivationDescription: {
    id: "notification.actions.description.deactivate",
    defaultMessage: "stop receiving this type of notification",
  },
  deleteDescription: {
    id: "notification.actions.description.delete",
    defaultMessage: "delete this notification",
  },
});
