import React, { useMemo } from "react";
import { Card, Form, Empty } from "antd";
import { useIntl } from "react-intl";
import { SummaryProps } from "./types";
import {
  Tooltip,
  Button,
  EmptyState,
  Typography,
} from "@trace-one/design-system";
import useRemove from "./hooks/useRemove";
import { createPackagingMessage } from "src/components/PackagingSystem/translations/messages";
import { useCreatePackagingContext } from "src/components/PackagingSystem/context";
import "../Summary/styles.less";
import { messages as commonMessages } from "src/messages";
import classNames from "classnames";
import { isListEmpty } from "src/utils/general";

const PackagingSystemCreationSummary: React.FC<SummaryProps> = props => {
  const { isPrimaryButtonDisabled, onValidate } = props;
  const {
    onDelete,
    components,
    onEdit,
    onDuplicate,
    isAssociating,
    isEditmode,
    types,
  } = useCreatePackagingContext();
  const { formatMessage } = useIntl();

  const getPrefixCls = "business-components-create-packaging-card-items";

  const { createActions, isTooltipVisible, onOpenChange } = useRemove({
    onDelete,
    cancelText: formatMessage(commonMessages.cancel),
    confirmText: formatMessage(createPackagingMessage.confirm),
  });

  const getTypeOfComponent = (type: string): string => {
    const componentType = types.find(item => item.value === type);
    return componentType ? componentType.name : "";
  };

  const hasComponentsAdded = useMemo(() => {
    return (
      !isListEmpty(components) && components.some(component => component.name)
    );
  }, [components]);

  return (
    <div className={getPrefixCls} data-test-id="components-summary">
      <div className={`${getPrefixCls}-component-summary-card`}>
        {formatMessage(createPackagingMessage.componentSummary)}
      </div>
      <Card className="summary-card">
        <div
          className={classNames("components", {
            "flex-center": !hasComponentsAdded,
          })}
          data-test-id="components"
        >
          <>
            {components?.map(
              (component, index) =>
                component?.name?.length > 0 && (
                  <div className={`${getPrefixCls}-card`} key={index}>
                    <div className={`${getPrefixCls}-summary`}>
                      <div className={`${getPrefixCls}-description`}>
                        <div className={`${getPrefixCls}-name`}>
                          <Tooltip
                            placement="bottomLeft"
                            text={component.name}
                            showFullText={true}
                          >
                            <span>{component.name}</span>
                          </Tooltip>
                        </div>
                        <Typography
                          variant="heading-xxs"
                          component="div"
                          className={`${getPrefixCls}-type`}
                        >
                          {getTypeOfComponent(component?.componentTypeId)}
                        </Typography>
                      </div>
                      <div className={`${getPrefixCls}-icons`}>
                        <Tooltip
                          text={formatMessage(
                            commonMessages.packagingComponentActionsEdit
                          )}
                          placement={"bottom"}
                        >
                          <Button
                            data-test-id="component-edit"
                            color={"primary"}
                            type="tertiary"
                            size="large"
                            iconName="edit"
                            onClick={() => onEdit(component)}
                          />
                        </Tooltip>

                        <Tooltip
                          text={formatMessage(commonMessages.duplicate)}
                          placement={"bottom"}
                        >
                          <Button
                            data-test-id="component-duplicate"
                            color={"primary"}
                            type="tertiary"
                            iconName="duplicate"
                            size="large"
                            onClick={() => onDuplicate(component)}
                          />
                        </Tooltip>

                        <Tooltip
                          text={formatMessage(
                            createPackagingMessage.deletetooltip
                          )}
                          placement="left"
                          actions={createActions(index)}
                          open={isTooltipVisible(index)}
                          onOpenChange={open => onOpenChange(index, open)}
                        >
                          <Button
                            iconName="trash"
                            color={"red"}
                            type="tertiary"
                            size="large"
                          />
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                )
            )}
          </>
          {!hasComponentsAdded && (
            <EmptyState image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </div>

        <Form.Item className={`${getPrefixCls}-onAssociate`}>
          <div className="modal-actions">
            <Button
              type="primary"
              loading={isAssociating}
              onClick={() => onValidate()}
              data-test-id="onAssociateButton"
              disabled={isPrimaryButtonDisabled}
            >
              {isEditmode
                ? (
                <div data-test-id="edit-message">
                  {formatMessage(createPackagingMessage.editButton)}
                </div>
                  )
                : (
                <div data-test-id="create-message">
                  {formatMessage(createPackagingMessage.createButton)}
                </div>
                  )}
            </Button>
          </div>
        </Form.Item>
      </Card>
    </div>
  );
};

export default PackagingSystemCreationSummary;
