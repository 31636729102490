import { useEffect, useState } from "react";
import * as APIS from "src/apis/cumd";

const useTimezones = ({ userId }: { userId: string }) => {
  const [timezones, setTimezones] = useState([]);

  const getTimeZones = async () => {
    try {
      const { data } = await APIS.getTimeZones();
      setTimezones(data);
    } catch (error) {}
  };

  const updateTimeZone = async (value: string) => {
    try {
      await APIS.updateUserProfile({
        userId,
        newTimeZone: value,
      });
    } catch (error) {}
  };

  useEffect(() => {
    getTimeZones();
  }, []);

  return {
    timezones,
    updateTimeZone,
  };
};

export default useTimezones;
