import React from "react";
import { useIntl } from "react-intl";
import { Paragraph } from "@trace-one/design-system";
import { withLanguageData } from "src/hocs";
import { isListEmpty } from "src/utils/general";
import { ErrorsProps } from "./types";

import enUs from "../../../../translations/en-US.json";
import frFr from "../../../../translations/fr-FR.json";
import { LangList } from "../../../../../../../utils/const";
import { errorMessages } from "../../../../translations/messages";

const DEFAULT_LANG = enUs;

const enhance = withLanguageData({
  translations: {
    [LangList.EN_US]: enUs,
    [LangList.FR_FR]: { ...DEFAULT_LANG, ...frFr },
  },
});

const Errors: React.FC<ErrorsProps> = ({
  allowedFileExtensions,
  maxFileSizeInBytes,
  typeErrorUids,
  sizeErrorUids,
  messages,
  error,
}) => {
  const { formatMessage } = useIntl();

  return (
    <div className="business-components-upload-wrapper-errors-list">
      {!isListEmpty(typeErrorUids) && (
        <Paragraph color="red">
          {messages?.generalType ??
            formatMessage(errorMessages.generalType, {
              fileTypes: allowedFileExtensions.join(", "),
            })}
        </Paragraph>
      )}
      {!isListEmpty(sizeErrorUids) && (
        <Paragraph color="red">
          {messages?.generalSize ??
            formatMessage(errorMessages.generalSize, {
              maxSize: maxFileSizeInBytes / Math.pow(1024, 2),
            })}
        </Paragraph>
      )}
      {error && <Paragraph color="red">{error}</Paragraph>}
    </div>
  );
};

Errors.defaultProps = {
  allowedFileExtensions: [],
};

export default enhance(Errors);
