import classNames from "classnames";
import React from "react";
import { Button, Heading } from "@trace-one/design-system";
import { getPrefixClassName } from "src/utils/general";
import { MultiSelectionSidebarProps } from "src/components/SearchFilter/types";
import { useIntl } from "react-intl";
import { searchFilterActions } from "src/components/SearchFilter/translations/defineMessage";

const prefixCls = getPrefixClassName("search-filter");

export const MultiSelectionSidebar: React.FC<MultiSelectionSidebarProps> = ({
  filter,
  isOpen = true,
  closeMultiSelectionSideBar,
}) => {
  const { formatMessage } = useIntl();
  const cancelPropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const applyFilter = () => {
    closeMultiSelectionSideBar();
  };

  return (
    <div
      onClick={cancelPropagation}
      data-test-id="multi-select-sidebar"
      className={classNames(prefixCls, `${prefixCls}-multi-select-sidebar`, {
        [`${prefixCls}-multi-select-sidebar-open`]: isOpen,
        [`${prefixCls}-multi-select-sidebar-close`]: !isOpen,
      })}
    >
      <header
        className={classNames(`${prefixCls}-multi-select-sidebar-header`)}
      >
        <div className="back-icon-container">
          <Button
            type="tertiary"
            iconName="arrow-left"
            data-test-id="bs-search-filter-multiselection-header-backbtn"
            onClick={closeMultiSelectionSideBar}
          ></Button>
        </div>
        <div>
          <Heading size="xs">{filter?.title}</Heading>
        </div>
      </header>

      {/* Panel Body */}
      <div className={classNames(`${prefixCls}-multi-select-sidebar-content`)}>
        {filter?.elementToRender}
      </div>

      <footer
        className={classNames(`${prefixCls}-multi-select-sidebar-footer`)}
      >
        <Button
          type="secondary"
          data-test-id="bs-search-filter-multiselection-footer-backbtn"
          onClick={closeMultiSelectionSideBar}
        >
          {formatMessage(searchFilterActions.allFiltersActionBack)}
        </Button>
        <Button
          type="primary"
          data-test-id="bs-search-filter-multiselection-applybtn"
          onClick={applyFilter}
        >
          {formatMessage(searchFilterActions.allFiltersActionApply)}
        </Button>
      </footer>
    </div>
  );
};

export default MultiSelectionSidebar;
