import { Reducer } from "react";
import { AskAccessModalAction, AskAccessModalState } from "../types";

const reducer: Reducer<AskAccessModalState, AskAccessModalAction> = (
  state,
  action
) => {
  if (action.type === "SET_APPS") {
    return { ...state, availableApps: action.availableApps };
  }
};

export default reducer;
