import { useEffect, useState } from "react";
import * as RLMD from "src/apis/rlmd";
import * as CUMD from "src/apis/cumd";
import { ReferenceListItem } from "src/models";

const useLanguages = ({
  languageCode,
  userId,
  onLanguageChange,
}: {
  languageCode: string;
  userId: string;
  onLanguageChange: (languageCode: string) => void;
}) => {
  const [languages, setLanguages] = useState<ReferenceListItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const getLanguages = async () => {
    setIsLoading(true);

    try {
      const { data } = await RLMD.getLanguages({ languageCode });

      setLanguages(
        data.reduce((previousState, { isItemActive, itemCode, text }) => {
          const languages = [...previousState];
          if (isItemActive) {
            languages.push({
              value: itemCode,
              name: text,
              "data-test-id": `my-settings-language-item-${itemCode}`,
            });
          }
          return languages;
        }, [])
      );
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const updateUserLanguage = async ({
    userId,
    languageCode,
  }: {
    userId: string;
    languageCode: string;
  }) => {
    try {
      await CUMD.updateUserLanguage({
        userId,
        languageCode,
      });
    } catch (error) {}
  };

  const onUserLanguageChange = (languageCode: string) => {
    updateUserLanguage({ userId, languageCode }).then(() =>
      onLanguageChange(languageCode)
    );
  };

  useEffect(() => {
    getLanguages();
  }, []);

  return {
    languages,
    onUserLanguageChange,
    isLoading,
  };
};

export default useLanguages;
