import React, { useEffect, useState } from "react";
import { getPrefixClassName } from "src/utils/general";
import { Button, Heading, Graphic, Typography } from "@trace-one/design-system";
import { StickyInfoBannerProps } from "../../types";

const prefixCls = getPrefixClassName("header");

const StickyInfoBanner = ({
  visible,
  bannerTitle,
  description,
  alertIndicatorIconName,
}: StickyInfoBannerProps) => {
  const [isBannerVisible, setIsBannerVisible] = useState(visible);

  useEffect(() => {
    setIsBannerVisible(visible);
  }, [visible]);

  const closeBanner = () => {
    setIsBannerVisible(false);
  };

  if (!isBannerVisible) {
    return null;
  }

  return (
    <div
      className={`${prefixCls}-sticky-info-banner`}
      data-test-id="ds-header-sticky-info-banner"
    >
      <div className={`${prefixCls}-sticky-info-banner-content-container`}>
        <div className={`${prefixCls}-sticky-info-banner-content`}>
          <Graphic name={alertIndicatorIconName}></Graphic>
          <div>
            <Heading size="xxs">{bannerTitle}</Heading>
            <Typography variant="body-s">{description}</Typography>
          </div>
        </div>
        <div>
          <Button
            type="tertiary"
            onClick={closeBanner}
            color="grey"
            iconName="close"
            data-test-id="ds-header-infobanner-close-btn"
          ></Button>
        </div>
      </div>
    </div>
  );
};

export default StickyInfoBanner;
