import { useEffect, useState } from "react";
import { fetchUserDetails } from "src/apis/cumd";
import { isListEmpty } from "src/utils/general";
import { DMSFileData } from "src/models";
import { getUserIds } from "./utils";

const useUserDetails = ({ dmsFiles }: { dmsFiles: DMSFileData[] }) => {
  const [userDetailsDictionary, setUserDetailsDictionary] = useState({});

  const getUserDetails = async (userIds: string[]) => {
    try {
      const { data } = await fetchUserDetails({ userIds });

      setUserDetailsDictionary(
        data.reduce(
          (previousState, { userId, userFirstName, userLastName }) => ({
            ...previousState,
            [userId]: `${userFirstName} ${userLastName}`,
          }),
          {}
        )
      );
    } catch (error) {}
  };

  useEffect(() => {
    const userIds = getUserIds(dmsFiles);

    const filteredIds = userIds.filter(
      userId => !userDetailsDictionary[userId]
    );

    if (!isListEmpty(filteredIds)) {
      getUserDetails(filteredIds);
    }
  }, [dmsFiles.length]);

  return {
    userDetailsDictionary,
  };
};

export default useUserDetails;
