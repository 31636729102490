import { createPackagingMessage } from "../../translations/messages";
import { v4 } from "uuid";

interface Component {
  name: string;
  id: string;
}

export const checkComponentId = (formatMessage, componentId) => () => {
  if (componentId?.length === 0) {
    return Promise.reject(
      new Error(formatMessage(createPackagingMessage.mandatoryTypesname))
    );
  }
};

export const checkComponentName = (formatMessage, componentName) => () => {
  if (componentName?.length === 0) {
    return Promise.reject(
      new Error(formatMessage(createPackagingMessage.mandatoryComponentname))
    );
  }
};

export const checkMandatoryConditions = field => {
  if ((!field.value && field?.unitId) || (field?.value && !field?.unitId)) {
    return true;
  } else {
    return false;
  }
};

export const checkComboField = (formatMessage, field) => () => {
  if (
    field === undefined ||
    (!field?.value && !field?.unitId) ||
    (field?.value && field?.unitId)
  ) {
    return Promise.resolve();
  }
  if ((!field.value && field?.unitId) || (field?.value && !field?.unitId)) {
    return Promise.reject(
      new Error(formatMessage(createPackagingMessage.mandatory))
    );
  }
};

export const filterOption = (input, option) =>
  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

export const getDuplicateComponentNames = components =>
  components.reduce((acc, curr, index, arr) => {
    if (
      arr.findIndex(
        component =>
          component?.name.trim().toUpperCase() ===
          curr?.name.trim().toUpperCase()
      ) !== index
    ) {
      acc.push(curr.name.trim());
    }
    return acc;
  }, []);

export const setComponentName = (baseName, suffix, space = true) =>
  `${baseName}${space ? " " : ""}${suffix}`;

export const prepareDuplicatedComponent = (
  components: Component[],
  duplicatedComponent: Component
): Component => {
  const baseName = duplicatedComponent.name?.trim()?.replace(/(\d+)?$/, "");
  const lastNumber =
    parseInt(duplicatedComponent.name?.trim()?.match(/(\d+)$/)?.[0]) || 0;
  let suffix = lastNumber + 1;
  let componentName = setComponentName(baseName, suffix, !lastNumber);

  const allPossibleDuplicatesNames = Array.from(
    components,
    (component, i) => component.name
  ).sort((a, b) => {
    return parseInt(a.split(" ")[1]) - parseInt(b.split(" ")[1]);
  });

  allPossibleDuplicatesNames.forEach(name => {
    if (name === componentName) {
      componentName = setComponentName(baseName, ++suffix, !lastNumber);
    }
  });

  duplicatedComponent.name = setComponentName(baseName, suffix, !lastNumber);

  return { ...duplicatedComponent, id: v4() };
};
