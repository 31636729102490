import React from "react";
import { Heading, Paragraph, Icon, UserCard } from "@trace-one/design-system";
import { useJobTitles } from "src/hooks";
import { UserInfoProps } from "./types";

const UserInfo: React.FC<UserInfoProps> = ({ user }) => {
  const { jobTitles } = useJobTitles({
    languageCode: user.userLanguagePreference,
  });

  const jobTitle = jobTitles?.find(
    jobTitle =>
      jobTitle.value === user.userJobTitleId?.toString() && jobTitle
  );

  return (
    <div
      className="user-info"
      data-test-id="user-profile-page-sidebar-user-info"
    >
      <div
        className="user-info-avatar"
        data-test-id="user-profile-page-sidebar-user-info-avatar"
      >
        <UserCard.Information
          name={`${user?.userFirstName} ${user?.userLastName}`}
          photoUrl={user?.userPhotoUrl}
          icon={false}
        />
        {user.isAccountAdministrator && (
          <div
            className="user-info-avatar-admin"
            data-test-id="user-profile-page-sidebar-user-info-admin-icon"
          >
            <Icon name="administrator" color="white" />
          </div>
        )}
      </div>
      <div
        className="user-info-name"
        data-test-id="user-profile-page-sidebar-user-info-name"
      >
        <Heading size="xs">{`${user.userFirstName} ${user.userLastName} `}</Heading>
      </div>
      <Paragraph data-test-id="user-profile-page-sidebar-user-info-job-title">
        {jobTitle && jobTitle.name}
      </Paragraph>
    </div>
  );
};

export default UserInfo;
