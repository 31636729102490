import React from "react";
import { useIntl } from "react-intl";
import { Select, Tooltip } from "@trace-one/design-system";
import { createPackagingMessage } from "../../translations/messages";
import { SelectPackagingComponentProps } from "./types";
import "./styles.less";

const SelectPackagingComponent: React.FC<SelectPackagingComponentProps> = ({
  addComponent,
  components,
  className,
  onOpenModal,
  allowPackagingSystemUpdate,
}) => {
  const intl = useIntl();

  const handleChange = (selectedValue: string, e: any) => {
    if (selectedValue === "AddExistingComponent") {
      onOpenModal();
    } else addComponent();
  };

  const options = [
    {
      value: "createAComponent",
      label: components?.some(obj => obj.name.length === 0)
        ? (
        <Tooltip
          text={intl.formatMessage(
            createPackagingMessage.createComponentTooltip
          )}
          size="large"
          placement={"right"}
          data-test-id="toolip-create-component"
        >
          <div data-test-id="create-components">
            {intl.formatMessage(createPackagingMessage.createComponent)}
          </div>
        </Tooltip>
          )
        : (
        <div data-test-id="create-components">
          {intl.formatMessage(createPackagingMessage.createComponent)}
        </div>
          ),
      disabled: components?.some(obj => obj.name.length === 0),
    },
    {
      value: "AddExistingComponent",
      label: intl.formatMessage(createPackagingMessage.addExistingComponent),
      disabled: false,
      title: "",
    },
  ];

  const getPrefixClassName = `${className}-associate-component`;

  return (
    <div className={`${getPrefixClassName}`}>
      <div
        className={`${getPrefixClassName}-create`}
        data-test-id="create-associate-component-header"
      >
        {intl.formatMessage(createPackagingMessage.createComponents)}
      </div>
      <div className={`${getPrefixClassName}-select`}>
        <Select
          value={intl.formatMessage(createPackagingMessage.addAComponent)}
          allowClear={false}
          onSelect={handleChange}
          options={options}
          data-test-id="select-associate-packaging"
          disabled={!allowPackagingSystemUpdate}
        />
      </div>
    </div>
  );
};

export default SelectPackagingComponent;
