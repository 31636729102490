import React from "react";
import { Row, Col } from "antd";
import {
  Paragraph,
  Heading,
  DisplayCard,
  ToggleButtons,
  Spinner,
} from "@trace-one/design-system";
import classnames from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDisplayItems } from "./hooks";
import { InfiniteScrollLibraryContentProps } from "./types";
import "./styles.less";

const getVersionName = (version: number): string => `V${version}`;

const InfiniteScrollLibraryContent: React.FC<
  InfiniteScrollLibraryContentProps
> = ({
  title,
  totalNumberOfItems,
  canLoadMore,
  libraryObjectList,
  nextFetchCallback,
  onSelectLibraryItem,
  existingLibraryObjectIds,
  selectedLibraryObjectIds,
  infiniteScrollWrapper,
}) => {
  const { displayItems, options, onDisplayItemChange } = useDisplayItems();

  return (
    <>
      <Row
        justify="space-between"
        align="middle"
        className={"modalBodyTitleRow"}
      >
        <Col>
          <Heading className={"modalBodyTitle"} size="xs">
            {title}
          </Heading>
          <Paragraph className={"modalBodyTotalNumber"} size="s">
            {` (${totalNumberOfItems})`}
          </Paragraph>
        </Col>
        <Col>
          <ToggleButtons
            onChange={onDisplayItemChange}
            defaultValue="display-4-columns"
            options={options}
          />
        </Col>
      </Row>
      <div
        id="scrollableInfiniteScrollWrapper"
        ref={infiniteScrollWrapper}
        className={"infiniteScrollWrapper"}
      >
        <InfiniteScroll
          className={classnames(
            "infiniteScrollDisplay",
            displayItems,
            "infiniteScroll"
          )}
          dataLength={libraryObjectList.length}
          hasMore={canLoadMore}
          loader={
            <div className={"infiniteScrollLoadingContainer"}>
              <Spinner />
            </div>
          }
          next={nextFetchCallback}
          height={380}
        >
          {libraryObjectList.map(libraryObject => {
            return (
              <DisplayCard
                key={libraryObject.id}
                title={libraryObject.name}
                subtitle={libraryObject.formattedType}
                iconName={libraryObject.iconName}
                version={getVersionName(libraryObject.versionNumber)}
                onClick={() => onSelectLibraryItem(libraryObject)}
                disabled={existingLibraryObjectIds?.includes(libraryObject.id)}
                selected={selectedLibraryObjectIds?.includes(libraryObject.id)}
              />
            );
          })}
        </InfiniteScroll>
      </div>
    </>
  );
};

export default InfiniteScrollLibraryContent;
