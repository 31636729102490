import React, { useMemo } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { EmptyState, Spinner, Heading } from "@trace-one/design-system";

import "../../styles.less";
import { useIntl } from "react-intl";
import moment from "moment";
import { headingMessages } from "src/components/NotificationsPanel/translations/defineMessage";
import { NotificationPanelContentProps } from "src/components/NotificationsPanel/types";
import SelectedFilters from "../SelectedFilters";
import NotificationCardItem from "./NotificationCardItem";

const Content: React.FC<NotificationPanelContentProps> = ({
  notifications,
  unreadNotificationsCount,
  loadMoreNotifs,
  hasMoreData,
  isLoading,
  toggleNotificationsReadStatus,
  removeFilterByTag,
  filtersToDisplay,
  removeNotifications,
  deactivateNotificationsType,
}) => {
  const { formatMessage } = useIntl();
  const renderedNotifications = useMemo(() => {
    const today = moment();
    let containsEarlierComponent = false;

    return notifications.map((x, i) => {
      const notifMoment = moment(x.notificationEventUtcDateTime);
      const addTodayHeading = notifMoment.isSame(today, "d") && i === 0;
      const addEarlierHeading =
        !notifMoment.isSame(today, "d") && !containsEarlierComponent;
      return (
        <div
          className="notification-panel-item"
          key={x.occurrenceUserInstanceId}
        >
          {addTodayHeading && (
            <Heading size="xxs">
              <span data-test-id="today-header">
                {formatMessage(headingMessages.today)}
              </span>
            </Heading>
          )}
          {addEarlierHeading &&
            (() => {
              containsEarlierComponent = true;
              return (
                <Heading size="xxs">
                  <span data-test-id="earlier-header">
                    {formatMessage(headingMessages.earlier)}
                  </span>
                </Heading>
              );
            })()}
          <NotificationCardItem
            notification={x}
            toggleNotificationsReadStatus={toggleNotificationsReadStatus}
            deactivateNotificationsType={deactivateNotificationsType}
            removeNotifications={removeNotifications}
          />
        </div>
      );
    });
  }, [notifications.length, unreadNotificationsCount]);

  return (
    <div
      className="notifications-panel-content-area"
      id="notifications-panel-scroll-container"
    >
      <SelectedFilters
        removeFilterByTag={removeFilterByTag}
        filtersToDisplay={filtersToDisplay}
      />
      <InfiniteScroll
        className="infinite-scroll"
        dataLength={notifications.length}
        next={() => {
          loadMoreNotifs();
        }}
        hasMore={hasMoreData}
        scrollableTarget="notifications-panel-scroll-container"
        loader={
          <div className="infinite-scroll-loading-container">
            <Spinner />
          </div>
        }
      >
        {renderedNotifications}
      </InfiniteScroll>
      {!isLoading && notifications.length === 0 && (
        <div className="notifications-sidebar-container-empty">
          <EmptyState heading={formatMessage(headingMessages.noData)} />
        </div>
      )}
    </div>
  );
};

export default Content;
