import React, { useContext } from "react";
import { Avatar } from "antd";
import { Graphic, SizeType } from "@trace-one/design-system";
import { AppsListContext } from "../../../../contexts";

export interface AppIconProps {
  graphicName?: string;
  size?: SizeType;
}

const AppIcon: React.FC<AppIconProps> = ({ graphicName, size }) => {
  const { prefixClassName } = useContext(AppsListContext);

  if (graphicName) {
    return (
      <Graphic
        name={`app-logo-${graphicName}-full`}
        size={size ?? "extra-large"}
      />
    );
  }

  return <Avatar className={`${prefixClassName}-list-item-app-icon`}>X</Avatar>;
};

export default AppIcon;
