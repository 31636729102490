import { useIntl } from "react-intl";
import { messages } from "src/messages";

export const useFormSchemaTranslation = () => {
  const intl = useIntl();

  return {
    getTranslationForKey: (key, values?) =>
      messages[key] ? intl.formatMessage(messages[key], values) : key,
  };
};
