import React from "react";
import { Card } from "antd";
import CreatePackageSystem from "./components/CreatePackagingSystem";
import { useGetRefLists } from "./hooks/useGetRefLists/";
import usePackagingComponentStructure from "./hooks/usePackagingComponentStructure";
import { CreatePackagingContextProvider } from "./context";
import { withLanguageData } from "src/hocs";
import { createPackagingProps } from "./types";
import enUs from "./translations/en-US.json";
import frFr from "./translations/fr-FR.json";
import "./styles.less";

const DEFAULT_LANG = { ...enUs };
const enhance = withLanguageData({
  translations: {
    "en-US": DEFAULT_LANG,
    "fr-FR": { ...DEFAULT_LANG, ...frFr },
  },
});

const PackagingSystem: React.FC<createPackagingProps> = ({
  onAssociate,
  packagingSystemId,
  onEditAndAssociate,
  languageCode,
  ownerCompanyId,
  closePackagingSystemCreation,
  multiple = true,
}) => {
  const { getRefLists } = useGetRefLists({
    languageCode,
    companyId: ownerCompanyId,
  });

  const { getPackagingComponentStructure } = usePackagingComponentStructure();

  return (
    <CreatePackagingContextProvider>
      <Card className="packaging-system" data-test-id="packaging-system">
        <CreatePackageSystem
          onAssociate={onAssociate}
          packagingSystemId={packagingSystemId}
          onEditAndAssociate={onEditAndAssociate}
          getRefLists={getRefLists}
          ownerCompanyId={ownerCompanyId}
          languageCode={languageCode}
          closePackagingSystemCreation={closePackagingSystemCreation}
          multiple={multiple}
          getPackagingComponentStructure={getPackagingComponentStructure}
        />
      </Card>
    </CreatePackagingContextProvider>
  );
};

export default enhance(PackagingSystem);
