import classNames from "classnames";
import React from "react";
import { Divider, Button, Heading, Typography } from "@trace-one/design-system";
import FilterItemHeader from "./FilterItemHeader";
import FilterItemBody from "./FilterItemBody";
import { getPrefixClassName } from "src/utils/general";
import { AllFiltersSidebarProps } from "src/components/SearchFilter/types";
import { useIntl } from "react-intl";
import { searchFilterActions } from "src/components/SearchFilter/translations/defineMessage";

const prefixCls = getPrefixClassName("search-filter");

export const AllFiltersSidebar: React.FC<AllFiltersSidebarProps> = ({
  isOpen,
  selectedFilters,
  filters = [],
  openMultiSelectionSideBar,
  closeAllFiltersPanel,
  clearAllFilters,
  onSave,
}) => {
  const { formatMessage } = useIntl();

  const cancelPropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const selectedFiltersMap = new Map(
    selectedFilters.map(({ filterId, values }) => [filterId, values])
  );

  const activeFiltersCount = selectedFilters.filter(
    sf => sf.values?.length
  ).length;

  return (
    <div
      onClick={cancelPropagation}
      data-test-id="allfilters-sidebar"
      className={classNames(prefixCls, `${prefixCls}-allfilters-sidebar`, {
        [`${prefixCls}-allfilters-sidebar-open`]: isOpen,
        [`${prefixCls}-allfilters-sidebar-close`]: !isOpen,
      })}
    >
      <header className={classNames(`${prefixCls}-allfilters-sidebar-header`)}>
        <div>
          <Heading size="xs">
            {formatMessage(searchFilterActions.allFiltersHeaderTitle)}
          </Heading>
          <Typography variant="body-s">
            {formatMessage(searchFilterActions.allFiltersHeaderDescription)}
          </Typography>
        </div>
        {onSave && (
          <div className="save-icon-container">
            <Button onClick={onSave} type="tertiary" iconName="save"></Button>
          </div>
        )}
      </header>

      {/* Filter Panel Body */}
      <div className={classNames(`${prefixCls}-allfilters-sidebar-content`)}>
        {filters.map((filter, i) => {
          const isLastItem = filters.length - 1 !== i;
          const selectedValues = selectedFiltersMap.get(filter.filterId);
          return (
            <div className="allfilters-filter-item" key={filter.filterId}>
              <FilterItemHeader
                filter={filter}
                selectedValues={selectedValues}
                openMultiSelectionSideBar={openMultiSelectionSideBar}
              />
              <FilterItemBody selectedValues={selectedValues} filter={filter} />
              {isLastItem && <Divider color="grey-2" />}
            </div>
          );
        })}
      </div>

      <footer className={classNames(`${prefixCls}-allfilters-sidebar-footer`)}>
        <Button
          data-test-id="bs-all-filters-footer-clear-btn"
          type="secondary"
          onClick={clearAllFilters}
        >
          {formatMessage(searchFilterActions.allFiltersActionClear)}
        </Button>
        <Button
          data-test-id="bs-all-filters-footer-validate-btn"
          type="primary"
          onClick={closeAllFiltersPanel}
        >
          {formatMessage(searchFilterActions.allFiltersActionValidate)}{" "}
          {activeFiltersCount > 0 && `(${activeFiltersCount})`}
        </Button>
      </footer>
    </div>
  );
};

export default AllFiltersSidebar;
