import React from "react";
import { FormSchemaProps } from "./types";
import CustomForm from "../CustomForm";

const FormSchema = ({
  uiSchema,
  formData,
  schema,
  onChange,
  onSubmit,
  formContext,
  widgets,
  extraErrors,
  transformErrors,
  fields,
  FieldTemplate,
  ObjectFieldTemplate,
  ArrayFieldTemplate,
  ...rest
}: FormSchemaProps<typeof formData>) => {
  return (
    <CustomForm
      uiSchema={uiSchema}
      schema={schema}
      widgets={widgets}
      fields={fields}
      FieldTemplate={FieldTemplate}
      ObjectFieldTemplate={ObjectFieldTemplate}
      ArrayFieldTemplate={ArrayFieldTemplate}
      formContext={formContext ?? {}}
      onChange={({ formData }) => onChange(formData)}
      onSubmit={onSubmit}
      formData={formData || {}}
      showErrorList={false}
      extraErrors={extraErrors}
      transformErrors={transformErrors}
      {...rest}
    >
      <span />
    </CustomForm>
  );
};

FormSchema.defaultProps = {
  onSubmit: () => {},
  onChange: () => {},
};

export default FormSchema;
