import { AxiosInstancesManager } from "../../configs/AxiosInstancesManager";
import {
  CountUnreadAPIResponse,
  DeleteNotificationsIdsParams,
  DeleteNotificationsIdsResponse,
  GetNotificationsIdsParams,
  GetNotificationsIdsResponse,
  GetNotificationsParams,
  GetNotificationsReqquestPayload,
  GetNotificationsResponse,
} from "./types";
import {
  NotificationSettings,
  NotificationStatus,
} from "src/models/notification";

export const getNotificationSettings = ({
  languageCode,
  senderZone,
  level,
  includeNotUserCustomizedNotifications,
}: {
  languageCode: string;
  senderZone?: string;
  level?: string;
  includeNotUserCustomizedNotifications?: boolean;
}): Promise<{ data: NotificationSettings }> => {
  const notifInstance = AxiosInstancesManager.getNotif();

  return notifInstance.get<NotificationSettings>(
    `/api/notificationPreferences`,
    {
      params: {
        languageCode,
        senderZone,
        level,
        includeNotUserCustomizedNotifications,
      },
    }
  );
};

export const updateNotificationSettings = ({
  notificationSettings,
}: {
  notificationSettings: NotificationSettings;
}) => {
  const notifInstance = AxiosInstancesManager.getNotif();

  return notifInstance.post<NotificationSettings>(
    `/api/notificationPreferences`,
    {
      ...notificationSettings,
    }
  );
};

export const deactivateNotificationType = ({
  notificationTypeId,
}: {
  notificationTypeId: string;
}) => {
  const notifInstance = AxiosInstancesManager.getNotif();

  return notifInstance.delete<any>(
    `/api/NotificationPreferences/${notificationTypeId}`
  );
};

export const getNotifications = (
  params: GetNotificationsParams,
  body?: GetNotificationsReqquestPayload
): Promise<GetNotificationsResponse> => {
  const notifInstance = AxiosInstancesManager.getNotif();
  if (params.senderZone === "All") {
    params.senderZone = undefined;
  }

  return notifInstance.post("/api/webNotifications/filters", body || {}, {
    params,
  });
};

export const getUnreadNotificationsCount =
  (): Promise<CountUnreadAPIResponse> => {
    const notifInstance = AxiosInstancesManager.getNotif();

    return notifInstance.get("/api/webNotifications/countUnread", {
      params: {
        withDetailsPerSender: true,
      },
    });
  };

export const updateNotificationStatus = (
  ids: string[] = [],
  status: NotificationStatus
): Promise<null> => {
  const notifInstance = AxiosInstancesManager.getNotif();
  return notifInstance.patch("/api/occurrenceUserInstances/list/updateStatus", {
    occurrenceUserInstanceIds: ids,
    newStatus: status,
  });
};

export const markAllNotificationsAsReadBySenderZone = (
  senderZone?: string
): Promise<null> => {
  const notifInstance = AxiosInstancesManager.getNotif();
  return notifInstance.put("/api/OccurenceUserInstance/read", null, {
    params: {
      senderZone,
    },
  });
};

export const getNotificationsIds = (
  params: GetNotificationsIdsParams
): Promise<GetNotificationsIdsResponse> => {
  const notifInstance = AxiosInstancesManager.getNotif();
  return notifInstance.get("/api/webNotifications/ids", { params });
};

export const deleteNotifications = ({
  occurrenceUserInstanceIds = [],
}: DeleteNotificationsIdsParams): Promise<DeleteNotificationsIdsResponse> => {
  const notifInstance = AxiosInstancesManager.getNotif();
  return notifInstance.delete("/api/occurrenceUserInstances/list", {
    data: { occurrenceUserInstanceIds },
  });
};
