import React, { createContext, useContext, useState } from "react";
import { v4 } from "uuid";
import {
  CreatePackagingProps,
  CreatePackagingContextProviderProps,
  MeasurementUnitProps,
  DuplicateErrorObjectProps,
  ComponentDimensionDictionary,
} from "./types";
import { prepareDuplicatedComponent } from "../components/CreatePackagingSystem/utils";

const initialPackagingcomponents = [
  {
    componentTypeId: null,
    componentId: null,
    diameter: { unitId: "", value: undefined },
    height: { unitId: "", value: undefined },
    id: v4(),
    length: { unitId: "", value: undefined },
    name: "",
    printableComponent: false,
    usageLevel: "Primary",
    weight: { unitId: "", value: undefined },
    width: { unitId: "", value: undefined },
  },
];

const initialComponents = [...initialPackagingcomponents];

const CreatePackagingContext = createContext<CreatePackagingProps | undefined>({
  isLoadingList: false,
  setIsLoading: () => {},
  types: [],
  setTypesOptions: () => {},
  unitOfMeasureLength: [],
  setUnitOfMeasureLengthOptions: () => {},
  unitOfMeasureMass: [],
  setUnitOfMeasureMassOptions: () => {},
  components: [...initialComponents],
  setComponents: () => {},
  name: "",
  setpsName: () => {},
  activeCollapse: null,
  setActiveCollapse: () => {},
  isAssociating: false,
  setIsAssociating: () => {},
  isEditmode: false,
  setIsEditMode: () => {},
  psNameError: null,
  setPsNameError: () => {},
  duplicateComponentNames: [],
  setDuplicateComponentNames: () => {},
  duplicateComponentNamesFromApi: [],
  setDuplicateComponentNamesFromApi: () => {},
  duplicateErrorObject: null,
  setDuplicateErrorObject: () => {},
  componentDimensionDictionary: {},
  setComponentDimensionDictionary: () => ({}),
  onHandleChange: () => {},
  onDelete: () => {},
  onDuplicate: () => {},
  onEdit: () => {},
  isComponentPrintable: () => {},
  getFields: () => ({}),
  addComponent: () => {},
  updateUnitId: () => {},
  updateUnitValue: () => {},
});

export const CreatePackagingContextProvider: React.FC<
  CreatePackagingContextProviderProps
> = ({ children }) => {
  const [isLoadingList, setIsLoading] = useState<boolean>(false);
  const [types, setTypesOptions] = useState<
    {
      value: string;
      name: string;
      title: "";
    }[]
  >([]);
  const [unitOfMeasureLength, setUnitOfMeasureLengthOptions] = useState<any[]>(
    []
  );
  const [unitOfMeasureMass, setUnitOfMeasureMassOptions] = useState<
    MeasurementUnitProps[]
  >([]);

  const [components, setComponents] = useState<any>(initialComponents);
  const [name, setpsName] = useState<string>("");
  const [activeCollapse, setActiveCollapse] = useState<number | string>(
    initialPackagingcomponents[0]?.id
  );
  const [isAssociating, setIsAssociating] = useState<boolean>(false);
  const [isEditmode, setIsEditMode] = useState<boolean>(false);
  const [psNameError, setPsNameError] = useState<string>(null);
  const [duplicateComponentNames, setDuplicateComponentNames] = useState<
    string[]
  >([]);
  const [duplicateComponentNamesFromApi, setDuplicateComponentNamesFromApi] =
    useState<string[]>([]);
  const [duplicateErrorObject, setDuplicateErrorObject] =
    useState<DuplicateErrorObjectProps>(null);
  const [componentDimensionDictionary, setComponentDimensionDictionary] =
    useState<ComponentDimensionDictionary>({});

  const onHandleChange = ({ value, name, index }) => {
    setComponents(prevComponents => {
      const updatedComponents = [...prevComponents];
      updatedComponents[index] = {
        ...updatedComponents[index],
        [name]: value,
      };
      return updatedComponents;
    });
  };

  const onDelete = index => {
    setComponents(prevComponents => {
      const updatedComponents = prevComponents.filter(
        (_, currentIndex) => currentIndex !== index
      );
      return updatedComponents;
    });
  };

  const onDuplicate = component => {
    if (component !== undefined) {
      try {
        setComponents(prevComponents => {
          const duplicatedItem = prepareDuplicatedComponent(prevComponents, {
            ...component,
            componentId: null,
          });
          setActiveCollapse(duplicatedItem?.id);
          return [...prevComponents, duplicatedItem];
        });
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    }
  };

  const onEdit = component => {
    const id =
      component?.id?.length > 0 ? component?.id : component?.componentId;
    setActiveCollapse(id);
  };

  const isComponentPrintable = (checked, index) => {
    const newData = components.map((item, i) => {
      if (i === index) {
        if (checked === true) {
          return {
            ...item,
            printableComponent: true,
          };
        }
        if (checked === false) {
          return {
            ...item,
            printableComponent: false,
          };
        }
      }
      return item;
    });
    setComponents(newData);
  };

  const getFields = () => {
    const comp = components.map(component => [
      {
        name: "psName",
        value: name,
      },
      {
        name: `${component.id}.componentName`,
        value: component.name,
      },
      {
        name: `${component.id}.type`,
        value: component.componentTypeId,
      },
      {
        name: `${component.id}.height`,
        value: component.height,
      },
      {
        name: `${component.id}.length`,
        value: component.length,
      },
      {
        name: `${component.id}.weight`,
        value: component.weight,
      },
      {
        name: `${component.id}.width`,
        value: component.width,
      },
      {
        name: `${component.id}.diameter`,
        value: component.diameter,
      },
      {
        name: `${component.id}.printableComponent`,
        value: component.printableComponent,
      },
    ]);

    return comp[0];
  };

  const addComponent = () => {
    setComponents(prevComponent => {
      const component = [...prevComponent];
      const updatedItems = [
        ...component,
        { ...initialPackagingcomponents[0], id: v4() },
      ];
      setActiveCollapse(updatedItems[updatedItems.length - 1]?.id);
      return updatedItems;
    });
  };

  const updateUnitId = (unit, key, index) => {
    setComponents(prevComponents => {
      const updatedComponents = prevComponents.map((data, iterator) => {
        if (index === iterator) {
          return {
            ...data,
            [key]: { ...data[key], unitId: unit },
          };
        }
        return data;
      });
      return updatedComponents;
    });
  };

  const updateUnitValue = (value, key, index) => {
    setComponents(prevComponents => {
      const updatedComponents = prevComponents.map((data, iterator) => {
        if (index === iterator) {
          return {
            ...data,
            [key]: { ...data[key], value },
          };
        }
        return data;
      });
      return updatedComponents;
    });
  };

  const contextValue: CreatePackagingProps = {
    isLoadingList,
    setIsLoading,
    types,
    setTypesOptions,
    unitOfMeasureLength,
    setUnitOfMeasureLengthOptions,
    unitOfMeasureMass,
    setUnitOfMeasureMassOptions,
    components,
    setComponents,
    name,
    setpsName,
    activeCollapse,
    setActiveCollapse,
    isAssociating,
    setIsAssociating,
    isEditmode,
    setIsEditMode,
    psNameError,
    setPsNameError,
    duplicateComponentNames,
    setDuplicateComponentNames,
    duplicateComponentNamesFromApi,
    setDuplicateComponentNamesFromApi,
    duplicateErrorObject,
    setDuplicateErrorObject,
    componentDimensionDictionary,
    setComponentDimensionDictionary,
    onHandleChange,
    onDelete,
    onDuplicate,
    onEdit,
    isComponentPrintable,
    getFields,
    addComponent,
    updateUnitId,
    updateUnitValue,
  };

  return (
    <CreatePackagingContext.Provider value={contextValue}>
      {children}
    </CreatePackagingContext.Provider>
  );
};

export const useCreatePackagingContext = (): CreatePackagingProps => {
  const context = useContext(CreatePackagingContext);
  return context;
};
