import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "shared.business.modalAskAccess.title",
    defaultMessage: "Ask for an access to Trace One solutions",
  },
  subtitle: {
    id: "shared.business.modalAskAccess.subtitle",
    defaultMessage: "Which solutions would you like to access ?",
  },
  requestMessage: {
    id: "shared.business.modalAskAccess.requestMessage",
    defaultMessage: "Write a message here",
  },
  requestMessageMandatoryError: {
    id: "shared.business.modalAskAccess.requestMessageMandatoryError",
    defaultMessage: "Message is mandatory for sending request.",
  },
  requestMessageMaxError: {
    id: "shared.business.modalAskAccess.requestMessageMaxError",
    defaultMessage:
      "You have reached your maximum limit of characters allowed.",
  },
  requestAppsError: {
    id: "shared.business.modalAskAccess.requestAppsError",
    defaultMessage: "At least one application is required.",
  },
  send: {
    id: "shared.business.modalAskAccess.send",
    defaultMessage: "Send",
  },
  notificationTitleConfirmation: {
    id: "shared.business.modalAskAccess.notification.title.confirmation",
    defaultMessage: "Confirmation",
  },
  notificationTitleAlert: {
    id: "shared.business.modalAskAccess.notification.title.alert",
    defaultMessage: "Error",
  },

  notificationSubtitleConfirmation: {
    id: "shared.business.modalAskAccess.notification.subtitle.confirmation",
    defaultMessage:
      "Your request has been successfully received. We will get back to you shortly.",
  },
  notificationSubtitleAlert: {
    id: "shared.business.modalAskAccess.notification.subtitle.alert",
    defaultMessage:
      "An error occurred while sending the request. Please try again later.",
  },
});

export default messages;
