import React from "react";
import { FormattedMessage } from "react-intl";
import { Divider, Col, Row } from "antd";
import { Heading } from "@trace-one/design-system";
import { isListEmpty } from "src/utils/general";
import { ResultTableProps } from "./types";
import { notificationSettingsMessages } from "../../translations/messages";
import Result from "./components/Result";
import NoResult from "./components/NoResult";

export const ResultTable: React.FC<ResultTableProps> = ({
  notificationPreferences,
  onChangeNotificationCommunicationMethod,
  onChangeNotificationDigest,
  isDigestAvailable,
}) => {
  return (
    <div className="notification-settings-container-table">
      <Row>
        <Col span={isDigestAvailable ? 12 : 14}>
          <Heading size="xxs">
            <FormattedMessage {...notificationSettingsMessages.typeTitle} />
          </Heading>
        </Col>
        <Col span={isDigestAvailable ? 4 : 5}>
          <Heading size="xxs">
            <FormattedMessage {...notificationSettingsMessages.inAppTitle} />
          </Heading>
        </Col>
        <Col span={isDigestAvailable ? 4 : 5}>
          <Heading size="xxs">
            <FormattedMessage
              {...notificationSettingsMessages.emailNotifTitle}
            />
          </Heading>
        </Col>
        {isDigestAvailable && (
          <Col span={4}>
            <Heading size="xxs">
              <FormattedMessage
                {...notificationSettingsMessages.emailDigestTitle}
              />
            </Heading>
          </Col>
        )}
        <Col
          span={24}
          className="notification-settings-container-table-divider"
        >
          <Divider />
        </Col>
      </Row>
      {!isListEmpty(notificationPreferences)
        ? (
        <Result
          notificationPreferences={notificationPreferences}
          onChangeNotificationCommunicationMethod={
            onChangeNotificationCommunicationMethod
          }
          onChangeNotificationDigest={onChangeNotificationDigest}
          isDigestAvailable={isDigestAvailable}
        />
          )
        : (
        <NoResult />
          )}
    </div>
  );
};

export default ResultTable;
