import { useEffect, useMemo, useState } from "react";
import { getBrandsByOwnerCompanyId } from "src/apis/pmd";

const useBrands = ({ ownerCompanyId }) => {
  const [brands, setBrands] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getBrands = async () => {
    try {
      setIsLoading(true);
      const res = await getBrandsByOwnerCompanyId({ ownerCompanyId });
      setBrands(res.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getBrands();
  }, [ownerCompanyId]);

  const brandOptions = useMemo(() => {
    return brands
      .filter(item => item.isActive)
      .map(({ id, brandName, isActive }) => ({
        label: `${brandName} - ${isActive ? "Active" : "In Active"}`,
        value: id,
        searchLabel: brandName,
      }));
  }, [brands]);

  const searchBrandByName = (str: string) => {
    const filteredBrands = brands.filter(option => {
      return option.brandName.toLowerCase().includes(str.toLowerCase());
    });

    return filteredBrands.map(({ id, brandName, isActive }) => ({
      label: `${brandName} - ${isActive ? "Active" : "In Active"}`,
      value: id,
      searchLabel: brandName,
    }));
  };

  return {
    isLoading,
    brandOptions,
    brands,
    searchBrandByName,
  };
};

export default useBrands;
