import React from "react";
import { Search, Button } from "@trace-one/design-system";
import classNames from "classnames";

import { AllFiltersPanelPortal } from "./components/AllFilters/Modal";
import useAllFiltersPanelOpen from "./hooks/useAllFiltersPanelOpen";
import { getPrefixClassName } from "src/utils/general";
import { withLanguageData } from "src/hocs";

import frFr from "./translations/fr-FR.json";
import enUs from "./translations/en-US.json";
import deDe from "./translations/de-DE.json";
import esEs from "./translations/es-ES.json";
import ptPt from "./translations/pt-PT.json";
import itIt from "./translations/it-IT.json";
import nlBe from "./translations/nl-BE.json";
import { LangList } from "../../../utils/const";
import { useLanguagePreference } from "src/hooks";

import { useIntl } from "react-intl";
import { searchFilterActions } from "./translations/defineMessage";
import "./style.less";
import { FilterProps } from "./types";

const DEFAULT_LANG = enUs;

const enhance = withLanguageData({
  translations: {
    [LangList.EN_US]: enUs,
    [LangList.FR_FR]: { ...DEFAULT_LANG, ...frFr },
    [LangList.DE_DE]: { ...DEFAULT_LANG, ...deDe },
    [LangList.ES_ES]: { ...DEFAULT_LANG, ...esEs },
    [LangList.PT_PT]: { ...DEFAULT_LANG, ...ptPt },
    [LangList.IT_IT]: { ...DEFAULT_LANG, ...itIt },
    [LangList.NL_BE]: { ...DEFAULT_LANG, ...nlBe },
  },
});

const prefixCls = getPrefixClassName("search-filter");

const Filter: React.FC<FilterProps> = ({
  history = { listen: () => {} },
  onAllFilterPanelToggle = () => {},
  onSave,
  filters = [],
  selectedFilters = [],
  clearAllFilters,
  search,
  languageCode,
  hideAllFilters,
  "data-test-id": dataTestId,
}) => {
  const languageCodePreference = useLanguagePreference();
  languageCode = languageCode ?? languageCodePreference;
  const { formatMessage } = useIntl();
  const { open, setOpen, isNodeMounted, nodeRef } = useAllFiltersPanelOpen(
    history,
    onAllFilterPanelToggle
  );

  if (!isNodeMounted) {
    return null;
  }

  const activeFiltersCount = selectedFilters.filter(
    sf => sf.values?.length
  ).length;

  return (
    <>
      <div
        data-test-id={dataTestId || "bs-search-filter"}
        className={classNames(prefixCls, `${prefixCls}-filter-container`)}
      >
        <Search {...search}></Search>
        <div className={`${prefixCls}-filter-actions-container`}>
          <div className={`${prefixCls}-quick-filters-container`}>
            {filters
              .filter(f => f.quickFilter)
              .map(qf => {
                return (
                  <div
                    key={`quickfilter-${qf.filterId}`}
                    className={`${prefixCls}-quick-filter-widget-container`}
                  >
                    {qf.quickFilter}
                  </div>
                );
              })}
          </div>

          {!hideAllFilters && (
            <Button
              data-test-id="allfilters-panel-trigger-btn"
              type="tertiary"
              onClick={() => setOpen(true)}
            >
              {formatMessage(searchFilterActions.allFiltersHeaderTitle)}{" "}
              {activeFiltersCount > 0 && `(${activeFiltersCount})`}
            </Button>
          )}
          {activeFiltersCount > 0 && (
            <Button
              data-test-id="clear-filters"
              type="link"
              linkColor="grey-5"
              onClick={() => clearAllFilters()}
            >
              {formatMessage(searchFilterActions.actionClearAll)}
            </Button>
          )}
        </div>
      </div>
      <AllFiltersPanelPortal
        isOpen={open}
        onSave={onSave}
        setIsOpen={setOpen}
        node={nodeRef.current}
        selectedFilters={selectedFilters}
        filters={filters}
        clearAllFilters={clearAllFilters}
      ></AllFiltersPanelPortal>
    </>
  );
};

export default enhance(Filter);
