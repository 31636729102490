import classNames from "classnames";
import React, { useState } from "react";
import { getPrefixClassName } from "src/utils/general";
import AllFiltersSidebar from "../Sidebar";
import MultiSelectionSidebar from "../Sidebar/MultiSelectionSidebar";
import {
  AllFiltersPanelModalProps,
  FilterItem,
} from "src/components/SearchFilter/types";

const prefixCls = getPrefixClassName("search-filter");

export const AllFiltersPanelModal: React.FC<AllFiltersPanelModalProps> = ({
  isOpen,
  filters,
  setIsOpen,
  selectedFilters,
  clearAllFilters,
  onSave,
}) => {
  const closeAllFiltersPanel = () => {
    setIsOpen(false);
  };

  const onOverlayClick = () => {
    closeAllFiltersPanel();
    setIsMultiSelectPanelOpen(false);
  };

  const [isMultiSelectPanelOpen, setIsMultiSelectPanelOpen] = useState(false);
  const [currentMultiSelectFilter, setCurrentMultiSelectFilter] =
    useState<FilterItem | null>(null);

  const closeMultiSelectionSideBar = () => {
    setIsMultiSelectPanelOpen(false);
  };

  const openMultiSelectionSideBar = (filter: FilterItem) => {
    setCurrentMultiSelectFilter(filter);
    setIsMultiSelectPanelOpen(true);
  };

  return (
    <div
      onClick={onOverlayClick}
      data-test-id="allfilters-overlay"
      className={classNames(
        prefixCls,
        `${prefixCls}-allfilters-overlay`,
        `${prefixCls}-allfilters-overlay-${isOpen ? "open" : "close"}`
      )}
    >
      <AllFiltersSidebar
        filters={filters}
        isOpen={isOpen}
        onSave={onSave}
        closeAllFiltersPanel={closeAllFiltersPanel}
        selectedFilters={selectedFilters}
        openMultiSelectionSideBar={openMultiSelectionSideBar}
        clearAllFilters={clearAllFilters}
      />

      <MultiSelectionSidebar
        filter={currentMultiSelectFilter}
        isOpen={isOpen && isMultiSelectPanelOpen}
        closeMultiSelectionSideBar={closeMultiSelectionSideBar}
      />
    </div>
  );
};

export default AllFiltersPanelModal;
