import React from "react";
import { BasketProps } from "./types";
import { Heading } from "@trace-one/design-system";

import { getPrefixClassName } from "src/utils/general";
import "./styles.less";
import EmptyBasket from "./components/EmptyBasket";
import BasketWithItems from "./components/BasketWithItems";

const prefixClass = getPrefixClassName("basket");

const Basket: React.FC<BasketProps> = ({
  items = [],
  title = "",
  emptyNote = "",
  validateButtonText = "Validate",
  onDelete = () => {},
  onValidate = () => {},
  validateButtonDisabled = false,
}) => {
  return (
    <div className={prefixClass}>
      <Heading size="xs">{title}</Heading>
      <div className={`${prefixClass}-card`}>
        {items.length === 0 && <EmptyBasket emptyNote={emptyNote} />}
        {items.length > 0 && (
          <BasketWithItems
            validateButtonText={validateButtonText}
            onValidate={onValidate}
            onDelete={onDelete}
            items={items}
            validateButtonDisabled={validateButtonDisabled}
          />
        )}
      </div>
    </div>
  );
};

export default Basket;
