import React from "react";
import { EmptyState } from "@trace-one/design-system";
import { Row } from "antd";
import { FormattedMessage } from "react-intl";
import { notificationSettingsMessages } from "../../../../translations/messages";

export const NoResult = () => {
  return (
    <Row
      className="notification-settings-container-table-empty-state"
      justify="center"
    >
      <EmptyState
        heading={<FormattedMessage {...notificationSettingsMessages.noData} />}
      />
    </Row>
  );
};

export default NoResult;
