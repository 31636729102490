import React from "react";
import { getPrefixClassName } from "src/utils/general";
import { EmptyState } from "@trace-one/design-system";
import { EmptyBasketProps } from "../../types";

const prefixClass = getPrefixClassName("basket");

const EmptyBasket: React.FC<EmptyBasketProps> = ({ emptyNote = "" }) => {
  return (
    <div
      data-test-id="bs-empty-basket"
      className={`${prefixClass}--empty-basket`}
    >
      <EmptyState description={emptyNote} />
    </div>
  );
};

export default EmptyBasket;
