import { AxiosInstancesManager } from "src/configs/AxiosInstancesManager";

export const sendAppAccessNotif = async ({
  requestApps,
  requestMessage,
  userId,
  langcode,
}: {
  requestApps: string[];
  requestMessage: string;
  userId: string;
  langcode: string;
}) => {
  const tonInstance = AxiosInstancesManager.getTon();

  return await tonInstance.post(`/api/notification/ask-app-access`, {
    appList: requestApps,
    message: requestMessage,
    userGuid: userId,
    langcode: langcode,
  });
};
