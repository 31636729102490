import React, { useEffect, useRef } from "react";
import classnames from "classnames";
import { useLocation } from "react-router-dom";
import { withLanguageData } from "src/hocs";
import { getPrefixClassName } from "src/utils/general";
import AppInfo from "./components/AppInfo";
import RightMenu from "./components/RightMenu";
import HeaderLink from "./components/HeaderLink";
import useHeaderLinks from "./hooks/useHeaderLinks";
import useMenuVisibility from "./hooks/useMenuVisibility";
import { HeaderProps } from "./types";
import "./styles.less";

import enUs from "./translations/en-US.json";
import frFr from "./translations/fr-FR.json";
import { LangList } from "../../../utils/const";
import RedirectBanner from "./components/RedirectBanner";
import StickyInfoBanner from "./components/StickyInfoBanner";

const DEFAULT_LANG = enUs;

const enhance = withLanguageData({
  translations: {
    [LangList.EN_US]: enUs,
    [LangList.FR_FR]: { ...DEFAULT_LANG, ...frFr },
  },
});

const Header: React.FC<HeaderProps> = ({
  appName,
  appLogo,
  homeUrl,
  displayAdminUserAndAccessRoute,
  adminUserAndAccessUrl,
  adminSubRoutes,
  routes,
  extraAboveAppLogo,
  appSwitcherProps,
  buttonProps,
  helpLinks,
  userId,
  onLogout,
  isTon,
  messageIcon,
  redirectBanner,
  stickyBanner,
  isNameShown,
  isMasquerade,
}) => {
  const prefixCls = getPrefixClassName("header");
  const { pathname } = useLocation();
  const { navList, getOnMainNavClick } = useHeaderLinks({
    routes,
    displayAdminUserAndAccessRoute,
    adminUserAndAccessUrl,
    adminSubRoutes,
    pathname,
  });

  const menuRef = useRef();
  const burgerIconRef = useRef();

  const { isMenuVisible, handleOutsideMenuClick, handleMenuToggle } =
    useMenuVisibility({ menuRef, burgerIconRef });

  useEffect(() => {
    const handleMousedown = handleOutsideMenuClick;

    // @ts-ignore
    document.addEventListener("mousedown", handleMousedown);
    return () => {
      // @ts-ignore
      document.removeEventListener("mousedown", handleMousedown);
    };
  }, []);

  return (
      <header className={prefixCls}>
        <StickyInfoBanner {...stickyBanner} />
        <RedirectBanner {...redirectBanner} />
        <div className={`${prefixCls}-container`}>
          <AppInfo
            appLogo={appLogo}
            appName={appName}
            extraAboveAppLogo={extraAboveAppLogo}
            homeUrl={homeUrl}
            handleMenuToggle={handleMenuToggle}
            burgerIconRef={burgerIconRef}
          />
          <ul
            data-test-id="nav-list"
            className={classnames(`${prefixCls}-menu`, {
              isVisible: isMenuVisible,
            })}
            ref={menuRef}
          >
            {navList.map((headerLinkProps, index) => {
              return (
                <HeaderLink
                  key={headerLinkProps.text as string}
                  index={index}
                  getOnMainNavClick={getOnMainNavClick}
                  {...headerLinkProps}
                />
              );
            })}
          </ul>
          <RightMenu
            appSwitcherProps={appSwitcherProps}
            buttonProps={buttonProps}
            helpLinks={helpLinks}
            userId={userId}
            onLogout={onLogout}
            isTon={isTon}
            messageIcon={messageIcon}
            isNameShown={isNameShown}
            isMasquerade={isMasquerade}
          />
        </div>
      </header>
  );
};

Header.displayName = "Header";

Header.defaultProps = {
  homeUrl: "/",
  routes: [],
  adminSubRoutes: [],
  messageIcon: null,
};

export default enhance(Header);
