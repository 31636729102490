import React, { useEffect, useState } from "react";
import { Form } from "antd";
import { useIntl, FormattedMessage } from "react-intl";
import { debounce, isEqual } from "lodash";
import { Icon, Input, Spinner } from "@trace-one/design-system";
import {
  updatePackagingSystem,
  createPackagingSystem,
  updatePackagingComponent,
  createPackagingComponent,
} from "src/apis/pkg";
import { createPackagingMessage } from "../../translations/messages";
import { getDuplicateComponentNames } from "./utils";
import { CreatePackagingSystemProps } from "./types";
import usePackagingSystem from "../../hooks/usePackagingSystem/usePackagingSystem";
import PackagingSystemCreationSummary from "../Summary";
import SelectPackagingComponent from "../SelectPackagingComponent/SelectPackagingComponent";
import ComponentItem from "../ComponentItems";
import "./styles.less";
import { useCreatePackagingContext } from "../../context";
import { useModalVisibility } from "src/hooks";
import ExistingPackagingComponentsModal from "../ExtistingPackagingComponentsModal/index";
import { isListEmpty, isObjectEmpty } from "src/utils/general";
import { ComponentsProps } from "../SelectPackagingComponent/types";
import { setComponentData } from "../../utils/helpers";

const CreatePackagingSystem: React.FC<CreatePackagingSystemProps> = ({
  getRefLists,
  onAssociate,
  packagingSystemId,
  onEditAndAssociate,
  closePackagingSystemCreation,
  ownerCompanyId,
  languageCode,
  getPackagingComponentStructure,
  multiple = true,
}) => {
  const [allowPackagingSystemUpdate, setAllowPackagingSystemUpdate] =
    useState<boolean>(true);
  const intl = useIntl();
  const [form] = Form.useForm();
  const {
    isLoadingList,
    setIsLoading,
    setTypesOptions,
    unitOfMeasureLength,
    setUnitOfMeasureLengthOptions,
    unitOfMeasureMass,
    setUnitOfMeasureMassOptions,
    components,
    setComponents,
    name,
    setpsName,
    setIsAssociating,
    isEditmode,
    setIsEditMode,
    psNameError,
    setPsNameError,
    duplicateComponentNames,
    setDuplicateComponentNames,
    duplicateComponentNamesFromApi,
    setDuplicateComponentNamesFromApi,
    duplicateErrorObject,
    setDuplicateErrorObject,
    getFields,
    addComponent,
    setActiveCollapse,
    componentDimensionDictionary,
    setComponentDimensionDictionary,
  } = useCreatePackagingContext();

  const { isModalOpen, onCloseModal, onOpenModal } = useModalVisibility();
  const getPrefixClassName = "business-components-create-packaging";

  const {
    packagingSystem,
    isLoading,
    setComponentValue,
    updatedRecords,
    setUpdatedRecords,
  } = usePackagingSystem({
    packagingSystemId,
    setComponents,
  });

  useEffect(() => {
    setPsNameError(
      duplicateErrorObject?.packagingSystem?.toUpperCase() ===
        name?.trim().toUpperCase()
        ? intl.formatMessage(
          createPackagingMessage.packagingSystemDuplicateName
        )
        : null
    );
  }, [name, duplicateErrorObject?.packagingSystem]);

  useEffect(() => {
    setDuplicateComponentNamesFromApi([
      ...(duplicateErrorObject?.packagingComponents || []),
    ]);
  }, [duplicateErrorObject?.packagingComponents]);

  useEffect(() => {
    if (packagingSystem) {
      const { name, components, allowsUpdate } = packagingSystem;
      setAllowPackagingSystemUpdate(allowsUpdate);
      setpsName(name);
      setComponents(components);
      setActiveCollapse(packagingSystem?.components[0]?.componentId);
      setIsEditMode(true);
    }
  }, [packagingSystem]);

  useEffect(() => {
    if (unitOfMeasureLength?.length === 0 && unitOfMeasureMass?.length === 0) {
      getRefLists(
        setIsLoading,
        setTypesOptions,
        setUnitOfMeasureLengthOptions,
        setUnitOfMeasureMassOptions
      );
    }
  }, []);

  useEffect(() => {
    if (isObjectEmpty(componentDimensionDictionary)) {
      getPackagingComponentStructure(setComponentDimensionDictionary);
    }
  }, []);

  useEffect(() => {
    if (!isListEmpty(components)) {
      debouncedCheck();
    }

    return () => debouncedCheck.cancel();
  }, [components]);

  useEffect(() => {
    if (!isLoadingList && !packagingSystem && components.length === 0) {
      addComponent();
    }
  }, [isLoadingList]);

  const debouncedCheck = debounce(() => {
    setDuplicateComponentNames([
      ...(getDuplicateComponentNames(components) || []),
    ]);
  }, 400);

  const onValidate = async () => {
    try {
      await form.validateFields();
      isEditmode ? await editAndAssociate() : await createAndAssociate();
    } catch (error) {
      console.error("Form validation failed:", error);
    }
  };

  const getValidComponent = component => {
    const isValid = value => {
      return (
        value?.value &&
        value?.value !== "" &&
        value?.unitId &&
        value?.unitId !== ""
      );
    };
    const newComponent = { ...component };
    if (!isValid(newComponent.height)) newComponent.height = null;
    if (!isValid(newComponent.weight)) newComponent.weight = null;
    if (!isValid(newComponent.length)) newComponent.length = null;
    if (!isValid(newComponent.width)) newComponent.width = null;
    if (!isValid(newComponent.diameter)) newComponent.diameter = null;
    newComponent.name = newComponent.name.trim();

    return newComponent;
  };

  const getCleanedComponents = (): ComponentsProps[] => {
    return components.map(component => {
      return {
        ...component,
        ...getValidComponent(component),
      };
    });
  };

  const isPrimaryButtonDisabled = !(
    !psNameError &&
    isListEmpty(duplicateComponentNames) &&
    isListEmpty(
      components?.filter(
        component =>
          duplicateComponentNamesFromApi.findIndex(
            nameFromApi =>
              nameFromApi.toUpperCase() === component?.name.trim().toUpperCase()
          ) !== -1
      )
    )
  );

  const createNewPackagingComponents = async () => {
    let hasError: boolean = false;
    const pkgComponents = getCleanedComponents();

    const promises = pkgComponents.map(component => {
      const { id, ...payload } = component;

      return component.componentId
        ? Promise.resolve(component.componentId)
        : createPackagingComponent({
          component: payload,
          setDuplicateErrorObject,
        });
    });

    const results = await Promise.allSettled(promises);

    const updatedComponents = results?.map((result, index) => {
      if (result.status === "fulfilled") {
        const updatedComponent = {
          ...pkgComponents[index],
          componentId: result.value,
        };

        setUpdatedRecords(prevState => {
          const idExists = prevState.some(
            comp => comp.componentId === updatedComponent.componentId
          );

          if (idExists) {
            const updatedRecords = prevState.map(comp => {
              if (comp.componentId === updatedComponent.componentId) {
                return setComponentData(updatedComponent);
              }
              return comp;
            });

            return updatedRecords;
          }

          return [...prevState, setComponentData(updatedComponent)];
        });

        return updatedComponent;
      } else {
        return pkgComponents[index];
      }
    });

    setComponents(updatedComponents);

    hasError = results.some(result => result.status === "rejected");

    if (hasError) {
      return [];
    }

    return (
      updatedComponents
        ?.filter(component => component.componentId)
        .map(component => component.componentId) || []
    );
  };

  const updatePackagingComponents = async () => {
    let hasError: boolean = false;
    const pkgComponents = getCleanedComponents();

    const promises = pkgComponents
      ?.filter(component => component.componentId)
      ?.filter(component => {
        const record = updatedRecords.find(
          item => item.componentId === component.componentId
        );

        const hasDataChanged = !isEqual(setComponentData(component), record);

        return hasDataChanged;
      })
      .map(component =>
        updatePackagingComponent({
          component,
          setDuplicateErrorObject,
          setUpdatedRecords,
        })
      );

    const results = await Promise.allSettled(promises);

    hasError = results.some(result => result.status === "rejected");

    if (hasError) {
      return false;
    }

    return true;
  };

  const updateAndCreatePackagingComponents = async () => {
    const arePackagingComponentsUpdated = await updatePackagingComponents();

    const componentIds = await createNewPackagingComponents();

    if (!arePackagingComponentsUpdated || isListEmpty(componentIds)) return [];

    return componentIds;
  };

  const editAndAssociate = async () => {
    setIsAssociating(true);
    try {
      const componentIds = await updateAndCreatePackagingComponents();

      if (isListEmpty(componentIds)) return;

      await updatePackagingSystem({
        id: packagingSystemId,
        name: name.trim(),
        componentIds,
        setDuplicateErrorObject,
      });
      await onEditAndAssociate();
    } catch {
    } finally {
      setIsAssociating(false);
    }
  };

  const createAndAssociate = async () => {
    setIsAssociating(true);
    try {
      const componentIds = await updateAndCreatePackagingComponents();

      if (isListEmpty(componentIds)) return;

      const psId = await createPackagingSystem({
        name: name.trim(),
        componentIds,
        setDuplicateErrorObject,
      });

      if (!psId) return;

      onAssociate([psId]);
    } catch {
    } finally {
      setIsAssociating(false);
    }
  };

  const onSelect = async newComponents => {
    setComponentValue(newComponents);
  };

  if (isLoading) {
    return (
      <div className="contentLoading">
        <Spinner />
      </div>
    );
  }

  return (
    <div
      className={`${getPrefixClassName}`}
      data-test-id="modalAssociateCreation"
    >
      <div className={`${getPrefixClassName}-container`}>
        <div className={`${getPrefixClassName}-container-header`}>
          <h2 className={`${getPrefixClassName}-title`}>
            <FormattedMessage
              {...createPackagingMessage[isEditmode ? "editTitle" : "title"]}
            />
          </h2>
          <p className={`${getPrefixClassName}-sub-title`}>
            <FormattedMessage {...createPackagingMessage.subTitle} />
          </p>
        </div>
        <div className={`${getPrefixClassName}-close-Packagin-System-Creation`}>
          <Icon
            size="large"
            name="close"
            color="primary"
            onClick={closePackagingSystemCreation}
          />
        </div>
      </div>
      <Form
        name="createPackagingSystem"
        scrollToFirstError
        form={form}
        validateMessages={{
          required: intl.formatMessage(createPackagingMessage.mandatoryPsname),
        }}
        fields={getFields()}
        validateTrigger="onSubmit"
        disabled={!allowPackagingSystemUpdate}
      >
        <Form.Item
          name="psName"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <div className={`${getPrefixClassName}-psContainer`}>
            <div className={`${getPrefixClassName}-psName`}>
              {intl.formatMessage(createPackagingMessage.psName)}
              <span className="asterick">*</span>
            </div>
            <Input
              placeholder={intl.formatMessage(
                createPackagingMessage.psNamePlaceholder
              )}
              maxLength={250}
              data-test-id="packaging-system-name"
              defaultValue={name}
              onChange={element => {
                setpsName(element.target.value);
              }}
              error={!!psNameError}
              errorMessage={psNameError}
            />
          </div>
        </Form.Item>

        <div className="associate-contents" data-test-id="associate-Contents">
          <div
            className="associate-packaging-system"
            data-test-id="associate-packaging-sysytem"
          >
            <SelectPackagingComponent
              addComponent={addComponent}
              components={components}
              className={getPrefixClassName}
              onOpenModal={onOpenModal}
              allowPackagingSystemUpdate={allowPackagingSystemUpdate}
            />
            {components.map((component, index) => (
              <ComponentItem
                component={component}
                index={index}
                duplicateNames={[
                  ...duplicateComponentNamesFromApi,
                  ...duplicateComponentNames,
                ]}
                key={component?.id}
              />
            ))}
          </div>
          <div
            className="packaging-system-creation-summary"
            data-test-id="package-creation-summary"
          >
            <PackagingSystemCreationSummary
              isPrimaryButtonDisabled={isPrimaryButtonDisabled}
              onValidate={onValidate}
            />
          </div>
        </div>
      </Form>
      {isModalOpen && (
        <ExistingPackagingComponentsModal
          onCloseModal={onCloseModal}
          ownerCompanyId={ownerCompanyId}
          languageCode={languageCode}
          components={components}
          onSelect={onSelect}
          multiple={multiple}
        />
      )}
    </div>
  );
};

export default CreatePackagingSystem;
