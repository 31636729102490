import { LogoUrl } from "../../../../../utils/const";
import { getAppGraphicName } from "../../../../utils/graphics";
import React from "react";
import { NavLink } from "react-router-dom";
import { Graphic, Heading, Button } from "@trace-one/design-system";
import { getPrefixClassName } from "../../../../utils/general";
import { SidebarHeaderProps } from "../../types";

const SidebarHeader: React.FC<SidebarHeaderProps> = ({
  appName,
  appLogo,
  homeUrl,
  collapsed,
  onToggleCollapsed,
}) => {
  const prefixCls = getPrefixClassName("sidebar-navigation");

  const toggleCollapsed = () => {
    onToggleCollapsed(!collapsed);
  };

  return (
    <>
      <div className={`${prefixCls}-header`}>
        <NavLink to={homeUrl} className={`${prefixCls}-header-logo`}>
          <img src={LogoUrl} height={32} alt="logo" />
        </NavLink>
        <Button
          type="tertiary"
          iconOnly={true}
          onClick={toggleCollapsed}
          className={`${prefixCls}-header-toggle-button`}
          data-test-id={`${prefixCls}-header-toggle-button`}
          iconName={"arrow-double-" + (collapsed ? "right" : "left")}
          size="small"
        />
      </div>
      <div className={`${prefixCls}-active-app`}>
        <Graphic name={getAppGraphicName(appLogo)} size="medium-large" />
        <Heading size="xxs" color="primary">
          {appName}
        </Heading>
      </div>
    </>
  );
};

export default SidebarHeader;
