import { useHistory } from "react-router-dom";
import { USER_PROFILE_PATH } from "IndexComponents";

const useRedirects = () => {
  const history = useHistory();

  const redirectToMyProfile = () => {
    // @ts-ignore
    const url = `${window.env.TON_URL}/user-profile`;
    window.open(url, "_self");
  };

  const redirectToMySettings = () => {
    if (history) {
      history.push(USER_PROFILE_PATH.MY_SETTINGS);
    }
  };

  const redirectToNotificationSettings = () => {
    if (history) {
      history.push(USER_PROFILE_PATH.NOTIFICATION_SETTINGS);
    }
  };

  const redirectToAdminSettings = () => {
    // @ts-ignore
    const url = `${window.env.TON_URL}/company-parameters`;
    window.open(url, "_self");
  };

  const redirectToCompanyProfile = () => {
    // @ts-ignore
    const url = `${window.env.TON_URL}/company`;
    window.open(url, "_self");
  };

  const redirectToChangePassword = () => {
    // @ts-ignore
    const url = `${window.env.IDSV_URL}/user/setpassword?ReturnURL=${window.env.IDSV_URL}`;
    window.open(url, "_self");
  };

  const redirectToPrivacy = () => {
    // @ts-ignore
    const url = `${window.env.TON_URL}/user-parameters`;
    window.open(url, "_self");
  };

  const redirectToMyBookmarks = () => {
    // @ts-ignore
    const url = `${window.env.TON_URL}/bookmarks`;
    window.open(url, "_self");
  };

  return {
    redirectToMyProfile,
    redirectToMySettings,
    redirectToNotificationSettings,
    redirectToAdminSettings,
    redirectToCompanyProfile,
    redirectToChangePassword,
    redirectToPrivacy,
    redirectToMyBookmarks,
  };
};

export default useRedirects;
