import React, { useEffect, useMemo, useState } from "react";
import { FileCard } from "@trace-one/design-system";
import { ItemProps } from "./types";

const Item: React.FC<ItemProps> = ({
  fileId,
  fileName,
  uploadedAt,
  version,
  onSelectFile,
  userName,
  isSelected: isItemSelected,
  showFullToolTip = false,
  disabled = false,
}) => {
  const [name, type] = useMemo(() => {
    return fileName.split(".");
  }, [fileName]);

  const [isSelected, setIsSelected] = useState<boolean>(isItemSelected);

  useEffect(() => {
    onSelectFile(isSelected);
  }, [isSelected]);

  useEffect(() => {
    setIsSelected(isItemSelected);
  }, [isItemSelected]);

  return (
    <FileCard
      data-test-id="dms-file-card"
      key={fileId}
      fileName={name}
      fileType={type}
      uploadDate={new Date(uploadedAt)
        .toLocaleDateString("en-us", {
          day: "numeric",
          month: "short",
          year: "numeric",
        })
        .toString()}
      fileVersion={"V" + version.toString()}
      selected={isSelected}
      onClick={() => setIsSelected(previousState => !previousState)}
      userName={userName}
      disabled={disabled}
      showFullToolTip={showFullToolTip}
    />
  );
};

export default Item;
