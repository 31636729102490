import React, { createContext, useReducer, useEffect } from "react";
import initialState from "./initialState";
import reducer from "./reducer";
import { getAppsList } from "../utils/utils";
import { AskAccessModalState } from "../types";

export const AskAccessModalContext = createContext<AskAccessModalState>({
  languageCode: null,
  availableApps: [],
});

export const AskAccessModalContextProvider = ({ children, languageCode }) => {
  const [{ availableApps }, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    (async () => {
      dispatch({
        type: "SET_APPS",
        availableApps: await getAppsList({
          languageCode,
        }),
      });
    })();
  }, [languageCode]);

  return (
    <AskAccessModalContext.Provider
      value={{
        languageCode,
        availableApps,
      }}
    >
      {children}
    </AskAccessModalContext.Provider>
  );
};
