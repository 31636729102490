import { useEffect, useMemo, useState } from "react";
import { useLanguagePreference } from "src/hooks";
import moment from "moment";
import { getNotificationSettings } from "src/apis/notif";
import {
  NotificationPreference,
  NotificationStatus,
} from "src/models/notification";
import {
  NotificationFiltersQueryState,
  NotificationFilterTag,
} from "../../types";
import { useIntl } from "react-intl";
import { notificationsFilterMessage } from "../../translations/defineMessage";

const useNotificationsFilter = (
  filters: NotificationFiltersQueryState,
  setFilters: (value: NotificationFiltersQueryState) => void,
  selectedTab: string
) => {
  const languageCode = useLanguagePreference();
  const { formatMessage } = useIntl();
  const [isFiltersVisible, setIsFiltersVisible] = useState(false);

  const [notificationTypes, setNotificationTypes] = useState<
    NotificationPreference[]
  >([]);

  const toggleFiltersVisibility = () => {
    setIsFiltersVisible(isVisisble => !isVisisble);
  };

  const translateNotificationStatus = (status: NotificationStatus) => {
    if (status === NotificationStatus.UNREAD) {
      return formatMessage(notificationsFilterMessage.optionUnreadOnly);
    }

    if (status === NotificationStatus.READ) {
      return formatMessage(notificationsFilterMessage.optionReadOnly);
    }
  };

  const getNotificationFilterTags = (): NotificationFilterTag[] => {
    const filtersToDisplay = [];

    if (filters.status) {
      filtersToDisplay.push({
        filterType: "status",
        displayValue: translateNotificationStatus(filters.status),
        value: filters.status,
      });
    }

    if (
      filters.notificationEventUtcDateTimeAfter ||
      filters.notificationEventUtcDateTimeBefore
    ) {
      const {
        notificationEventUtcDateTimeAfter: from,
        notificationEventUtcDateTimeBefore: to,
      } = filters;

      const formateDate = (d: string) => moment(d).format("DD/MM/yyyy");

      let displayValue = "";
      if (from && to) {
        displayValue = `${formateDate(from)} - ${formateDate(to)}`;
      }

      if (from && !to) {
        displayValue = `${formatMessage(
          notificationsFilterMessage.dateAfter
        )}: ${formateDate(from)}`;
      }

      if (!from && to) {
        displayValue = `${formatMessage(
          notificationsFilterMessage.dateBefore
        )}: ${formateDate(to)}`;
      }

      filtersToDisplay.push({
        filterType: "dateFilter",
        displayValue,
        value: [
          filters.notificationEventUtcDateTimeAfter,
          filters.notificationEventUtcDateTimeBefore,
        ],
      });
    }

    if (filters.notificationTypeIds?.length > 0) {
      const notificationTypeIdMap = new Map(
        notificationTypes.map(type => [
          type.notificationTypeId,
          type.notificationTypeTitle,
        ])
      );

      filtersToDisplay.push(
        ...filters.notificationTypeIds.map(id => {
          return {
            filterType: "notificationType",
            displayValue: notificationTypeIdMap.get(id) || "",
            value: id,
          };
        })
      );
    }

    return filtersToDisplay;
  };

  useEffect(() => {
    getNotificationSettings({
      languageCode,
      senderZone: selectedTab.toLowerCase() === "all" ? undefined : selectedTab,
      level: "NotificationType",
      includeNotUserCustomizedNotifications: true,
    })
      .then(({ data }) => {
        const notificationTypes = data.notificationPreferences.filter(
          notificationSetting =>
            notificationSetting.level === "NotificationType"
        );
        setNotificationTypes(notificationTypes || []);
      })
      .catch(error => {
        console.error(error);
      });
  }, [languageCode, selectedTab]);

  const filtersToDisplay = useMemo(getNotificationFilterTags, [
    filters,
    languageCode,
    selectedTab,
    notificationTypes,
  ]);

  const removeFilterByTag = (
    item: NotificationFilterTag | NotificationFilterTag[]
  ) => {
    if (!Array.isArray(item)) {
      if (item.filterType === "status") {
        setFilters({ ...filters, status: undefined });
      }

      if (item.filterType === "dateFilter") {
        setFilters({
          ...filters,
          notificationEventUtcDateTimeAfter: null,
          notificationEventUtcDateTimeBefore: null,
        });
      }

      if (item.filterType === "notificationType") {
        setFilters({
          ...filters,
          notificationTypeIds: filters.notificationTypeIds.filter(
            typeId => typeId !== item.value
          ),
        });
      }
    } else {
      const newFilters = {
        ...filters,
        notificationTypeIds: filters.notificationTypeIds.slice(),
      };

      item.forEach(filterTag => {
        newFilters.notificationTypeIds = newFilters.notificationTypeIds.filter(
          typeId => typeId !== filterTag.value
        );
      });

      setFilters(newFilters);
    }
  };

  return {
    isFiltersVisible,
    toggleFiltersVisibility,
    notificationTypes,
    removeFilterByTag,
    filtersToDisplay,
  };
};

export default useNotificationsFilter;
