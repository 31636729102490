import React from "react";
import { TagV1 as Tag, Tooltip } from "@trace-one/design-system";

import "../../styles.less";

import { NotificationPanelSelectedFiltersProps } from "../../types";

const SelectedFilters: React.FC<NotificationPanelSelectedFiltersProps> = ({
  filtersToDisplay,
  removeFilterByTag,
}) => {
  let numberOfItemsToDisplay = 2;
  if (
    filtersToDisplay?.[0]?.filterType === "notificationType" ||
    filtersToDisplay?.[1]?.filterType === "notificationType"
  ) {
    // this is done to avoid relatively large filters to overflow the screen
    // by displaying only one item we are avoid that
    numberOfItemsToDisplay = 1;
  }

  if (!Array.isArray(filtersToDisplay)) {
    return null;
  }

  return (
    <div
      className="notifications-panel-selected-filter-tags"
      data-test-id="notifications-panel-selected-filter-tags"
    >
      {filtersToDisplay.slice(0, numberOfItemsToDisplay).map(filterItem => {
        return (
          <div
            key={
              filterItem.filterType === "notificationType"
                ? String(filterItem.value)
                : filterItem.displayValue
            }
          >
            <Tag
              onClose={() => {
                removeFilterByTag(filterItem);
              }}
              label={
                <span className="trim-selected-filter-tag-content">
                  <Tooltip
                    showFullText={true}
                    text={filterItem.displayValue}
                    size="small"
                  >
                    {filterItem.displayValue}
                  </Tooltip>
                </span>
              }
            ></Tag>
          </div>
        );
      })}
      {filtersToDisplay.slice(numberOfItemsToDisplay).length > 0 && (
        <Tag
          onClose={() => {
            removeFilterByTag(filtersToDisplay.slice(numberOfItemsToDisplay));
          }}
          label={
            <Tooltip
              showFullText={true}
              text={filtersToDisplay
                .slice(numberOfItemsToDisplay)
                .map(o => o.displayValue)
                .join(",")}
              size="small"
            >
              {`+${filtersToDisplay.slice(numberOfItemsToDisplay).length}`}
            </Tooltip>
          }
        ></Tag>
      )}
    </div>
  );
};

export default SelectedFilters;
