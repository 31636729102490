import Form from "@rjsf/core";
import { isListEmpty, isObjectEmpty, setCleanValue } from "src/utils/general";
import { FormData } from "../FormSchema";

class CustomForm extends Form<FormData> {
  constructor(props) {
    super(props);

    const {
      formContext: { saveTemplateBlock, setErrors },
    } = this.props;

    const superOnChange = this.onChange;

    this.onChange = newFormData => {
      let preparedFormData = newFormData;

      if (!isObjectEmpty(newFormData)) {
        preparedFormData = Object.entries(newFormData).reduce(
          (previousState, [key, value]) => {
            return {
              ...previousState,
              ...setCleanValue({ key, value }),
            };
          },
          {}
        );
      }

      superOnChange(preparedFormData, undefined);

      const { errors, errorSchema } = this.validate(preparedFormData);

      this.setState({
        errors,
        errorSchema,
      });

      if (isListEmpty(errors)) {
        saveTemplateBlock && saveTemplateBlock(preparedFormData);
      } else {
        setErrors &&
          setErrors(
            errors.map(({ property, name }) => ({
              path: property,
              error: name,
            }))
          );
      }
    };
  }
}

export default CustomForm;
