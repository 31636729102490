import { useContext } from "react";
import { IntlContext, createIntl, createIntlCache } from "react-intl";
import useLanguagePreference from "../useLanguagePreference";
import { Translations } from "src/types";

const useIntlLocale = ({
  translations = {},
  languageCode,
}: {
  languageCode?: string;
  translations: Translations;
}) => {
  const intlContext = useContext(IntlContext);
  const languageCodeParentContext = useLanguagePreference();

  const parentMessages = intlContext?.messages || {};

  const getLanguageWithoutRegionCode = (locale: string) => {
    return locale ? locale.toLowerCase().split(/[_-]+/)[0] : null;
  };

  const locale = languageCode || languageCodeParentContext;

  const messages =
    translations[
      Object.keys(translations).find(
        key =>
          getLanguageWithoutRegionCode(key) ===
          getLanguageWithoutRegionCode(locale)
      )
    ] || translations["en-US"];

  const cache = createIntlCache();
  const intl = createIntl(
    {
      locale,
      //@ts-ignore
      messages: {
        ...messages,
        ...parentMessages,
      },
    },
    cache
  );

  return { intl, locale };
};

export default useIntlLocale;
