import { InputProps } from "antd";

export const getHandleDebouncedValueChange =
  ({
    currentValue,
    defaultValue,
    onChange,
  }: {
    currentValue: InputProps["defaultValue"];
    defaultValue: InputProps["defaultValue"];
    onChange: Function;
  }) =>
  () => {
    if (currentValue === defaultValue) return;

    onChange(currentValue);
  };
