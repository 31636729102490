import { useState } from "react";
import usePagination from "../usePagination";
import { useAllFilterSelectedValues } from "IndexComponents";

const useProductFilters = () => {
  const pagination = usePagination();
  const {
    selectedFilters,
    mergeFilterValues: _mergeFilterValues,
    clearFilterValues: _clearFilterValues,
    clearAllFilters: _clearAllFilters,
  } = useAllFilterSelectedValues();

  const [search, setSearch] = useState("");

  const handleSearch = (search = "") => {
    pagination.resetPage();
    setSearch(search);
  };

  const mergeFilterValues = (
    filterId: string,
    selectedValues: string | string[]
  ) => {
    pagination.resetPage();
    _mergeFilterValues(filterId, selectedValues);
  };

  const clearFilterValues = (filterId: string) => {
    pagination.resetPage();
    _clearFilterValues(filterId);
  };

  const clearAllFilters = () => {
    pagination.resetPage();
    _clearAllFilters();
  };

  return {
    pagination,
    filter: {
      search,
      selectedFilters,
      handleSearch,
      mergeFilterValues,
      clearFilterValues,
      clearAllFilters,
    },
  };
};

export default useProductFilters;
