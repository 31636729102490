import { useEffect, useRef, useState } from "react";
import { SelectedFilterItem } from "../../types";

const useAllFilterSelectedValues = (
  defaultSelectedFilters?: SelectedFilterItem[]
) => {
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilterItem[]>(
    defaultSelectedFilters || []
  );
  const isDirty = useRef(false);

  useEffect(() => {
    if (!isDirty.current && defaultSelectedFilters) {
      setSelectedFilters(defaultSelectedFilters);
    }
  }, [defaultSelectedFilters]);

  const mergeFilterValues = (
    filterId: string,
    selectedValues: string | string[]
  ) => {
    isDirty.current = true;

    if (!selectedValues) {
      return;
    }

    if (!Array.isArray(selectedValues)) {
      selectedValues = selectedValues ? [selectedValues] : [];
    }

    setSelectedFilters(selectedFilters => {
      let selectedFilter = selectedFilters.find(x => x.filterId === filterId);
      if (selectedFilter) {
        selectedFilter.values = selectedValues as string[];
        return [...selectedFilters];
      }

      selectedFilter = {
        filterId: filterId,
        values: selectedValues as string[],
      };

      return [...selectedFilters, selectedFilter];
    });
  };

  const clearFilterValues = (filterId: string) => {
    setSelectedFilters(selectedFilters => {
      return selectedFilters.filter(f => f.filterId !== filterId);
    });
  };

  const clearAllFilters = () => {
    setSelectedFilters([]);
  };

  return {
    selectedFilters,
    mergeFilterValues,
    clearFilterValues,
    clearAllFilters,
  };
};

export default useAllFilterSelectedValues;
