import React from "react";
import { Icon } from "@trace-one/design-system";
import { useLanguagePreference } from "src/hooks";
import { HeaderProps } from "../../types";

const HelperButton = ({
  helpLinks,
}: {
  helpLinks: HeaderProps["helpLinks"];
}) => {
  const languageCode = useLanguagePreference();
  return (
    <li
      key="helperButton"
      onClick={() => {
        const link = helpLinks[languageCode] ?? helpLinks["en-US"];

        window.open(link, "_blank");
      }}
      data-test-id="helper-button"
    >
      <Icon name="help-center" />
    </li>
  );
};

export default HelperButton;
