import { useState } from "react";
import { useIntl } from "react-intl";
import { itemMessages } from "src/components/UploadFiles/translations/messages";
import { DraggerProps } from "../../../../../../../Dragger/types";

const useRemove = ({
  onRemove,
  messages,
}: {
  onRemove?: Function;
  messages?: DraggerProps["messages"];
}) => {
  const { formatMessage } = useIntl();
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const onOpenChange = (open: boolean) => {
    setIsTooltipVisible(open);
  };

  const onRemoveClick = () => {
    setIsTooltipVisible(true);
  };

  const closeTooltip = () => {
    setIsTooltipVisible(false);
  };

  const onYesClick = () => {
    onRemove();

    closeTooltip();
  };

  const onNoClick = () => {
    closeTooltip();
  };

  return {
    isTooltipVisible,
    onOpenChange,
    onRemoveClick,
    actions: [
      {
        text: messages?.yes ?? formatMessage(itemMessages.yes),
        onClick: onYesClick,
      },
      {
        text: messages?.no ?? formatMessage(itemMessages.no),
        onClick: onNoClick,
      },
    ],
  };
};

export default useRemove;
