import * as API from "src/apis/cumd";
import { useEffect, useState } from "react";

const useAuthType = (companyId: string) => {
  const [authType, setAuthType] = useState<string>(null);

  const getAuthType = async () => {
    try {
      const { data } = await API.getCompanyConfigurations(companyId);
      setAuthType(data.authenticationType);
    } catch (error) {}
  };

  useEffect(() => {
    getAuthType();
  }, [companyId]);

  return { authType };
};

export default useAuthType;
