import { useEffect, useState } from "react";
import { fetchManufacturedItems, fetchTradeItems } from "src/apis/pmd";
import { TradeItemsResponse } from "src/apis/pmd/types";
import useProductFilters from "../useProductFilters";

const useProducts = ({ ownerCompanyId }) => {
  const { pagination, filter } = useProductFilters();
  const [productsRes, setProductsRes] = useState<TradeItemsResponse>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    refreshProducts();
  }, [
    pagination.pageAndSize.page,
    pagination.pageAndSize.pageSize,
    filter.selectedFilters.map(x => x.values.join("")).join(""),
    filter.search,
  ]);

  const isProductWithSuppliers = () => {
    const data = filter.selectedFilters.find(
      x => x.filterId === "with_suppliers"
    );
    return data?.values?.[0] === "yes";
  };

  const prepareTradeItemsAPIParams = () => {
    return {
      OnlyWithoutContacts: false,
      HasNoBrand: false,
      HasNoProductCategory: false,
      ownerCompanyId,
      skip: pagination.pageAndSize.page,
      take: pagination.pageAndSize.pageSize,
    };
  };

  const prepareTradeItemsAPIReqData = () => {
    const supplierFilter = filter.selectedFilters.find(
      f => f.filterId === "supplier"
    );

    const brandFilter = filter.selectedFilters.find(
      f => f.filterId === "brand"
    );

    return {
      tradeItemStatuses: ["34a95104-3a04-4078-94d5-30068b8c583a"],
      searchText: filter.search,
      supplierCompanyIds: supplierFilter?.values || [],
      brandIds: brandFilter?.values || [],
    };
  };

  const prepareManufacturedItemsAPIReqData = () => {
    return {
      manufacturedItemStatuses: ["7dbe3b47-b5c6-4304-bac9-3895efa5a8f9"],
    };
  };

  const prepareManufacturedItemsParams = () => {
    return {
      skip: pagination.pageAndSize.page,
      take: pagination.pageAndSize.pageSize,
      searchText: filter.search,
    };
  };

  const refreshProducts = async () => {
    try {
      setIsLoading(true);
      if (isProductWithSuppliers()) {
        const params = prepareManufacturedItemsParams();
        const data = prepareManufacturedItemsAPIReqData();
        const res = await fetchManufacturedItems(data, params);
        setProductsRes(res.data);
      } else {
        const params = prepareTradeItemsAPIParams();
        const data = prepareTradeItemsAPIReqData();
        const res = await fetchTradeItems(data, params);
        setProductsRes(res.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    products: productsRes?.tradeItems || [],
    total: productsRes?.skipAndTakeQueryStats?.totalCount || 0,
    refreshProducts,
    pagination,
    filter,
    isLoading,
  };
};

export default useProducts;
