import { useEffect, useMemo, useState } from "react";
import { useLanguagePreference } from "src/hooks";
import { getUserApplications } from "src/apis/cumd";
import { makeNotificationAppTabs } from "../../utils";
import { useIntl } from "react-intl";
import { tab } from "../../translations/defineMessage";
import { Tab } from "../../types";

const useTabs = (): Tab[] => {
  const languageCode = useLanguagePreference();
  const [apps, setApps] = useState<Tab[]>([]);
  const { formatMessage } = useIntl();
  const appsPromise = useMemo(() => getUserApplications(), []);

  useEffect(() => {
    const allDefinition = {
      displayText: formatMessage(tab.all),
      senderZone: "All",
      name: "All",
    };

    appsPromise
      .then(({ data }) => {
        const userApplications = makeNotificationAppTabs(data.userApplications);
        const apps = [allDefinition, ...userApplications];
        setApps(apps);
      })
      .catch(error => {
        console.error(error);
      });
  }, [languageCode]);

  return apps;
};

export default useTabs;
