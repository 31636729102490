const LangList = Object.freeze({
  EN_US: "en-US",
  FR_FR: "fr-FR",
  DE_DE: "de-DE",
  ES_ES: "es-ES",
  PT_PT: "pt-PT",
  IT_IT: "it-IT",
  NL_BE: "nl-BE",
});

const Apps = Object.freeze({
  SPEC: "spec",
  QMS: "qms",
  PROJECT: "project",
  MD: "md",
});

const MappedApps = Object.values(Apps).map(value => value);

const LogoUrl =
  "https://www.traceone.com/hubfs/Logos%20Traceone/traceone_logo.svg";

export { Apps, MappedApps, LogoUrl, LangList };
