import { defineMessages } from "react-intl";

export const messages = defineMessages({
  usersAndAccess: {
    id: "business.components.header.links.usersAndAccess",
    defaultMessage: "Users & Access",
  },
  administration: {
    id: "business.components.header.links.administration",
    defaultMessage: "Administration",
  },
});
