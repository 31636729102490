import { useMemo } from "react";
import { APPLICATIONS } from "../../constants";
import { useIntl } from "react-intl";
import { useLanguagePreference } from "src/hooks";
import { toolbarMessage } from "../../translations/defineMessage";

const useToolbarTitle = (selectedTab: string) => {
  const languageCode = useLanguagePreference();
  const { formatMessage } = useIntl();

  const toolBarTitle = useMemo(() => {
    if (selectedTab.toLowerCase() === "all") {
      return formatMessage(toolbarMessage.title);
    }

    return APPLICATIONS.find(
      app => app.value.toLowerCase() === selectedTab.toLowerCase()
    )?.name;
  }, [selectedTab, languageCode]);

  return toolBarTitle;
};

export default useToolbarTitle;
