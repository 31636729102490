import { defineMessages } from "react-intl";

export const askAppAccessMessages = defineMessages({
  title: {
    id: "shared.business.AppSwitcher.askAppAccess.title",
    defaultMessage: "Discover Trace One Solutions",
  },
  link: {
    id: "shared.business.AppSwitcher.askAppAccess.link",
    defaultMessage: "Ask for an access to Trace One solutions",
  },
  requestAccess: {
    id: "shared.business.AppSwitcher.askAppAccess.requestAccess",
    defaultMessage: "Request access",
  },
});
