import { Apps } from "src/types";

export const appGraphicNameMap = new Map<Apps, string>([
  ["md", "app-logo-md-full"],
  ["project", "app-logo-project-full"],
  ["qms", "app-logo-qms-full"],
  ["spec", "app-logo-spec-full"],
  ["ton", "app-logo-ton-full"],
  ["administration", "app-logo-admin-full"],
  ["rfx", "app-logo-rfx-full"],
]);

export const getAppGraphicName = (appName: Apps): string =>
  appGraphicNameMap.get(appName) || "";
