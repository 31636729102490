import React from "react";
import { Icon, Button } from "@trace-one/design-system";
import { useLanguagePreference } from "src/hooks";
import { SidebarNavigationProps } from "../../types";
import { getPrefixClassName } from "../../../../utils/general";
import { sidebarNavigationMessages } from "../../translations/defineMessage";
import { FormattedMessage } from "react-intl";

const HelperButton = ({
  helpLinks,
}: {
  helpLinks: SidebarNavigationProps["helpLinks"];
}) => {
  const prefixClassName = getPrefixClassName("sidebar-navigation");
  const languageCode = useLanguagePreference();

  const handleClick = () => {
    const link = helpLinks[languageCode] ?? helpLinks["en-US"];
    window.open(link, "_blank");
  };

  return (
    <Button
      className={`${prefixClassName}-helper-button`}
      data-test-id="helper-button"
      type="link"
      key="helperButton"
      onClick={handleClick}
      linkColor="grey-4"
      size="small"
    >
      <Icon name="help-center" size="small" />
      <span className={`${prefixClassName}-helper-button-text`}>
        <FormattedMessage {...sidebarNavigationMessages.help_center} />
      </span>
    </Button>
  );
};

export default HelperButton;
