export const PACKAGING_SYSTEMS_PREFIX = "/api/packaging-systems";
export const PACKAGING_COMPONENT_PREFIX = "/api/packaging-components";
export const RLMD_PREFIX = "/api/referencelist";

export const ERROR_CODES = Object.freeze({
  DUPLICATE_TAG: "3e36005b-c2b7-4d9e-bb7d-831f120ecdc7",
  DUPLICATE_PACKAGING_NAME: "4d7a1a96-5e47-4f96-a417-abd4e17ff691",
});

export const rlmdIds = {
  packagingMaterial: "81fe1f1f-adb3-4a96-9a22-6b7427567704",
  netContentUnitOfMeasure: "8ff32c51-aef6-4ff8-826d-0baa2b0ae10e",
  typeUnitOfMeasure: "92a88070-b5fa-4459-9e1b-2bee7c7d39eb",
  typeUnitOfMeasureLength: "d1317005-58cf-4de4-8d87-cb98336e62c3",
  typeUnitOfMeasureMass: "97c9a7a1-9f4d-4aba-a710-3f569e95d683",
  packagingDocumentTypes: "14a52808-be4c-47a7-9f79-72b974d395e7",
  componentTypes: "b171e5be-f0b5-433f-a27f-7a06a640ccfc",
};
