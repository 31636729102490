import {
  Dictionary,
  LibraryObjectData,
  TagData,
  LibraryObjectType,
  LibraryListItemViewModel,
} from "../types";
import {
  LIBRARY_OBJECT_TYPES,
  LIBRARY_OBJECT_STATES,
  LIBRARY_TYPE_GRAPHICS,
  PRODUCT_TYPES,
} from "../constants";
import {
  isLibraryObjectChaptersType,
  isLibraryObjectMaterialType,
  isLibraryObjectPackagingSystemsType,
  isLibraryObjectRequirementType,
  isLibraryObjectTemplatesType,
  isMaterialAdditiveType,
  isMaterialProcessingAidType,
  isMaterialRawMaterialType,
  isProductTypeFAndV,
} from "./library";

const prepareLibraryListItemType = ({
  libraryItem,
  typeDictionary,
  type,
}: {
  libraryItem: LibraryObjectData;
  typeDictionary?: Dictionary<string>;
  type?: LibraryObjectType;
}) => {
  let itemType = "";

  if (isLibraryObjectMaterialType(type)) {
    if (
      isMaterialRawMaterialType(libraryItem?.materialType) ||
      isMaterialAdditiveType(libraryItem?.materialType) ||
      isMaterialProcessingAidType(libraryItem?.materialType)
    ) {
      itemType = libraryItem.materialType;
    }

    let recipeType = "";

    if (libraryItem.hasOwnProperty("recipe")) {
      // @ts-ignore
      recipeType = libraryItem.recipe?.recipeType;
    } else {
      recipeType = libraryItem.recipeType;
    }

    if (recipeType) {
      itemType = recipeType;
    }
  } else if (isLibraryObjectRequirementType(type)) {
    itemType = libraryItem.requirementType;
  } else if (isLibraryObjectChaptersType(type)) {
    itemType = libraryItem.type;
  } else if (isLibraryObjectTemplatesType(type)) {
    if (isProductTypeFAndV(libraryItem?.productTypeId))
      itemType = PRODUCT_TYPES.F_AND_V;
    else itemType = PRODUCT_TYPES.FOOD;
  } else {
    if (libraryItem.hasOwnProperty("componentTypeId"))
      itemType = libraryItem.componentTypeId;
    else itemType = LIBRARY_OBJECT_TYPES.PACKAGING_SYSTEMS;
  }

  return typeDictionary?.[itemType] || itemType;
};

const prepareLibraryListItemState = (libraryItem: LibraryObjectData) => {
  if (libraryItem.state) {
    return libraryItem.state;
  }

  if (libraryItem.hasOwnProperty("allowsUpdate") && !libraryItem.allowsUpdate) {
    return LIBRARY_OBJECT_STATES.LOCKED;
  }

  return null;
};

const prepareLibraryListIconName = (
  libraryItem: LibraryObjectData,
  type: LibraryObjectType
) => {
  if (isLibraryObjectMaterialType(type)) {
    if (
      isMaterialRawMaterialType(libraryItem?.materialType) ||
      isMaterialAdditiveType(libraryItem?.materialType) ||
      isMaterialProcessingAidType(libraryItem?.materialType)
    ) {
      return LIBRARY_TYPE_GRAPHICS[libraryItem.materialType];
    }

    let recipeType = "";

    if (libraryItem.hasOwnProperty("recipe")) {
      // @ts-ignore
      recipeType = libraryItem.recipe?.recipeType;
    } else {
      recipeType = libraryItem.recipeType;
    }

    return LIBRARY_TYPE_GRAPHICS[recipeType];
  }

  if (isLibraryObjectRequirementType(type)) {
    return LIBRARY_TYPE_GRAPHICS["requirement"];
  }

  if (isLibraryObjectChaptersType(type)) {
    return LIBRARY_TYPE_GRAPHICS[libraryItem.type];
  }

  if (isLibraryObjectTemplatesType(type)) {
    return LIBRARY_TYPE_GRAPHICS[LIBRARY_OBJECT_TYPES.TEMPLATE];
  }

  if (isLibraryObjectPackagingSystemsType(type)) {
    if (libraryItem.hasOwnProperty("componentTypeId")) {
      return LIBRARY_TYPE_GRAPHICS[LIBRARY_OBJECT_TYPES.PACKAGING_COMPONENTS];
    } else {
      return LIBRARY_TYPE_GRAPHICS[LIBRARY_OBJECT_TYPES.PACKAGING_SYSTEMS];
    }
  }

  return LIBRARY_TYPE_GRAPHICS[LIBRARY_OBJECT_TYPES.PACKAGING_SYSTEMS];
};

export const prepareLibraryListItemFromApiData = ({
  libraryItem,
  tags = [],
  typeDictionary,
  type,
}: {
  libraryItem: LibraryObjectData;
  tags?: TagData[];
  typeDictionary?: Dictionary<string>;
  type?: LibraryObjectType;
}) => {
  let newLibraryItem: LibraryListItemViewModel = {
    id: libraryItem.id,
    name: libraryItem.name,
    state: prepareLibraryListItemState(libraryItem),
    versionNumber: libraryItem?.versionNumber ?? libraryItem?.version?.number,
    type: prepareLibraryListItemType({ libraryItem, type }),
    formattedType: prepareLibraryListItemType({
      libraryItem,
      typeDictionary,
      type,
    }),
    lastUpdateDateUtc: libraryItem?.lastUpdateDateUtc,
    iconName: prepareLibraryListIconName(libraryItem, type),
    tags: tags?.reduce((previousState, { tagId, tagText }) => {
      let currentState = [...previousState];
      if (libraryItem?.tagIds?.includes(tagId)) {
        currentState.push(tagText);
      }

      return currentState;
    }, []),
  };

  return newLibraryItem;
};
