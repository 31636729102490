import { isListEmpty } from "src/utils/general";
import { FileListType } from "../../../types";

export const isTheSameFile = (
  fileToBeRemoved: FileListType,
  currentFile: FileListType
) => {
  if (
    !!fileToBeRemoved.uid &&
    !!currentFile.uid &&
    fileToBeRemoved.uid === currentFile.uid
  ) {
    return true;
  }

  if (
    !!fileToBeRemoved.id &&
    !!currentFile.id &&
    fileToBeRemoved.id === currentFile.id
  ) {
    return true;
  }

  return false;
};

export const prepareFileName = ({
  fileList,
  fileName,
  number = 1,
  originalFileName,
}: {
  fileList: FileListType[];
  fileName: string;
  number?: number;
  originalFileName?: string;
}) => {
  if (isListEmpty(fileList)) return fileName;

  const fileNameIndex = fileList.findIndex(({ name }) => name === fileName);

  if (fileNameIndex === -1) return fileName;

  const fileNameArray = fileName.split(".");

  if (!originalFileName) {
    originalFileName = fileNameArray[0];
  }

  return prepareFileName({
    fileList,
    fileName: `${originalFileName}(${number}).${
      fileNameArray[fileNameArray.length - 1]
    }`,
    number: ++number,
    originalFileName,
  });
};
