import React from "react";
import { NavLink } from "react-router-dom";
import { Graphic, Icon, Heading } from "@trace-one/design-system";
import { LogoUrl } from "../../../../../utils/const";
import { getAppGraphicName } from "src/utils/graphics";
import { getPrefixClassName } from "src/utils/general";
import { AppInfoProps } from "./types";

const AppInfo: React.FC<AppInfoProps> = ({
  appName,
  appLogo,
  homeUrl,
  extraAboveAppLogo,
  handleMenuToggle,
  burgerIconRef,
}) => {
  const prefixCls = getPrefixClassName("header");

  return (
    <div className={`${prefixCls}-app-info`}>
      <NavLink to={homeUrl} className={`${prefixCls}-logo-container`}>
        <img src={LogoUrl} height={32} alt="logo" />
      </NavLink>
      <div
        ref={burgerIconRef}
        data-test-id="nav-burger"
        className={`${prefixCls}-burger-btn`}
        onClick={handleMenuToggle}
      >
        <Icon name="burger-menu" />
      </div>

      <div className={`${prefixCls}-app-name-wrapper`}>
        {extraAboveAppLogo}
        <NavLink to={homeUrl} className={`${prefixCls}-app-logo-wrapper`}>
          <Graphic name={getAppGraphicName(appLogo)} />
          <Heading size="xs" color="primary">
            {appName}
          </Heading>
        </NavLink>
      </div>
    </div>
  );
};

AppInfo.displayName = "AppInfo";

AppInfo.defaultProps = {
  homeUrl: "/",
};

export default AppInfo;
