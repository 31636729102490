import React, { useContext, useState } from "react";
import { Form as AntdForm } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { TextArea, Checkbox, Paragraph } from "@trace-one/design-system";
import messages from "../../translations/messages";
import { AskAccessModalContext } from "../../context/AskAccessModalContext";
import "../../styles.less";
import { FormProps } from "./types";
import { useForm } from "./hooks";
import { getPrefixClassName } from "src/utils/general";

const { Item } = AntdForm;

export const Form: React.FC<FormProps> = ({
  langcode,
  onClose,
  userId,
  formRef,
}) => {
  const { availableApps } = useContext(AskAccessModalContext);
  const { formatMessage } = useIntl();
  const [checkedApps, setCheckedApps] = useState<string[]>([]);

  const {
    handleCheck,
    requestAppsValidation,
    requestMessageValidation,
    onFinish,
  } = useForm({
    userId,
    langcode,
    onClose,
    checkedApps,
    setCheckedApps,
  });

  const prefixClassName = getPrefixClassName("ask-access-modal-form");

  return (
    <div className={prefixClassName}>
      <AntdForm
        ref={formRef}
        name="ask-access-form"
        onFinish={values =>
          onFinish(
            Object.assign(values, {
              requestApps: checkedApps,
              requestMessage: values.requestMessage.trim(),
            })
          )
        }
      >
        <Paragraph className={`${prefixClassName}-subtitle`}>
          <FormattedMessage {...messages.subtitle} />
        </Paragraph>

        <Item
          name="requestApps"
          required
          rules={[{ validator: requestAppsValidation }]}
        >
          <div>
            {availableApps.map(({ text, id }) => (
              <Checkbox
                checked={checkedApps.some(appName => appName === text)}
                name={text}
                key={id}
                onChange={handleCheck}
              >
                {text}
              </Checkbox>
            ))}
          </div>
        </Item>

        <Item
          name="requestMessage"
          required
          rules={[
            {
              required: true,
              message: formatMessage(messages.requestMessageMandatoryError),
            },
            {
              max: 1000,
              message: formatMessage(messages.requestMessageMaxError),
            },
            { validator: requestMessageValidation },
          ]}
        >
          <TextArea
            name="requestMessage"
            data-test-id="requestMessage"
            placeholder={formatMessage(messages.requestMessage)}
          />
        </Item>
      </AntdForm>
    </div>
  );
};

export default Form;
