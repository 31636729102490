/* istanbul ignore file */

import React from "react";
import LanguagePreferenceProvider from "src/components/LanguagePreferenceProvider";

const withLanguageData =
  ({ translations }) =>
    WrappedComponent => {
      const Component = props => {
        const { languageCode } = props;

        return (
        <LanguagePreferenceProvider
          languageCode={languageCode}
          translations={translations}
        >
          <WrappedComponent {...props} />
        </LanguagePreferenceProvider>
        );
      };

      return Component;
    };

export default withLanguageData;
