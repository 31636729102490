import { useState } from "react";

const useDisplayItems = () => {
  const [displayItems, setDisplayItems] = useState("display-3-columns");

  const onDisplayItemChange = (value: string) => {
    setDisplayItems(value);
  };

  return {
    displayItems,
    options: [
      {
        value: "display-4-columns",
        iconName: "display-4-columns",
      },
      {
        value: "display-3-columns",
        iconName: "display-3-columns",
      },
    ],
    onDisplayItemChange,
  };
};

export default useDisplayItems;
