import { useEffect, useState } from "react";
import { getUserApplications } from "src/apis/cumd";
import { UserApplication } from "src/models";
import { isListEmpty } from "src/utils/general";
import { AppSwitcherProps } from "../../types";
import { getAppsToDisplay } from "../../utils";

const useAppSwitcher = ({
  fetchCompaniesByIds,
  excludeApps,
  includeApps,
}: {
  fetchCompaniesByIds?: AppSwitcherProps["fetchCompaniesByIds"];
  excludeApps?: AppSwitcherProps["excludeApps"];
  includeApps?: AppSwitcherProps["includeApps"];
}) => {
  const [loading, setLoading] = useState(false);
  const [apps, setApps] = useState<UserApplication[]>([]);

  const fetchApps = async () => {
    try {
      setLoading(true);
      const {
        data: { userApplications },
      } = await getUserApplications();

      const appsToDisplay = getAppsToDisplay({
        apps: userApplications,
        excludeApps,
        includeApps,
      });

      setApps(appsToDisplay);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchApps();
  }, []);

  useEffect(() => {
    if (!isListEmpty(apps)) {
      const companyIds = apps?.map(
        ({ masterDataApplicationOwnerId }) => masterDataApplicationOwnerId
      );
      if (
        !isListEmpty(companyIds) &&
        typeof fetchCompaniesByIds === "function"
      ) {
        fetchCompaniesByIds({ companyIds });
      }
    }
  }, [apps]);

  return {
    loading,
    apps,
  };
};

export default useAppSwitcher;
