import { useEffect, useRef, useState } from "react";
import { NotificationPanelProps } from "../../types";
export const NOTIFICATIONS_ROOT = "notificationsRoot";

const useNotificationPanelOpen = (
  history: NotificationPanelProps["history"],
  onNotificationPanelToggle: NotificationPanelProps["onNotificationPanelToggle"]
) => {
  const nodeRef = useRef<null | HTMLElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [isNodeMounted, setIsNodeMounted] = useState<boolean>(false);

  useEffect(() => {
    onNotificationPanelToggle(open);
  }, [open]);

  useEffect(() => {
    history.listen(() => {
      setOpen(false);
    });
  }, [history]);

  useEffect(() => {
    const interval = window.setInterval(() => {
      const node = document.getElementById(NOTIFICATIONS_ROOT);
      if (node) {
        window.clearInterval(interval);
        nodeRef.current = node;
        setIsNodeMounted(true);
      }
    }, 500);

    return () => {
      window.clearInterval(interval);
    };
  }, []);

  const toggleOpen = () => setOpen(isOpen => !isOpen);

  return {
    isNodeMounted,
    open,
    setOpen,
    nodeRef,
    toggleOpen,
  };
};

export default useNotificationPanelOpen;
