import React from "react";
import { Button } from "@trace-one/design-system";
import { LogOutProps } from "./types";
import { useIntl } from "react-intl";
import { sideBarMessages } from "../../../../translations/messages";

const LogOut: React.FC<LogOutProps> = ({ onLogout }) => {
  const { formatMessage } = useIntl();

  return (
    <div className="log-out" data-test-id="user-profile-page-sidebar-logout">
      <Button onClick={onLogout}>
        {formatMessage(sideBarMessages.logOutButton)}
      </Button>
    </div>
  );
};

export default LogOut;
