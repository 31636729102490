import { useEffect, useState } from "react";
import { getDocumentConfig } from "src/apis/document";
import { DocumentConfigData } from "src/models";
import { isListEmpty } from "src/utils/general";

const useDocumentConfiguration = ({ fileType }: { fileType?: string }) => {
  const [documentConfiguration, setDocumentConfiguration] =
    useState<DocumentConfigData>({
      allowedFileExtensions: [],
      maxFileSizeInBytes: 0,
    });

  const getConfiguration = async () => {
    try {
      const { data } = await getDocumentConfig();

      const docConfig = {
        maxFileSizeInBytes: data.maxFileSizeInBytes,
        allowedFileExtensions: data.allowedFileExtensions,
      };

      if (fileType && !isListEmpty(data.fileTypes)) {
        const fileTypeConfig = data.fileTypes.find(
          ({ fileTypeName }) => fileTypeName === fileType
        );

        if (fileTypeConfig) {
          docConfig.allowedFileExtensions =
            fileTypeConfig.allowedFileExtensions;
          if (fileTypeConfig.maxFileLength)
            docConfig.maxFileSizeInBytes = fileTypeConfig.maxFileLength;
        }
      }
      setDocumentConfiguration(docConfig);
    } catch (e) {}
  };

  useEffect(() => {
    getConfiguration();
  }, [fileType]);

  return documentConfiguration;
};

export default useDocumentConfiguration;
