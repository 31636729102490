import { useEffect } from "react";
import { AxiosError, AxiosInstance } from "axios";
import { toaster } from "@trace-one/design-system";
import { AxiosInstancesManager } from "src/configs/AxiosInstancesManager";
import useIntlLocale from "../useIntlLocale";
import { shouldNotificationErrorBeDisplayed } from "./utils";
import {
  isErrorDetail,
  isErrorResponseList,
  isErrorWithCode,
} from "src/utils/general";
import { LangList } from "../../../utils/const";
import { messages as currentMessages } from "./translations/messages";
import { ErrorDetail } from "src/types";
import enUs from "./translations/en-US.json";
import frFr from "./translations/fr-FR.json";

const translations = {
  [LangList.EN_US]: enUs,
  [LangList.FR_FR]: { ...enUs, ...frFr },
};

export const useAxiosErrorsInterceptor = (
  axiosInstance: AxiosInstance,
  {
    errorCodesToSkip = [],
    errorStatusesToSkip = [],
    extraMessages = {},
  }: {
    errorCodesToSkip?: string[];
    errorStatusesToSkip?: number[];
    extraMessages?: { [key: string]: string };
  } = {}
) => {
  const {
    intl: { formatMessage },
  } = useIntlLocale({
    translations,
  });

  const getErrorMessage =
    ({ errorCode, status }: { errorCode?: string; status?: number }) =>
    (errorType: string) => {
      if (extraMessages[`${errorCode}${errorType}`]) {
        return extraMessages[`${errorCode}${errorType}`];
      }

      if (extraMessages[`${status}${errorType}`]) {
        return extraMessages[`${status}${errorType}`];
      }

      if (currentMessages[`${status}${errorType}`]) {
        return formatMessage(currentMessages[`${status}${errorType}`]);
      }

      return formatMessage(currentMessages[`general${errorType}`]);
    };

  const handleReject = (error: AxiosError, errorDetail?: ErrorDetail) => {
    if (
      shouldNotificationErrorBeDisplayed({
        error,
        errorCodesToSkip,
        errorStatusesToSkip,
        errorDetail,
      })
    ) {
      const errorCode = isErrorWithCode(error?.response?.data)
        ? error?.response?.data?.errorCode
        : isErrorDetail(errorDetail)
        ? errorDetail?.code
        : "";

      const getErrorMessageByType = getErrorMessage({
        errorCode,
        status: error?.response?.status,
      });

      toaster.open({
        type: "alert",
        duration: 5,
        message: getErrorMessageByType("Message"),
        description: getErrorMessageByType("Description"),
      });
    }
  };

  const onReject = (error: AxiosError) => {
    const errorResponseData = error?.response?.data;

    if (isErrorResponseList(errorResponseData)) {
      for (const errorDetail of errorResponseData?.errors) {
        handleReject(error, errorDetail);
      }
    } else {
      handleReject(error);
    }

    return Promise.reject(error);
  };

  useEffect(() => {
    const requestInterceptor = axiosInstance.interceptors.response.use(
      response => response,
      onReject
    );

    return () => {
      axiosInstance.interceptors.response.eject(requestInterceptor);
    };
  }, [axiosInstance]);
};

const useAxiosErrorsInterceptors = (
  {
    errorCodesToSkip = [],
    errorStatusesToSkip = [],
    extraMessages = {},
  }: {
    errorCodesToSkip?: string[];
    errorStatusesToSkip?: number[];
    extraMessages?: { [key: string]: string };
  } = { errorCodesToSkip: [], errorStatusesToSkip: [] }
) => {
  const axiosInstanceCumd = AxiosInstancesManager.getCumd();
  useAxiosErrorsInterceptor(axiosInstanceCumd, {
    errorCodesToSkip,
    errorStatusesToSkip,
    extraMessages,
  });

  const axiosInstancePmd = AxiosInstancesManager.getPmd();
  useAxiosErrorsInterceptor(axiosInstancePmd, {
    errorCodesToSkip,
    errorStatusesToSkip,
    extraMessages,
  });

  const axiosInstanceRlmd = AxiosInstancesManager.getRlmd();
  useAxiosErrorsInterceptor(axiosInstanceRlmd, {
    errorCodesToSkip,
    errorStatusesToSkip,
    extraMessages,
  });

  const axiosInstanceSmd = AxiosInstancesManager.getSmd();
  useAxiosErrorsInterceptor(axiosInstanceSmd, {
    errorCodesToSkip,
    errorStatusesToSkip,
    extraMessages,
  });

  const axiosInstanceTags = AxiosInstancesManager.getTags();
  useAxiosErrorsInterceptor(axiosInstanceTags, {
    errorCodesToSkip,
    errorStatusesToSkip,
    extraMessages,
  });

  const axiosInstancePkg = AxiosInstancesManager.getPkg();
  useAxiosErrorsInterceptor(axiosInstancePkg, {
    errorCodesToSkip,
    errorStatusesToSkip,
    extraMessages,
  });

  const axiosInstanceClb = AxiosInstancesManager.getClb();
  useAxiosErrorsInterceptor(axiosInstanceClb, {
    errorCodesToSkip,
    errorStatusesToSkip,
    extraMessages,
  });

  const axiosInstanceTon = AxiosInstancesManager.getTon();
  useAxiosErrorsInterceptor(axiosInstanceTon, {
    errorCodesToSkip,
    errorStatusesToSkip,
    extraMessages,
  });

  const axiosInstanceDocument = AxiosInstancesManager.getDocument();
  useAxiosErrorsInterceptor(axiosInstanceDocument, {
    errorCodesToSkip,
    errorStatusesToSkip,
    extraMessages,
  });

  const axiosInstanceNotif = AxiosInstancesManager.getNotif();
  useAxiosErrorsInterceptor(axiosInstanceNotif, {
    errorCodesToSkip,
    errorStatusesToSkip,
    extraMessages,
  });

  const axiosInstanceDiscuss = AxiosInstancesManager.getDiscuss();
  useAxiosErrorsInterceptor(axiosInstanceDiscuss, {
    errorCodesToSkip,
    errorStatusesToSkip,
    extraMessages,
  });

  const axiosInstanceCatalogue = AxiosInstancesManager.getCatalogue();
  useAxiosErrorsInterceptor(axiosInstanceCatalogue, {
    errorCodesToSkip,
    errorStatusesToSkip,
    extraMessages,
  });
};

export default useAxiosErrorsInterceptors;
