import React from "react";
import { Paragraph } from "@trace-one/design-system";
import { bytesToSize } from "./utils";
import { InfoProps } from "./types";

const Info: React.FC<InfoProps> = props => {
  const {
    fileSize = 0,
    infoPrefixCls = "business-components-upload-files-item-info",
  } = props;

  return (
    <Paragraph
      data-test-id="upload-files-item-size"
      className={`${infoPrefixCls}-size`}
      size="xs"
      color="grey-4"
    >
      {bytesToSize(fileSize)}
    </Paragraph>
  );
};

export default Info;
