import { defineMessages } from "react-intl";

export const errorMessages = defineMessages({
  download: {
    id: "business.components.upload.files.errors.download",
    defaultMessage:
      "An error occurred during the file download, please try again",
  },
  empty: {
    id: "business.components.upload.files.errors.empty",
    defaultMessage: "The file is empty",
  },
  upload: {
    id: "business.components.upload.files.errors.upload",
    defaultMessage: "An error occurred during file upload",
  },
  fileSize: {
    id: "business.components.upload.files.errors.size.file",
    defaultMessage: "Size of file is too big",
  },
  generalSize: {
    id: "business.components.upload.files.errors.size.general",
    defaultMessage: "The file size is limited to {maxSize} MB",
  },
  fileType: {
    id: "business.components.upload.files.errors.type.file",
    defaultMessage: "Not authorized file type",
  },
  generalType: {
    id: "business.components.upload.files.errors.type.general",
    defaultMessage:
      "This type of file is not authorized. Please select a file which type is {fileTypes}",
  },
});

export const draggerMessages = defineMessages({
  uploadButton: {
    id: "business.components.upload.files.upload.button",
    defaultMessage: "Upload",
  },
  uploadButtonFromComputer: {
    id: "business.components.upload.files.upload.button.items.computer",
    defaultMessage: "From computer",
  },
  uploadButtonFromLibrary: {
    id: "business.components.upload.files.upload.button.items.library",
    defaultMessage: "From document library",
  },
  draggerText: {
    id: "business.components.upload.files.upload.dragger.text",
    defaultMessage: "Drag here or upload",
  },
  draggerTextLarge: {
    id: "business.components.upload.files.upload.dragger.text.large",
    defaultMessage: "Drag and drop files here",
  },
});

export const itemMessages = defineMessages({
  yes: {
    id: "business.components.upload.files.item.remove.tooltip.yes",
    defaultMessage: "Yes",
  },
  no: {
    id: "business.components.upload.files.item.remove.tooltip.no",
    defaultMessage: "No",
  },
  removeTooltipMessage: {
    id: "business.components.upload.files.item.remove.tooltip.message",
    defaultMessage: "Are you sure you want to remove this file?",
  },
});
