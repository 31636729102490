import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./styles.less";
import SideBar from "./components/SideBar";
import Content from "./components/Content";
import { UserProfilePageProps } from "./types";
import { useUser } from "src/hooks";
import { withLanguageData } from "src/hocs";
import { LangList } from "../../../utils/const";
import enUs from "./translations/en-US.json";
import frFr from "./translations/fr-FR.json";
import deDe from "./translations/de-DE.json";
import esEs from "./translations/es-ES.json";
import ptPt from "./translations/pt-PT.json";
import itIt from "./translations/it-IT.json";
import nlBe from "./translations/nl-BE.json";
import { Spinner } from "@trace-one/design-system";
import classNames from "classnames";
import { USER_PROFILE_PATH, USER_PROFILE_TILE } from "src/utils/constants";

const DEFAULT_LANG = enUs;

const enhance = withLanguageData({
  translations: {
    [LangList.EN_US]: enUs,
    [LangList.FR_FR]: { ...DEFAULT_LANG, ...frFr },
    [LangList.DE_DE]: { ...DEFAULT_LANG, ...deDe },
    [LangList.ES_ES]: { ...DEFAULT_LANG, ...esEs },
    [LangList.PT_PT]: { ...DEFAULT_LANG, ...ptPt },
    [LangList.IT_IT]: { ...DEFAULT_LANG, ...itIt },
    [LangList.NL_BE]: { ...DEFAULT_LANG, ...nlBe },
  },
});

const UserProfilePage: React.FC<UserProfilePageProps> = ({
  userId,
  onLogout,
  onLanguageChange,
  applicationTypeIds,
  withMarginTop,
  section,
  isCompanyAdmin,
}) => {
  const history = useHistory();
  const { user, isLoading } = useUser({ userId });

  const [selectedTile, setSelectedTile] = useState(
    section ?? USER_PROFILE_TILE.MY_SETTINGS
  );

  useEffect(() => {
    if (history) {
      history.push(USER_PROFILE_PATH[selectedTile]);
    }
  }, [selectedTile]);

  if (isLoading) return <Spinner size="large" />;

  return (
    <div
      className={classNames("user-profile-page-container", {
        "user-profile-page-container-with-margin-top": withMarginTop,
      })}
      data-test-id="user-profile-page"
    >
      <SideBar
        user={user}
        onLogout={onLogout}
        selectedTile={selectedTile}
        setSelectedTile={setSelectedTile}
      />
      <Content
        user={user}
        onLanguageChange={onLanguageChange}
        selectedTile={selectedTile}
        applicationTypeIds={applicationTypeIds}
        isCompanyAdmin={isCompanyAdmin}
      />
    </div>
  );
};

export default enhance(UserProfilePage);
