import React from "react";
import {
  Heading,
  Paragraph,
  Select,
  Spinner,
  Toggle,
} from "@trace-one/design-system";
import { useIntl } from "react-intl";
import { notifSettingsContentMessages } from "src/components/UserProfilePage/translations/messages";
import { NotificationsSettings } from "IndexComponents";
import { SettingsProps } from "./types";
import useNotificationSettings from "./hooks/useNotificationSettings";
import { DIGEST_FREQUENCY } from "src/utils/constants";

const Settings: React.FC<SettingsProps> = ({
  applicationTypeIds,
  isUserAdmin,
  isCompanyAdmin,
}) => {
  const { formatMessage } = useIntl();

  const {
    notificationSettings,
    onChangeDigestFrequency,
    isDigestAvailable,
    isLoading,
  } = useNotificationSettings();

  if (isLoading) return <Spinner size="large" />;

  return (
    <>
      <div
        className="content-notif-settings-emailDigest"
        data-test-id="user-profile-content-notif-settings-digest"
      >
        <div className="content-notif-settings-emailDigest-actions">
          <div
            className="content-notif-settings-emailDigest-actions-toggle"
            data-test-id="user-profile-content-notif-settings-digest-actions"
          >
            <Heading
              size="xs"
              data-test-id="user-profile-content-notif-settings-digest-actions-title"
            >
              {formatMessage(notifSettingsContentMessages.emailDigest)}
            </Heading>
            <Toggle
              text={
                isDigestAvailable
                  ? formatMessage(notifSettingsContentMessages.activated)
                  : formatMessage(notifSettingsContentMessages.deactivated)
              }
              size="small"
              checked={isDigestAvailable}
              onChange={value => {
                onChangeDigestFrequency(
                  value ? DIGEST_FREQUENCY.DAILY : DIGEST_FREQUENCY.OFF
                );
              }}
              data-test-id="user-profile-content-notif-settings-digest-actions-toggle"
            />
          </div>
          <Select
            value={notificationSettings.digestFrequency}
            onSelect={onChangeDigestFrequency}
            options={Object.values(DIGEST_FREQUENCY)
              .filter(
                value => isDigestAvailable && value !== DIGEST_FREQUENCY.OFF
              )
              .map(value => ({
                value,
                label: formatMessage(
                  notifSettingsContentMessages[value.toLowerCase()]
                ),
              }))}
            disabled={!isDigestAvailable}
            allowClear={false}
            data-test-id="user-profile-content-notif-settings-digest-actions-select"
          />
        </div>
        {isDigestAvailable && (
          <Paragraph
            className="content-notif-settings-emailDigest-text"
            keydata-test-id="user-profile-content-notif-settings-digest-actions-text"
          >
            {notificationSettings.digestFrequency === DIGEST_FREQUENCY.DAILY
              ? formatMessage(notifSettingsContentMessages.dailyDescription)
              : formatMessage(notifSettingsContentMessages.weeklyDescription)}
          </Paragraph>
        )}
      </div>
      <div data-test-id="user-profile-content-notif-settings-digest-actions-notif-table">
        <NotificationsSettings
          applicationTypeIds={applicationTypeIds}
          isUserAdmin={isUserAdmin}
          isCompanyAdmin={isCompanyAdmin}
        />
      </div>
    </>
  );
};

export default Settings;
