import React from "react";
import { getPrefixClassName } from "src/utils/general";
import { Button, Graphic, Typography } from "@trace-one/design-system";
import { RedirectBannerProps } from "../../types";

const RedirectBanner = ({
  visible,
  bannerTitle,
  description,
  appIconName,
  onBackClick = () => {},
}: RedirectBannerProps) => {
  const prefixCls = getPrefixClassName("header");

  if (!visible) {
    return null;
  }

  return (
    <div
      className={`${prefixCls}-redirect-banner`}
      data-test-id="ds-header-redirect-banner"
    >
      <div className={`${prefixCls}-redirect-banner-content-container`}>
        <Button
          type="tertiary"
          color="grey"
          iconName="long-arrow-left"
          onClick={onBackClick}
        ></Button>

        <Button type="link" linkColor="grey-5" onClick={onBackClick}>
          <Graphic name={appIconName}></Graphic> {bannerTitle}
        </Button>

        <Typography color="grey-4" variant="body-regular">
          {description}
        </Typography>
      </div>
    </div>
  );
};

export default RedirectBanner;
