import { fetchPackagingComponentStructure } from "src/apis/pkg";
import { CreatePackagingProps } from "../../context/types";
import { PACKAGING_COMPONENT_PROPERTIES } from "../../constants";
import { ObjectSchema, Property, SectionData } from "../../types";
import { isListEmpty, isObjectEmpty } from "src/utils/general";

const usePackagingComponentStructure = () => {
  const getInputNumberProps = (properties: Property) => {
    const {
      multipleOf = undefined,
      minimum = undefined,
      maximum = undefined,
    } = properties;

    const inputNumberProps = {
      min: minimum ?? Number.MIN_SAFE_INTEGER,
      max: maximum ?? Number.MAX_SAFE_INTEGER,
    };

    if (!multipleOf) {
      return {
        precision: 0,
        step: 1,
        ...inputNumberProps,
      };
    }

    return {
      precision: multipleOf.toString().split(".")[1].length,
      step: multipleOf,
      ...inputNumberProps,
    };
  };

  const getBasicInformationJsonSchema = (
    sections: SectionData[]
  ): ObjectSchema => {
    if (isListEmpty(sections)) {
      return;
    }

    const jsonSchema = sections.find(
      ({ sectionCode }) => sectionCode === "basic_informations"
    )?.blocks[0]?.jsonSchema;

    return JSON.parse(jsonSchema);
  };

  const getPackagingComponentStructure = async (
    setComponentDimensionDictionary: CreatePackagingProps["setComponentDimensionDictionary"]
  ) => {
    const { data = {} } = await fetchPackagingComponentStructure("en-US");

    const basicInformationJsonSchema = getBasicInformationJsonSchema(
      data?.sections
    );

    const componentPropertiesPrecision = Object.values(
      PACKAGING_COMPONENT_PROPERTIES
    ).reduce((acc, cur) => {
      acc = {
        ...acc,
        [cur]: getInputNumberProps(
          basicInformationJsonSchema?.properties?.[cur]?.properties?.value
        ),
      };
      return acc;
    }, {});

    if (isObjectEmpty(componentPropertiesPrecision)) {
      return;
    }

    setComponentDimensionDictionary(componentPropertiesPrecision);
  };

  return { getPackagingComponentStructure };
};

export default usePackagingComponentStructure;
