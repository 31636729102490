import { MutableRefObject, useEffect, useRef, useState } from "react";
import { getFiles } from "src/apis/document";
import { DMSFileData } from "src/models";

const useDMS = ({
  isModalOpen,
  fileType,
  ownerCompanyId,
  infiniteScrollWrapper,
  metaDataSearches,
}: {
  isModalOpen?: boolean;
  fileType?: string;
  ownerCompanyId?: string;
  // eslint-disable-next-line no-undef
  infiniteScrollWrapper?: MutableRefObject<HTMLDivElement>;
  metaDataSearches?: Array<Object>;
}) => {
  const [dmsFiles, setDmsFiles] = useState<DMSFileData[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);
  const [canLoadMore, setCanLoadMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedFiles, setSelectedFiles] = useState<DMSFileData[]>([]);
  const [searchText, setSearchText] = useState<string>(undefined);

  const isFirstRender = useRef(true);

  const getDMSFiles = async (isSearch: boolean = false) => {
    try {
      setIsLoading(true);

      const {
        data: { items, totalCount },
      } = await getFiles({
        take: 30,
        skip: isSearch ? 0 : (currentPage - 1) * 30,
        ...(fileType && {
          fileTypes: [fileType],
        }),
        searchText: searchText || undefined,
        metaDataSearches: metaDataSearches,
        ownerCompanyId,
      });

      let newItemsLength = items.length;

      if (isSearch) {
        setCurrentPage(1);
        setDmsFiles(items);
      } else {
        setDmsFiles(previousState => {
          const newItemsList = [...previousState, ...items];

          newItemsLength = newItemsList.length;

          return newItemsList;
        });
      }

      setCurrentPage(previousState => previousState + 1);

      setTotalCount(totalCount);
      setCanLoadMore(totalCount > newItemsLength);
    } catch (_) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    infiniteScrollWrapper?.current.scrollTo({ top: 0 });

    getDMSFiles(true);
  }, [searchText]);

  useEffect(() => {
    if (isModalOpen) {
      getDMSFiles();
    }
  }, [isModalOpen]);

  return {
    isLoading,
    dmsFiles,
    canLoadMore,
    currentPage,
    getDMSFiles,
    selectedFiles,
    setSelectedFiles,
    setSearchText,
    totalCount,
  };
};

export default useDMS;
