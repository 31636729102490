import * as RLMD from "src/apis/rlmd";
import * as TON from "src/apis/ton";
import { RequestApps, notificationType } from "../components/Form/types";

export const getAppsList = async ({
  languageCode,
}: {
  languageCode: string;
}) => {
  try {
    const { data } = await RLMD.fetchAppsList({ languageCode });
    return data.referenceListItems.map(app => ({ ...app }));
  } catch (error) {
    return [];
  }
};

export const getAppAccessNotifStatus = async (
  values: RequestApps,
  userId: string,
  langcode: string
) => {
  try {
    const { data } = await TON.sendAppAccessNotif({
      ...values,
      userId,
      langcode,
    });
    return data.code === 1;
  } catch (e) {
    return false;
  }
};

export const isTypeConfirmation = (type: notificationType) =>
  type === "confirmation";
