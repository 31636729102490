import { useState } from "react";
import * as CUMD from "src/apis/cumd";
import { toaster } from "@trace-one/design-system";
import {
  notifSettingsContentMessages,
  settingsContentMessages,
} from "src/components/UserProfilePage/translations/messages";
import { useIntl } from "react-intl";

const useNotificationEmail = ({
  userId,
  userNotifEmail,
}: {
  userId: string;
  userNotifEmail: string;
}) => {
  const { formatMessage } = useIntl();

  const [email, setEmail] = useState<string>(userNotifEmail);
  const [confirmationEmail, setConfirmationEmail] =
    useState<string>(userNotifEmail);

  const updateEmail = value => {
    setEmail(value);
  };

  const updateConfirmationEmail = value => {
    setConfirmationEmail(value);
  };

  const updateNotifEmail = async () => {
    if (email !== confirmationEmail) {
      return;
    }

    try {
      await CUMD.updateUserProfile({
        userId,
        newNotificationEmail: email,
      });
      toaster.open({
        message: formatMessage(settingsContentMessages.toasterConfirmation),
        description: formatMessage(
          notifSettingsContentMessages.toasterConfirmationMsg
        ),
        type: "confirmation",
      });
    } catch (error) {
      toaster.open({
        message: formatMessage(settingsContentMessages.toasterAlert),
        description: formatMessage(settingsContentMessages.toasterAlertMsg),
        type: "alert",
      });
    }
  };

  return {
    email,
    updateEmail,
    confirmationEmail,
    updateConfirmationEmail,
    updateNotifEmail,
  };
};

export default useNotificationEmail;
