import React from "react";
import { Button, Icon, Spinner } from "@trace-one/design-system";
import { useLanguages } from "./hooks";
import { LanguageProps } from "./types";
import FlagIcon from "./FlagIcon";

const Language: React.FC<LanguageProps> = ({ user, onLanguageChange }) => {
  const { languages, onUserLanguageChange, isLoading } = useLanguages({
    userId: user.userId,
    languageCode: user.userLanguagePreference,
    onLanguageChange,
  });

  if (isLoading) {
    return <Spinner color="primary" size="small" />;
  }

  return (
    <>
      <div
        className="user-menu-language-select"
        data-test-id="user-menu-language-select"
      >
        <ul className="user-menu-language-selection--list">
          {languages.map(lang => {
            return (
              <li
                key={lang.value}
                data-test-id={`user-menu-language-item-${lang.value}`}
              >
                <Button
                  data-test-id={`user-menu-language-selection-button-${lang.value}`}
                  className="ds-d-flex ds-justify-content-between ds-w-100"
                  onClick={() => {
                    onUserLanguageChange(lang.value);
                  }}
                  type="link"
                  size="small"
                  linkColor="grey-5"
                >
                  <span className="ds-d-flex ds-align-items-center ds-gap-1">
                    <Icon
                      className="language-selection-hierarchy-icon"
                      name="hierarchy-link"
                      size="small"
                    ></Icon>
                    <FlagIcon languageCode={lang.value}></FlagIcon>
                    {lang.name}
                  </span>

                  {lang.value === user.userLanguagePreference && (
                    <Icon name="check" size="small" color="primary" />
                  )}
                </Button>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default Language;
