import { useEffect, useMemo, useState } from "react";
import { fetchPackagingComponents } from "../../../apis/pkg";
import { fetchReferenceListItems } from "../../../apis/rlmd";
import { LibraryObjectData, UseAddPackagingComponentsProps } from "../types";
import { Dictionary } from "../../../models";
import { getDictionary, getSkipParamForApi } from "../utils/helpers";
import {
  LIBRARY_OBJECT_TYPES,
  REFLIST_IDS,
  TAKE_ITEMS_FOR_INFINITE_PAGINATION,
} from "../constants";
import { prepareLibraryListItemFromApiData } from "./libraryItem";
import { isListEmpty } from "src/utils/general";

const useAddPackagingComponents = ({
  selectedPackagingComponents,
  setSelectedPackagingComponents,
  onCloseModal,
  onSelect,
  ownerCompanyId,
  languageCode,
  infiniteScrollWrapper,
}: UseAddPackagingComponentsProps) => {
  const [typeDictionary, setTypeDictionary] = useState<Dictionary>({});
  const [packagingComponentsList, setPackagingComponentsList] = useState<
    LibraryObjectData[]
  >([]);

  const [isLoading, setIsLoading] = useState(true);
  const [canLoadMore, setCanLoadMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalNumberOfItems, setTotalNumberOfItems] = useState(0);
  const [searchText, setSearchText] = useState<string>();

  const isPrimaryButtonDisabled = useMemo(
    () => isListEmpty(selectedPackagingComponents),
    [selectedPackagingComponents]
  );

  const handleSelectAction = () => {
    if (typeof onSelect === "function") {
      onSelect(selectedPackagingComponents);
    }
    onCloseModal();
  };

  const getPackagingComponentTypes = async () => {
    setIsLoading(true);
    try {
      const { data } = await fetchReferenceListItems({
        languageCode,
        id: REFLIST_IDS.PACKAGING_COMPONENTS_TYPE,
        take: 500,
      });
      setTypeDictionary(
        getDictionary({
          data: data.referenceListItems,
          key: "id",
          value: "text",
        })
      );
    } catch (_) {
    } finally {
      setIsLoading(false);
    }
  };

  const searchPackagingComponents = async ({
    searchText,
    isNewSearch,
  }: {
    searchText: string;
    isNewSearch?: boolean;
  }) => {
    try {
      setIsLoading(true);

      const {
        data: { items, totalNumberOfItems },
      } = await fetchPackagingComponents({
        params: {
          take: TAKE_ITEMS_FOR_INFINITE_PAGINATION,
          skip: getSkipParamForApi({
            isNewSearch,
            currentPage,
            pageSize: TAKE_ITEMS_FOR_INFINITE_PAGINATION,
          }),
          ownerCompanyId,
          searchText,
        },
      });

      let newItemsLength = items.length;
      setCurrentPage(previousState => (isNewSearch ? 2 : previousState + 1));

      if (isNewSearch) {
        setPackagingComponentsList(items);
      } else {
        setPackagingComponentsList(previousState => {
          const newItemsList = [...previousState, ...items];

          newItemsLength = newItemsList.length;

          return newItemsList;
        });
      }

      setTotalNumberOfItems(totalNumberOfItems);

      setCanLoadMore(totalNumberOfItems > newItemsLength);
    } catch (_) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPackagingComponentTypes();
    searchPackagingComponents({ searchText });
  }, []);

  useEffect(() => {
    if (searchText !== undefined) {
      // this is solving the issue of making multiple requests because the scroll is not at the top
      infiniteScrollWrapper.current.scrollTo({ top: 0 });

      searchPackagingComponents({ searchText, isNewSearch: true });
    }
  }, [searchText]);

  const onCloseModalHandler = () => {
    setSelectedPackagingComponents([]);
    onCloseModal();
  };

  const createData = list => {
    return list.map(item =>
      prepareLibraryListItemFromApiData({
        libraryItem: item,
        typeDictionary,
        type: LIBRARY_OBJECT_TYPES.PACKAGING_SYSTEMS,
      })
    );
  };

  return {
    isLoading,
    packagingComponentsList: createData(packagingComponentsList),
    canLoadMore,
    currentPage,
    totalNumberOfItems,
    searchPackagingComponents,
    onCloseModalHandler,
    isPrimaryButtonDisabled,
    searchText,
    setSearchText,
    handleSelectAction,
  };
};

export default useAddPackagingComponents;
