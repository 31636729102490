import { useEffect, useMemo, useState } from "react";
import { useLanguagePreference } from "src/hooks";
import * as API from "src/apis/notif";
import { NotificationSettings } from "src/models/notification";
import { DIGEST_FREQUENCY } from "src/utils/constants";

const useNotificationSettings = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [reloadNotificationSettings, setReloadNotificationSettings] =
    useState(false);
  const [notificationSettings, setNotificationSettings] =
    useState<NotificationSettings>(notificationSettingsInitialState);
  const languageCode = useLanguagePreference();

  const getNotificationSettings = async () => {
    setIsLoading(true);
    try {
      const { data } = await API.getNotificationSettings({
        languageCode,
      });

      setNotificationSettings(data);
      return data;
    } catch (error) {
      setNotificationSettings(notificationSettingsInitialState);
      return notificationSettingsInitialState;
    } finally {
      setIsLoading(false);
      setReloadNotificationSettings(false);
    }
  };

  const updateNotificationSettings = async ({
    notificationSettings,
  }: {
    notificationSettings: NotificationSettings;
  }) => {
    try {
      await API.updateNotificationSettings({
        notificationSettings,
      });
      setReloadNotificationSettings(true);
    } catch (_) {}
  };

  const onChangeDigestFrequency = async (digestFrequency: string) => {
    updateNotificationSettings({
      notificationSettings: {
        digestFrequency,
      },
    });
  };

  const isDigestAvailable = useMemo(
    () => notificationSettings.digestFrequency !== DIGEST_FREQUENCY.OFF,
    [notificationSettings.digestFrequency]
  );

  useEffect(() => {
    getNotificationSettings();
  }, [languageCode]);

  useEffect(() => {
    if (reloadNotificationSettings) {
      getNotificationSettings();
    }
  }, [reloadNotificationSettings]);

  return {
    notificationSettings,
    onChangeDigestFrequency,
    isDigestAvailable,
    isLoading,
  };
};

const notificationSettingsInitialState = {
  notificationPreferences: [],
  digestFrequency: DIGEST_FREQUENCY.OFF,
};

export default useNotificationSettings;
