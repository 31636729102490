import { useEffect, useMemo, useState } from "react";
import { useDebounce } from "IndexComponents";
import { getCompaniesByFilters } from "src/apis/cumd";
import { Company } from "src/apis/cumd/types";

const useGetCompanies = ({ companyId }) => {
  const [suppliers, setSuppliers] = useState<Company[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(searchValue, 300);

  const suppliersOptions = useMemo(() => {
    return suppliers.map(({ companyId, companyDisplayName }) => ({
      label: companyDisplayName,
      value: companyId,
      searchLabel: companyDisplayName,
    }));
  }, [suppliers]);

  const makeCompanyOptions = (suppliers: Company[]) => {
    return suppliers.map(({ companyId, companyDisplayName }) => ({
      label: companyDisplayName,
      value: companyId,
      searchLabel: companyDisplayName,
    }));
  };

  const getCompanies = async searchName => {
    try {
      setIsLoading(true);
      const res = await getCompaniesByFilters(null, { searchName });
      setSuppliers(res.data.companies);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const searchCompanies = async (searchName: string) => {
    const res = await getCompaniesByFilters(null, { searchName });
    return res.data.companies;
  };

  useEffect(() => {
    getCompanies(debouncedSearchValue);
  }, [companyId, debouncedSearchValue]);

  return {
    isLoading,
    suppliers,
    suppliersOptions,
    searchCompanies,
    setSearchValue,
    makeCompanyOptions,
  };
};

export default useGetCompanies;
