import React from "react";
import { Heading } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import Language from "./components/Language";
import { settingsContentMessages } from "src/components/UserProfilePage/translations/messages";
import { MySettingsProps } from "./types";
import Country from "./components/Country";
import TimeZone from "./components/TimeZone";
import Email from "./components/Email";
import { useAuthType } from "src/hooks";
import { AUTH_TYPE } from "src/utils/constants";

const MySettings: React.FC<MySettingsProps> = ({ user, onLanguageChange }) => {
  const { formatMessage } = useIntl();
  const { authType } = useAuthType(user.owningCompanyId);

  return (
    <div data-test-id="user-profile-content-my-settings">
      <Heading size="m">{formatMessage(settingsContentMessages.title)}</Heading>
      <div
        className="content-my-settings"
        data-test-id="user-profile-content-my-settings-language"
      >
        {authType !== AUTH_TYPE.OAUTH && <Email user={user} />}
        <Language user={user} onLanguageChange={onLanguageChange} />
        <div
          className="content-my-settings-details-container"
          data-test-id="user-profile-content-my-settings-details"
        >
          <Country user={user} />
          <TimeZone user={user} />
        </div>
      </div>
    </div>
  );
};

export default MySettings;
