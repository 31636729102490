import React from "react";

import { Graphic } from "@trace-one/design-system";
import { LangList } from "../../../../../utils/const";

const flagMapper = {
  [LangList.FR_FR]: "france-flag",
  [LangList.DE_DE]: "germany-flag",
  [LangList.IT_IT]: "italy-flag",
  [LangList.NL_BE]: "holland-flag",
  [LangList.PT_PT]: "portugal-flag",
  [LangList.ES_ES]: "spain-flag",
  [LangList.EN_US]: "uk-flag",
} as const;

interface FlagIconProps {
  languageCode: string;
}

const FlagIcon: React.FC<FlagIconProps> = ({ languageCode }) => {
  return <Graphic name={flagMapper[languageCode]} />;
};

export default FlagIcon;
