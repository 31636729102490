export enum LIBRARY_OBJECT_TYPES {
  REQUIREMENTS = "requirements",
  TEXT_REQUIREMENTS = "text-requirements",
  DOCUMENT_REQUIREMENTS = "document-requirements",
  MATERIALS = "materials",
  RAW_MATERIALS = "raw-materials",
  BOUGHT_TO_THIRD_PARTY = "bought-composites",
  MADE_IN_HOUSE = "made-composites",
  COMPOSITES = "composites",
  ADDITIVES = "additives",
  PROCESSING_AIDS = "processing-aids",
  CHAPTERS = "chapters",
  PRODUCTION = "production",
  CONDITIONING = "conditioning",
  TESTING = "testing",
  FINISHED_PRODUCTS = "finished-product",
  PACKAGING_SYSTEMS = "packaging-systems",
  PACKAGING_COMPONENTS = "packaging-components",
  TEMPLATES = "templates",
  TEMPLATE = "template",
  FOOD = "food",
  F_AND_V = "fAndV",
}

export enum LIBRARY_OBJECT_STATES {
  DRAFT = "draft",
  LOCKED = "locked",
  READY = "ready",
  PUBLISHED = "published",
}

export enum LIBRARY_TYPE_GRAPHICS {
  production = "production",
  finishedProduct = "finish-product",
  testing = "testing",
  template = "template",
  rawMaterial = "raw-material",
  "packaging-systems" = "packaging-system",
  "packaging-components" = "component",
  component = "component",
  requirement = "requirement",
  text = "requirement",
  document = "requirement",
  madeInHouse = "composite-made-in-house",
  boughtToThirdParty = "composite-third-party",
  additive = "additive",
  processingAid = "processing-aid",
}

export enum LIBRARY_CHAPTER_TYPE {
  PRODUCTION = "production",
  TESTING = "testing",
  FINISHED_PRODUCT = "finishedProduct",
}

export enum LIBRARY_REQUIREMENT_TYPES {
  DOCUMENT = "document",
  TEXT = "text",
}

export enum MATERIAL_TYPES {
  RAW_MATERIAL = "rawMaterial",
  COMPOSITE = "composite",
  ADDITIVE = "additive",
  PROCESSING_AID = "processingAid",
}

export enum RECIPE_TYPES {
  BOUGHT_TO_THIRD_PARTY = "boughtToThirdParty",
  MADE_IN_HOUSE = "madeInHouse",
}

export enum PRODUCT_TYPES {
  FOOD = "food",
  F_AND_V = "fAndV",
}

export enum REFLIST_IDS {
  PRODUCT_TYPES = "af391fb2-1d34-45ef-8400-cb14642b8445",
  CONTROL_PHASES = "a3fbe681-73fd-4bdb-b072-8454416e4f9a",
  CONTROL_TYPES = "3daf8078-dea2-47c8-9725-0725538ef9af",
  ORIGIN_TYPES = "7ace3dbd-4181-4c56-97e1-039794e56e8c",
  COUNTRIES = "3B123F34-E7A3-4FE0-AFF0-D31CE33E924C",
  REGIONS = "ffff99ef-0d1f-4602-acb8-0a78e0d3c14e",
  ZONES = "d3f1c34f-9cb9-4100-90f1-410a1841ca77",
  SOURCES = "2093fc20-f99a-4c25-a691-4123aa6d30a3",
  PARTS = "b67f5c74-257f-4ef7-9650-c5ad791e96e2",
  FUNCTIONS = "cbb55aac-6ac5-4766-99c7-286421145537",
  ALLERGENS = "bc7d5937-7f05-4363-a811-a91218386049",
  NUTRIENTS = "aa81f2b0-6bc4-45a7-9eaf-49600876eede",
  QUALITY_MANAGER_RESPONSIBILITY = "4f86a4e7-be95-4cba-a9c0-c42bc8f54fef",
  QUALITY_MANAGER_ROLE = "41627065-367c-488b-acb6-01ba6963906a",
  COMPANY_ACTIVITY = "2a585e99-1ca3-49c7-83cd-d8ec352f442e",
  UNITS_OF_MEASURE = "8ff32c51-aef6-4ff8-826d-0baa2b0ae10e",
  INTERNATIONAL_SYSTEM_SOURCE = "14dfe812-c247-4d6e-8c99-4332c865b2f3",
  MASS = "97c9a7a1-9f4d-4aba-a710-3f569e95d683",
  VOLUME = "cdea27bd-807f-4a57-b5f8-4b7073a5e98c",
  F_AND_V_PRODUCT_TYPE = "51e3e0fc-de32-4ca7-b1d3-ae6d64df3297",
  FOOD_PRODUCT_TYPE = "fda02edf-d3ff-47f0-b9b2-7a06f9aa832f",
  PACKAGING_COMPONENTS_TYPE = "b171e5be-f0b5-433f-a27f-7a06a640ccfc",
}

export const TAKE_ITEMS_FOR_INFINITE_PAGINATION = 24;

export enum PACKAGING_COMPONENT_PROPERTIES {
  HEIGHT = "height",
  LENGTH = "length",
  WIDTH = "width",
  WEIGHT = "weight",
  DIAMETER = "diameter",
}
