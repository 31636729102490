import React, { useEffect, useState } from "react";
import { Graphic } from "@trace-one/design-system";
import { messages } from "../../translations/messages";
import { useIntl } from "react-intl";
import { isMainNavActive } from "../../utils";
import { UseHeaderLinksProps } from "./types";

const useHeaderLinks = ({
  routes,
  displayAdminUserAndAccessRoute,
  adminUserAndAccessUrl,
  adminSubRoutes,
  pathname,
}: UseHeaderLinksProps) => {
  const { formatMessage } = useIntl();

  const buildAdminRoute = () => {
    const adminSubNav = [...adminSubRoutes];

    if (displayAdminUserAndAccessRoute) {
      adminSubNav.push({
        path: adminUserAndAccessUrl,
        text: (
          <div className="user-access-link-wrapper">
            <Graphic name="app-logo-admin-letter" />
            <span>{formatMessage(messages.usersAndAccess)}</span>
          </div>
        ),
        onClick: () => {
          window.open(adminUserAndAccessUrl, "_self");
        },
      });
    }

    return adminSubNav.length > 0
      ? {
          path: "#",
          text: formatMessage(messages.administration),
          subNav: adminSubNav,
        }
      : null;
  };

  const buildRoutes = () => {
    const adminRoute = buildAdminRoute();
    const routeList = adminRoute ? [...routes, adminRoute] : routes;

    return routeList.map(route => {
      const { path, subNav } = route;
      return {
        ...route,
        isSelected: isMainNavActive({ pathname, path, subNav })(),
      };
    });
  };

  const [navList, setNavList] = useState(buildRoutes());

  useEffect(() => {
    setNavList(buildRoutes());
  }, [
    JSON.stringify(routes),
    JSON.stringify(adminSubRoutes),
    adminUserAndAccessUrl,
  ]);

  const getOnMainNavClick =
    ({ index }: { index: number }) =>
      // eslint-disable-next-line no-undef
      (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();

        setNavList(prev => {
          return prev.map((item, prevIndex) => {
            if (index === prevIndex) {
              return {
                ...item,
                isSelected: !item.isSelected,
              };
            }

            return {
              ...item,
              isSelected: false,
            };
          });
        });
      };

  return { navList, setNavList, getOnMainNavClick };
};

export default useHeaderLinks;
