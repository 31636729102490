import { AxiosRequestConfig } from "axios";
import { AxiosInstancesManager } from "../../configs/AxiosInstancesManager";
import {
  Brand,
  BrandParams,
  ParamsWithPagination,
  TradeItemParams,
  TradeItemsResponse,
} from "./types";

export const fetchTradeItems = (
  data?: {
    tradeItemStatuses?: string[];
    searchText?: string;
    supplierCompanyIds?: string[];
    brandIds?: string[];
  },
  params?: ParamsWithPagination<TradeItemParams>,
  config?: AxiosRequestConfig
): Promise<{ data: TradeItemsResponse }> => {
  const pmdInstance = AxiosInstancesManager.getPmd();

  return pmdInstance.post<TradeItemsResponse>(`/api/tradeItems/filters`, data, {
    params,
    ...config,
  });
};

export const fetchManufacturedItems = (
  data?: {
    manufacturedItemStatuses?: string[];
  },
  params?: ParamsWithPagination<TradeItemParams>,
  config?: AxiosRequestConfig
): Promise<{ data: TradeItemsResponse }> => {
  const pmdInstance = AxiosInstancesManager.getPmd();
  return pmdInstance.post<TradeItemsResponse>(
    `/api/manufacturedItems/filters`,
    data,
    {
      params,
      ...config,
    }
  );
};

export const getBrandsByOwnerCompanyId = (
  params?: BrandParams,
  config?: AxiosRequestConfig
) => {
  const pmdInstance = AxiosInstancesManager.getPmd();
  return pmdInstance.get<Brand[]>(`/api/brands`, { params, ...config });
};
