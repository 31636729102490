import * as API from "src/apis/cumd";
import { useEffect, useState } from "react";
import { CompanyDetailsData } from "src/models/companyDetails";

const useCompanyDetails = (companyId: string) => {
  const [companyDetails, setCompanyDetails] =
    useState<CompanyDetailsData>(null);
  const [isLoading, setIsLoading] = useState(true);

  const getCompanyDetails = async () => {
    setIsLoading(true);

    try {
      const { data } = await API.getCompanyDetails(companyId);
      setCompanyDetails(data);
    } catch (error) {
      // error should be managed
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (companyId) {
      getCompanyDetails();
    }
  }, [companyId]);

  return { companyDetails, isLoading };
};

export default useCompanyDetails;
