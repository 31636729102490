/* istanbul ignore file */

import { AxiosInstancesManager } from "src/configs/AxiosInstancesManager";
import {
  PackagingSystemComponentData,
  PackagingSystemComponentObjectData,
  SectionBlockData,
  SimpleListItemData,
} from "src/types";
import {
  PackagingComponentData,
  FetchPackagingSystemByIdTypeProps,
  DuplicateErrorObject,
  PackagingComponentStructure,
} from "src/components/PackagingSystem/types";
import { CreatePackagingSystemProps } from "src/components/PackagingSystem/components/CreatePackagingSystem/types";
import {
  PACKAGING_COMPONENT_PREFIX,
  PACKAGING_SYSTEMS_PREFIX,
} from "../constants";
import { ERROR_CODES } from "../constants";
import { LibraryObjectData } from "../../components/PackagingSystem/types";
import { setComponentData } from "src/components/PackagingSystem/utils/helpers";

export const fetchPackagingComponent = ({
  packagingComponentId,
  languageCode,
}: {
  packagingComponentId: PackagingSystemComponentData["packagingComponentId"];
  languageCode: string;
}) =>
  AxiosInstancesManager.getPkg().get<PackagingSystemComponentObjectData>(
    `/api/packagingComponents/${packagingComponentId}`,
    {
      params: {
        languageCode,
      },
    }
  );

export const updatePackagingComponentName = ({
  packagingComponentId,
  name,
}: Pick<PackagingSystemComponentData, "packagingComponentId" | "name">) =>
  AxiosInstancesManager.getPkg().put<
    Pick<PackagingSystemComponentObjectData, "version" | "name" | "level">
  >(`/api/v2/packaging-components/${packagingComponentId}/name`, {
    value: name,
  });

export const updatePackagingComponentUsageLevel = ({
  packagingComponentId,
  usageLevel,
}: Pick<PackagingSystemComponentData, "packagingComponentId" | "usageLevel">) =>
  AxiosInstancesManager.getPkg().put<
    Pick<PackagingSystemComponentObjectData, "version" | "name" | "level">
  >(`/api/v2/packaging-components/${packagingComponentId}/usage-level`, {
    value: usageLevel,
  });

export const updatePackagingComponentSectionTemplateBlock = ({
  packagingComponentId,
  templateBlockId,
  dataAsJson,
}: {
  packagingComponentId: PackagingSystemComponentData["packagingComponentId"];
  templateBlockId: SectionBlockData["templateBlockId"];
  dataAsJson: SectionBlockData["dataAsJson"];
}) =>
  AxiosInstancesManager.getPkg().put<PackagingSystemComponentData>(
    `/api/v2/packaging-components/${packagingComponentId}/blocks/${templateBlockId}`,
    {
      dataAsJson,
    }
  );

export const fetchPackagingUsage = ({
  name,
  languageCode,
}: {
  name?: string;
  languageCode?: string;
}) =>
  AxiosInstancesManager.getPkg().get<SimpleListItemData[]>(
    `/api/packaging-usage`,
    {
      params: { name, languageCode },
    }
  );

export const updatePackagingSystem = async ({
  id,
  name,
  componentIds,
  setDuplicateErrorObject,
}: {
  id: string;
  name: string;
  componentIds: PackagingComponentData["componentId"][];
  setDuplicateErrorObject: Function;
}) => {
  try {
    await AxiosInstancesManager.getPkg().put<void>(
      `${PACKAGING_SYSTEMS_PREFIX}/${id}`,
      {
        name,
        componentIds,
      }
    );
  } catch (error) {
    setDuplicateErrorObject((prevState: DuplicateErrorObject | null) =>
      error.response?.data?.errorCode === ERROR_CODES.DUPLICATE_PACKAGING_NAME
        ? { ...(prevState || {}), packagingSystem: name }
        : null
    );

    console.error(error);
  }
};

export const updatePackagingComponent = async ({
  component,
  setDuplicateErrorObject,
  setUpdatedRecords,
}: {
  component: PackagingComponentData;
  setDuplicateErrorObject: Function;
  setUpdatedRecords: Function;
}) => {
  const { componentId, ...payload } = component;
  try {
    await AxiosInstancesManager.getPkg().put<void>(
      `${PACKAGING_COMPONENT_PREFIX}/${componentId}`,
      {
        ...payload,
      }
    );

    setUpdatedRecords(prevState =>
      prevState.map(comp => {
        if (comp.componentId === componentId) {
          return setComponentData(component);
        }
        return comp;
      })
    );
  } catch (error) {
    setDuplicateErrorObject((prevState: DuplicateErrorObject | null) =>
      error.response?.data?.errorCode === ERROR_CODES.DUPLICATE_PACKAGING_NAME
        ? {
            ...prevState,
            packagingComponents: [
              ...(prevState?.packagingComponents || []),
              component.name,
            ],
          }
        : prevState
    );

    console.error(error);

    throw error;
  }
};

export const createPackagingSystem = async ({
  name,
  componentIds,
  setDuplicateErrorObject,
}: {
  name: string;
  componentIds: PackagingComponentData["componentId"][];
  setDuplicateErrorObject: Function;
}) => {
  try {
    const { data } = await AxiosInstancesManager.getPkg().post<
      CreatePackagingSystemProps["packagingSystemId"]
    >(PACKAGING_SYSTEMS_PREFIX, {
      name,
      componentIds,
    });
    return data;
  } catch (error) {
    setDuplicateErrorObject((prevState: DuplicateErrorObject | null) =>
      error.response?.data?.errorCode === ERROR_CODES.DUPLICATE_PACKAGING_NAME
        ? { ...(prevState || {}), packagingSystem: name }
        : null
    );

    console.error(error);
  }
};

export const createPackagingComponent = async ({
  component,
  setDuplicateErrorObject,
}: {
  component: PackagingComponentData;
  setDuplicateErrorObject: Function;
}) => {
  try {
    const { data } = await AxiosInstancesManager.getPkg().post<
      PackagingComponentData["componentId"]
    >(PACKAGING_COMPONENT_PREFIX, {
      ...component,
    });

    return data;
  } catch (error) {
    setDuplicateErrorObject((prevState: DuplicateErrorObject | null) =>
      error.response?.data?.errorCode === ERROR_CODES.DUPLICATE_PACKAGING_NAME
        ? {
            ...prevState,
            packagingComponents: [
              ...(prevState?.packagingComponents || []),
              component.name,
            ],
          }
        : prevState
    );

    console.error(error);

    throw error;
  }
};

export const fetchPackagingSystemById = async (packagingSystemId: string) => {
  try {
    const packagingInstance = AxiosInstancesManager.getPkg();
    const { data } =
      await packagingInstance.get<FetchPackagingSystemByIdTypeProps>(
        `${PACKAGING_SYSTEMS_PREFIX}/${packagingSystemId}`
      );

    return data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchPackagingComponents = ({ params }) =>
  AxiosInstancesManager.getPkg()<{
    totalNumberOfItems: number;
    skippedNumberOfItems: number;
    items: LibraryObjectData[];
  }>(`/api/packaging-components/filters`, {
    params,
  });

export const fetchPackagingComponentCollection = async (
  componentIds: string[]
) => {
  try {
    const packagingInstance = AxiosInstancesManager.getPkg();
    const { data } = await packagingInstance.post<PackagingComponentData[]>(
      `${PACKAGING_COMPONENT_PREFIX}/collection`,
      {
        idsIn: componentIds,
      }
    );
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchPackagingComponentStructure = (languageCode: string) =>
  AxiosInstancesManager.getPkg().get<PackagingComponentStructure>(
    `${PACKAGING_COMPONENT_PREFIX}/smart-document/structure`,
    {
      params: {
        languageCode,
      },
    }
  );
