import React from "react";
import { Heading, Button } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import { sideBarMessages } from "../../../../translations/messages";
import { MenuProps } from "./types";
import { AUTH_TYPE, USER_PROFILE_TILE } from "src/utils/constants";
import classNames from "classnames";
import { useAuthType, useRedirects } from "src/hooks";

const Menu: React.FC<MenuProps> = ({ user, selectedTile, setSelectedTile }) => {
  const { formatMessage } = useIntl();

  const {
    redirectToMyProfile,
    redirectToAdminSettings,
    redirectToCompanyProfile,
    redirectToChangePassword,
  } = useRedirects();

  const handleTileChange = (selectedTile: string) => {
    setSelectedTile(selectedTile);
  };

  const { authType } = useAuthType(user.owningCompanyId);

  return (
    <div className="menu" data-test-id="user-profile-page-sidebar-menu">
      <div
        className="link-menu-tile"
        data-test-id="user-profile-page-sidebar-menu-my-profile"
      >
        <Button
          onClick={redirectToMyProfile}
          type="link"
          iconName="link"
          iconPlacement="right"
          size="large"
        >
          {formatMessage(sideBarMessages.myProfile)}
        </Button>
      </div>

      <div
        onClick={() => handleTileChange(USER_PROFILE_TILE.MY_SETTINGS)}
        className={classNames("menu-title", {
          selected: selectedTile === USER_PROFILE_TILE.MY_SETTINGS,
        })}
        data-test-id="user-profile-page-sidebar-menu-my-settings"
      >
        <Heading size="xxs">
          {formatMessage(sideBarMessages.mySettings)}
        </Heading>
      </div>
      <div
        onClick={() =>
          handleTileChange(USER_PROFILE_TILE.NOTIFICATION_SETTINGS)
        }
        className={classNames("menu-title", {
          selected: selectedTile === USER_PROFILE_TILE.NOTIFICATION_SETTINGS,
        })}
        data-test-id="user-profile-page-sidebar-menu-notif-settings"
      >
        <Heading size="xxs">
          {formatMessage(sideBarMessages.notification)}
        </Heading>
      </div>

      {user.isAccountAdministrator && (
        <div
          className="link-menu-tile"
          data-test-id="user-profile-page-sidebar-menu-admin-settings"
        >
          <Button
            onClick={redirectToAdminSettings}
            type="link"
            iconName="link"
            iconPlacement="right"
            size="large"
          >
            {formatMessage(sideBarMessages.administrationSettings)}
          </Button>
        </div>
      )}
      <div
        className="link-menu-tile"
        data-test-id="user-profile-page-sidebar-menu-company-profile"
      >
        <Button
          onClick={redirectToCompanyProfile}
          type="link"
          iconName="link"
          iconPlacement="right"
          size="large"
        >
          {formatMessage(sideBarMessages.companyProfile)}
        </Button>
      </div>

      {authType !== AUTH_TYPE.OAUTH && (
        <div
          className="link-menu-tile"
          data-test-id="user-profile-page-sidebar-menu-password"
        >
          <Button
            onClick={redirectToChangePassword}
            type="link"
            iconName="link"
            iconPlacement="right"
            size="large"
          >
            {formatMessage(sideBarMessages.changePassword)}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Menu;
