/* istanbul ignore file */

import React from "react";
import { locale } from "moment";
import { RawIntlProvider } from "react-intl";
import { useIntlLocale } from "src/hooks";
import { LanguagePreferenceContext } from "./contexts";
import { LanguagePreferenceProviderProps } from "./types";

const Children: React.FC<LanguagePreferenceProviderProps> = ({
  translations,
  children,
  languageCode,
}) => {
  const { intl, locale: hookLocale } = useIntlLocale({
    translations,
    languageCode,
  });

  locale(hookLocale || "en-US");

  if (!translations) {
    return <>{children}</>;
  }

  return <RawIntlProvider value={intl}>{children}</RawIntlProvider>;
};

const LanguagePreferenceProvider: React.FC<LanguagePreferenceProviderProps> = ({
  children,
  languageCode,
  translations,
}) => {
  const shouldUseLanguageParentContext = !languageCode;

  if (shouldUseLanguageParentContext) {
    return (
      <Children
        translations={translations}
        languageCode={languageCode}
        children={children}
      />
    );
  }

  return (
    <LanguagePreferenceContext.Provider value={languageCode}>
      <Children
        translations={translations}
        languageCode={languageCode}
        children={children}
      />
    </LanguagePreferenceContext.Provider>
  );
};

export default LanguagePreferenceProvider;
