import React from "react";
import { ContentProps } from "./types";
import MySettings from "./MySettings";
import NotifSettings from "./NotifSettings";
import { USER_PROFILE_TILE } from "src/utils/constants";

const Content: React.FC<ContentProps> = ({
  user,
  onLanguageChange,
  applicationTypeIds,
  selectedTile,
  isCompanyAdmin,
}) => {
  return (
    <div className="content" data-test-id="user-profile-content">
      {selectedTile === USER_PROFILE_TILE.MY_SETTINGS
        ? (
        <MySettings user={user} onLanguageChange={onLanguageChange} />
          )
        : (
        <NotifSettings
          user={user}
          applicationTypeIds={applicationTypeIds}
          isCompanyAdmin={isCompanyAdmin}
        />
          )}
    </div>
  );
};

export default Content;
