import { useState } from "react";
import { TradeItem } from "src/models/tradeitem";

const useSelectedProducts = ({ onClose }: { onClose: (x: any) => void }) => {
  const [selectedProducts, setSelectedProducts] = useState([]);

  const handleAddSelectedProduct = (product: TradeItem) => {
    setSelectedProducts(current => current.concat(product));
  };

  const handleRemoveSelectedProduct = (productId: string) => {
    setSelectedProducts(current =>
      current.filter(product => product.id !== productId)
    );
  };

  const onValidateClick = () => {
    onClose({ selectedProducts });
  };

  const isProductSelected = (item: TradeItem) => {
    return !!selectedProducts.includes(item);
  };

  return {
    selectedProducts,
    handleAddSelectedProduct,
    handleRemoveSelectedProduct,
    onValidateClick,
    isProductSelected,
  };
};

export default useSelectedProducts;
