import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "shared.business.modalProductSelect.modal.title",
    defaultMessage: "Add your existing product(s)",
  },
  description: {
    id: "shared.business.modalProductSelect.modal.description",
    defaultMessage:
      "Feel free to include as many existing products as required for your project. Rest assured, you can edit this list at any time. By default, only products associated with a supplier will be visible. If you'd like to view all products, please utilize the advanced search feature.",
  },
  basketTitle: {
    id: "shared.business.modalProductSelect.basket.title",
    defaultMessage: "Your selection",
  },
  basketValidate: {
    id: "shared.business.modalProductSelect.basket.validate",
    defaultMessage: "Validate",
  },
  filterSearchPlaceholder: {
    id: "shared.business.modalProductSelect.filter.searchPlaceholder",
    defaultMessage: "Enter a product name, GTIN",
  },
  filterSupplierTitle: {
    id: "shared.business.modalProductSelect.filter.supplierTitle",
    defaultMessage: "Supplier",
  },
  filterCategoryTitle: {
    id: "shared.business.modalProductSelect.filter.categoryTitle",
    defaultMessage: "Category",
  },
  filterBrandTitle: {
    id: "shared.business.modalProductSelect.filter.brandTitle",
    defaultMessage: "Brand",
  },
  filterWithSuppliersTitle: {
    id: "shared.business.modalProductSelect.filter.withSuppliersTitle",
    defaultMessage: "With suppliers",
  },
  listResults: {
    id: "shared.business.modalProductSelect.list.results",
    defaultMessage: "results",
  },
  listSortBy: {
    id: "shared.business.modalProductSelect.list.sortBy",
    defaultMessage: "Sort by",
  },
});

export default messages;
