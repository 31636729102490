import React from "react";
import { useIntl } from "react-intl";
import { Graphic, Tooltip } from "@trace-one/design-system";
import { useRemove } from "./hooks";
import { itemMessages } from "src/components/UploadFiles/translations/messages";
import { RemoveProps } from "./types";

const Remove: React.FC<RemoveProps> = ({
  itemPrefixCls,
  hasError,
  onRemove,
  fileId,
  showRemoveTooltip,
  messages,
}) => {
  const { formatMessage } = useIntl();
  const { isTooltipVisible, actions, onOpenChange, onRemoveClick } = useRemove({
    onRemove,
    messages,
  });

  if ((hasError && !fileId) || !showRemoveTooltip) {
    return (
      <div
        className={`${itemPrefixCls}-remove`}
        data-test-id="upload-files-item-remove"
        // @ts-ignore-next
        onClick={onRemove}
      >
        <Graphic name={hasError && !fileId ? "alert" : "close"} size="small" />
      </div>
    );
  }

  return (
    <Tooltip
      trigger={["click"]}
      placement="right"
      text={
        messages?.removeTooltipMessage ??
        formatMessage(itemMessages.removeTooltipMessage)
      }
      actions={actions}
      open={isTooltipVisible}
      onOpenChange={onOpenChange}
    >
      <div
        className={`${itemPrefixCls}-remove`}
        data-test-id="upload-files-item-remove"
        onClick={onRemoveClick}
      >
        <Graphic name={hasError ? "alert" : "close"} size="small" />
      </div>
    </Tooltip>
  );
};

export default Remove;
