import { useState } from "react";
import { RadioChangeEvent } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import {
  NotificationPreference,
  NotificationStatus,
} from "src/models/notification";
import {
  NotificationFilterFormState,
  NotificationFiltersQueryState,
} from "../../types";
import moment from "moment";
import {
  getDefaultNotificationStatus,
  getDefaultDaterangeValue,
} from "../../utils";

const getDefaultNotificationTypeIds = (
  notificationTypeIds: string[],
  notificationTypes: NotificationPreference[]
) => {
  return notificationTypeIds
    ? notificationTypes
      .filter(type => notificationTypeIds.includes(type.notificationTypeId))
      .map(type => type.notificationTypeId)
    : [];
};

const useNotificationsFilterControls = (
  filters: NotificationFiltersQueryState,
  setFilters: (value: NotificationFiltersQueryState) => void,
  notificationTypes: NotificationPreference[]
) => {
  const [filterControlValues, setFilterControlValues] =
    useState<NotificationFilterFormState>({
      notificationStatus: getDefaultNotificationStatus(filters.status),
      dateRange: getDefaultDaterangeValue(
        filters.notificationEventUtcDateTimeAfter,
        filters.notificationEventUtcDateTimeBefore
      ),
      selectedNotificationTypes: getDefaultNotificationTypeIds(
        filters.notificationTypeIds,
        notificationTypes
      ),
    });

  const handleReadUnReadFilterChange = (e: RadioChangeEvent) => {
    setFilterControlValues(value => ({
      ...value,
      notificationStatus: e.target.value,
    }));
  };

  const handleDateSelection = (e: [moment.Moment, moment.Moment] | null) => {
    setFilterControlValues(value => ({
      ...value,
      dateRange: e,
    }));
  };

  const handleNotificationTypeSelection = (e: CheckboxValueType[]) => {
    if (e.includes("all")) {
      setFilterControlValues(value => ({
        ...value,
        selectedNotificationTypes: notificationTypes.map(
          notiffType => notiffType.notificationTypeId
        ),
      }));
    } else {
      setFilterControlValues(value => ({
        ...value,
        selectedNotificationTypes: e as string[],
      }));
    }
  };

  const applyFilters = () => {
    let status: string;
    if (filterControlValues.notificationStatus === "read") {
      status = NotificationStatus.READ;
    }

    if (filterControlValues.notificationStatus === "unread") {
      status = NotificationStatus.UNREAD;
    }

    const filters: NotificationFiltersQueryState = {};
    if (status) {
      Object.assign(filters, { status });
    }
    if (Array.isArray(filterControlValues.dateRange)) {
      const [from, to] = filterControlValues.dateRange;
      Object.assign(filters, {
        notificationEventUtcDateTimeAfter: from?.toISOString(),
        notificationEventUtcDateTimeBefore: to?.toISOString(),
      });
    }

    if (filterControlValues.selectedNotificationTypes.length > 0) {
      Object.assign(filters, {
        notificationTypeIds: filterControlValues.selectedNotificationTypes,
      });
    }

    setFilters(filters);
  };

  return {
    handleReadUnReadFilterChange,
    handleDateSelection,
    handleNotificationTypeSelection,
    filterControlValues,
    applyFilters,
  };
};

export default useNotificationsFilterControls;
