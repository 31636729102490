import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "business.components.dms.header.title",
    defaultMessage: "Documents library",
  },
  searchPlaceholder: {
    id: "business.components.dms.header.search.placeholder",
    defaultMessage: "Search by name",
  },
  contentTitle: {
    id: "business.components.dms.content.tite",
    defaultMessage: "Your files",
  },
  cancelBtn: {
    id: "business.components.dms.actions.cancel",
    defaultMessage: "Cancel",
  },
  selectBtn: {
    id: "business.components.dms.actions.select",
    defaultMessage: "Select",
  },
});
