import React from "react";
import { Heading, Toggle, Tooltip } from "@trace-one/design-system";

import { Col, Row } from "antd";
import { NOTIFICATION_TYPE } from "src/utils/constants";
import { ResultProps } from "./types";
import { useIntl } from "react-intl";
import { notificationSettingsMessages } from "src/components/NotificationSettings/translations/messages";
import CommunicationMethodToggle from "./components/Toggle";

export const Result: React.FC<ResultProps> = ({
  notificationPreferences,
  onChangeNotificationCommunicationMethod,
  onChangeNotificationDigest,
  isDigestAvailable,
}) => {
  const { formatMessage } = useIntl();

  return (
    <div className="notification-settings-container-table-scrollable-container">
      {notificationPreferences.map(settingsItem => (
        <Row
          key={settingsItem.notificationTypeId}
          className="notification-settings-container-table-scrollable-container-row"
        >
          <Col span={isDigestAvailable ? 12 : 14}>
            <Heading size="xxs">{settingsItem.notificationTypeTitle}</Heading>
          </Col>

          <Col span={isDigestAvailable ? 4 : 5}>
            {settingsItem.isDigestActive
              ? (
              <Tooltip
                text={formatMessage(
                  notificationSettingsMessages.inAppIsMandatory
                )}
              >
                <>
                  <CommunicationMethodToggle
                    statusForDigest={true}
                    settingsItem={settingsItem}
                    notificationType={NOTIFICATION_TYPE.WEB}
                    onChange={onChangeNotificationCommunicationMethod}
                  />
                </>
              </Tooltip>
                )
              : Array.isArray(
                settingsItem.notificationTypeDefaultCommunicationMethods
              ) &&
              settingsItem.notificationTypeDefaultCommunicationMethods.includes(
                NOTIFICATION_TYPE.WEB
              )
                ? (
              <CommunicationMethodToggle
                statusForDigest={true}
                settingsItem={settingsItem}
                notificationType={NOTIFICATION_TYPE.WEB}
                onChange={onChangeNotificationCommunicationMethod}
              />
                  )
                : (
              <Tooltip
                text={formatMessage(
                  notificationSettingsMessages.optionNotAllowed
                )}
              >
                <>
                  <CommunicationMethodToggle
                    statusForDigest={true}
                    settingsItem={settingsItem}
                    notificationType={NOTIFICATION_TYPE.WEB}
                    onChange={onChangeNotificationCommunicationMethod}
                  />
                </>
              </Tooltip>
                  )}
          </Col>

          <Col span={isDigestAvailable ? 4 : 5}>
            {settingsItem.isDigestActive
              ? (
              <Tooltip
                text={formatMessage(notificationSettingsMessages.noSingleEmail)}
              >
                <>
                  <CommunicationMethodToggle
                    statusForDigest={false}
                    settingsItem={settingsItem}
                    notificationType={NOTIFICATION_TYPE.EMAIL}
                    onChange={onChangeNotificationCommunicationMethod}
                  />
                </>
              </Tooltip>
                )
              : Array.isArray(
                settingsItem.notificationTypeDefaultCommunicationMethods
              ) &&
              settingsItem.notificationTypeDefaultCommunicationMethods.includes(
                NOTIFICATION_TYPE.EMAIL
              )
                ? (
              <CommunicationMethodToggle
                statusForDigest={false}
                settingsItem={settingsItem}
                notificationType={NOTIFICATION_TYPE.EMAIL}
                onChange={onChangeNotificationCommunicationMethod}
              />
                  )
                : (
              <Tooltip
                text={formatMessage(
                  notificationSettingsMessages.optionNotAllowed
                )}
              >
                <>
                  <CommunicationMethodToggle
                    statusForDigest={false}
                    settingsItem={settingsItem}
                    notificationType={NOTIFICATION_TYPE.EMAIL}
                    onChange={onChangeNotificationCommunicationMethod}
                  />
                </>
              </Tooltip>
                  )}
          </Col>

          {isDigestAvailable && (
            <Col span={4}>
              {settingsItem.isUserAllowedToSubscribeInDigest
                ? (
                <Toggle
                  checked={settingsItem.isDigestActive}
                  size="small"
                  onClick={onChangeNotificationDigest({
                    id: settingsItem.notificationTypeId,
                  })}
                />
                  )
                : (
                <Tooltip
                  text={formatMessage(
                    notificationSettingsMessages.emailDigestNotAllowed
                  )}
                >
                  <>
                    <Toggle
                      checked={settingsItem.isDigestActive}
                      size="small"
                      onClick={onChangeNotificationDigest({
                        id: settingsItem.notificationTypeId,
                      })}
                      disabled
                    />
                  </>
                </Tooltip>
                  )}
            </Col>
          )}
        </Row>
      ))}
    </div>
  );
};

export default Result;
