import { useEffect, useMemo, useState } from "react";
import { fetchNetContent } from "src/apis/rlmd";
import { ReferenceListItem } from "src/models";

const useNetContents = ({ languageCode = "en-US" }) => {
  const [netContent, setNetContent] = useState<ReferenceListItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchNetcontent();
  }, [languageCode]);

  const netContentMeasures = useMemo(() => {
    return netContent.reduce(
      (prev, current) => ({
        ...prev,
        [current.id]: current.text,
      }),
      {}
    );
  }, [netContent]);

  const fetchNetcontent = async () => {
    try {
      setIsLoading(true);
      const res = await fetchNetContent({ languageCode });
      setNetContent(res.data);
    } catch (error) {
      console.error("failed to fetch net contents", error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    netContent,
    netContentMeasures,
  };
};

export default useNetContents;
