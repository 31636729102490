import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { Icon } from "@trace-one/design-system";

const BackButton = () => {
  const history = useHistory();
  const [previousPath, setPreviousPath] = useState("");

  useEffect(() => {
    const {
      location: { state },
    } = history;

    const backPath = state?.from ?? "/";

    setPreviousPath(backPath);
  }, []);

  return (
    <Link
      to={previousPath}
      className="notification-settings-container-header-back-arrow"
    >
      <Icon name="long-arrow-left" data-test-id="back-arrow" />
    </Link>
  );
};

export default BackButton;
