import { useEffect, useState } from "react";
import * as API from "src/apis/cumd";
import { UserData } from "src/models";

const useUser = ({ userId }: { userId: string }) => {
  const [user, setUser] = useState<UserData>(null);
  const [isLoading, setIsLoading] = useState(true);

  const getUser = async (userId: string) => {
    setIsLoading(true);

    try {
      const { data } = await API.getUser({ userId });
      setUser(data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUser(userId);
  }, [userId]);

  return {
    user,
    isLoading,
  };
};

export default useUser;
