import React from "react";
import { FormattedMessage } from "react-intl";
import { Heading, ToggleButtons, Spinner } from "@trace-one/design-system";
import { withLanguageData } from "src/hocs";
import useApplicationToggleButtons from "./hooks/useApplicationToggleButtons";
import ResultTable from "./components/ResultTable";
import BackButton from "./components/BackButton";
import { NotificationSettingsProps } from "./types";
import useNotificationSettings from "./hooks/useNotificationSettings";
import { notificationSettingsMessages } from "./translations/messages";
import "./styles.less";

import frFr from "./translations/fr-FR.json";
import enUs from "./translations/en-US.json";
import deDe from "./translations/de-DE.json";
import esEs from "./translations/es-ES.json";
import ptPt from "./translations/pt-PT.json";
import itIt from "./translations/it-IT.json";
import nlBe from "./translations/nl-BE.json";
import { LangList } from "../../../utils/const";

const DEFAULT_LANG = enUs;

const enhance = withLanguageData({
  translations: {
    [LangList.EN_US]: enUs,
    [LangList.FR_FR]: { ...DEFAULT_LANG, ...frFr },
    [LangList.DE_DE]: { ...DEFAULT_LANG, ...deDe },
    [LangList.ES_ES]: { ...DEFAULT_LANG, ...esEs },
    [LangList.PT_PT]: { ...DEFAULT_LANG, ...ptPt },
    [LangList.IT_IT]: { ...DEFAULT_LANG, ...itIt },
    [LangList.NL_BE]: { ...DEFAULT_LANG, ...nlBe },
  },
});

const NotificationSettings: React.FC<NotificationSettingsProps> = props => {
  const { isUserAdmin, isCompanyAdmin, applicationTypeIds, showBackArrow } =
    props;

  const {
    applicationToggleButtons,
    selectedApplicationType,
    onChangeApplicationToggle,
  } = useApplicationToggleButtons({
    applicationTypeIds,
  });

  const {
    notificationSettings,
    onChangeNotificationCommunicationMethod,
    onChangeNotificationDigest,
    isDigestAvailable,
    isLoading,
  } = useNotificationSettings({
    applicationType: selectedApplicationType,
  });

  if (isLoading) return <Spinner size="large" />;

  return (
    <div className="notification-settings-container">
      <div className="notification-settings-container-header">
        {showBackArrow && <BackButton />}
        <Heading size="xs">
          <FormattedMessage {...notificationSettingsMessages.title} />
        </Heading>
      </div>
      <ToggleButtons
        options={
          !!isUserAdmin && isCompanyAdmin
            ? [...applicationToggleButtons]
            : [...applicationToggleButtons]
        }
        value={selectedApplicationType}
        onChange={onChangeApplicationToggle}
      />
      <ResultTable
        notificationPreferences={notificationSettings.notificationPreferences}
        onChangeNotificationCommunicationMethod={
          onChangeNotificationCommunicationMethod
        }
        onChangeNotificationDigest={onChangeNotificationDigest}
        isDigestAvailable={isDigestAvailable}
      />
    </div>
  );
};

export default enhance(NotificationSettings);
